import { IUser } from 'common/interfaces/user'
import React from 'react'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { transitionCreditCardChangeForm } from 'services/unsubscribe'
import { signOut } from 'services/user/setting'

interface IButtonsProps {
  user: IUser
  history: any
}

const Buttons: React.FC<IButtonsProps> = (props: IButtonsProps) => {
  const creditCardChange = () => transitionCreditCardChangeForm(props.user)
  const logOut = () => signOut(props.history)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-3">
                <div className="text-center mb-5">
                  <Row className="my-5 text-left">
                    MASARUのログインに問題が生じました。
                    <br />
                    クレジットカードの有効性をご確認いただくか、
                    <br />
                    下記サポート窓口にご連絡ください。
                    <br />
                    <a href="mailto:info@masaru-online.com">
                      info@masaru-online.com
                    </a>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <Button
                        onClick={creditCardChange}
                        color="info"
                        type="button"
                      >
                        クレジットカード情報変更
                      </Button>
                    </Col>
                  </Row>
                  <Row className="my-4">
                    <Col>
                      <Button onClick={logOut} color="info" type="button">
                        ログアウト
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Buttons
