import { Routes } from 'common/enums'
import { IUser } from 'common/interfaces/user'
import { alertService } from './alert'
import { modalService } from './modal'

const STORE_ID = String(process.env.REACT_APP_ROBOT_PAYMENT_STORE_ID)

/**
 * アカウントの種別を判別し、該当のURLに遷移する
 */
export const transitionAccount = (props: any, user: IUser | null): void => {
  if (!user) {
    return
  }

  if (user.admin) {
    props.history.push(Routes.AdminIndex)
    return
  }
  props.history.push(Routes.Root)
}

/**
 * クレカ変更フォームに遷移する
 */
export const transitionCreditCardChangeForm = (user: IUser): void => {
  try {
    const paymentID: string | null =
      user.payment !== null ? user.payment[0].id : null
    if (!paymentID) {
      alertService.show(false, 'クレジットカード変更画面には遷移できません')
    }

    window.location.href = `https://credit.j-payment.co.jp/gateway/cardinfo.aspx?aid=${STORE_ID}&tid=${paymentID}`
  } catch (error) {
    console.log(error)
  }
}

/**
 * 解約フォームに遷移する
 */
export const transitionUnsubscribeForm = (): void => {
  try {
    modalService.show(
      '退会を完了するとサービスがご利用いただけなくなりますが、よろしいですか？',
      () => {
        window.location.href = `https://credit.j-payment.co.jp/gateway/acstop.aspx?aid=${STORE_ID}`
      }
    )
  } catch (error) {
    console.log(error)
  }
}
