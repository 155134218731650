import firebase, { db } from 'common/firebase'
import { IPartTag, IPartTagResponse } from 'common/interfaces/part_tag'

const PartTags = db.collection('part_tags')

/**
 * part_tags all get
 */
export const get = async (): Promise<IPartTagResponse> => {
  const partTagResponse: IPartTagResponse = { data: [] }

  try {
    const partTagDatas = await PartTags.get()
    partTagDatas.docs.forEach((partTag) => {
      const partTagData: IPartTag = makePartTag(partTag)
      partTagResponse.data.push(partTagData)
    })
  } catch (error) {
    console.log(error)
  }

  return partTagResponse
}

function makePartTag(
  partTag: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): IPartTag {
  const data = partTag.data()
  return {
    id: data.id,
    name: data.name,
    image: data.image,
    sort_num: data.sort_num,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}

/**
 * part_tags add
 */
export const store = async (partTag: IPartTag): Promise<void> => {
  try {
    await PartTags.doc(partTag.id).set(partTag)
  } catch (error) {
    console.log(error)
  }
}

/**
 * part_tags update
 */
export const update = async (tag: IPartTag): Promise<void> => {
  try {
    tag.updated_at = firebase.firestore.Timestamp.now()

    await PartTags.doc(tag.id).update(tag)
  } catch (error) {
    console.log(error)
  }
}

/**
 * part_tags delete
 */
export const remove = async (partTag: IPartTag): Promise<void> => {
  try {
    await PartTags.doc(partTag.id).delete()
  } catch (error) {
    console.log(error)
  }
}

/**
 * remove image
 */
export const removeImage = async (tag: IPartTag): Promise<void> => {
  try {
    tag.image = null
    await PartTags.doc(tag.id).update(tag)
  } catch (error) {
    console.log(error)
  }
}
