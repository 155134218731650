import { findCachedTagsNonNull } from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IViewHistory } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { calcKcal } from 'common/utils'
import VideoValues from 'components/molecules/User/VideoValues/VideoValues'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Col, Row } from 'reactstrap'
import './widevideocard.scss'

interface IWideVideoCard {
  className?: string
  viewHistory?: IViewHistory
  video?: IVideo
}

interface IVideoInfo {
  title: string | null
  image: string | null
  tags: {
    id: string
    name: string | null
  }[]
  playTime: number
  mets: number
  kcal: number
}

const WideVideoCard: React.FC<IWideVideoCard> = (props: IWideVideoCard) => {
  const storeCache = useContext(AuthContext).storeCache

  if (props.viewHistory === undefined && props.video === undefined) {
    return null
  }

  const info = props.viewHistory
    ? viewHistoryInfo(props.viewHistory)
    : videoInfo(storeCache, props.video!)

  const imageCol = [12, 3, 2]
  const textCol = [12, 9, 5]
  const valueCol = [12, 12, 5]

  return (
    <div className={(props.className ?? '') + ' wide-video-card'}>
      <Row className="row">
        <Col className="col" xs={imageCol[0]} md={imageCol[1]} lg={imageCol[2]}>
          <img className="image" src={info.image ?? ''} alt="" />
        </Col>
        <Col className="col" xs={textCol[0]} md={textCol[1]} lg={textCol[2]}>
          <div className="title">{info.title}</div>
          <div className="tags">{createTags(info)}</div>
        </Col>
        <Col className="col" xs={valueCol[0]} md={valueCol[1]} lg={valueCol[2]}>
          <VideoValues
            className="video-values"
            minutes={info.playTime}
            mets={info.mets}
            kcal={info.kcal}
          />
        </Col>
      </Row>
    </div>
  )
}

function viewHistoryInfo(vh: IViewHistory): IVideoInfo {
  return {
    title: vh.title,
    image: vh.image,
    tags: vh.tags,
    playTime: vh.play_time,
    mets: vh.mets,
    kcal: vh.kcal,
  }
}

function videoInfo(storeCache: IStoreCache, video: IVideo): IVideoInfo {
  const tags = findCachedTagsNonNull(storeCache, video.tag_ids)
  const kcal = calcKcal(storeCache.user?.weight, video)
  return {
    title: video.title,
    image: video.image,
    tags: tags,
    playTime: video.play_time,
    mets: video.mets,
    kcal: kcal,
  }
}

function createTags(videoInfo: IVideoInfo): JSX.Element[] {
  return videoInfo.tags.map((tag) => {
    return (
      <div key={tag.id} className="tag">
        {tag.name}
      </div>
    )
  })
}

export default WideVideoCard
