import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IViewHistory } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { calcKcal } from 'common/utils'
import { addViewHistory, findByID } from 'repositories/store/firebase_user'

/**
 * 視聴履歴を取得
 */
export const getUserViewHistories = async (
  setData: React.Dispatch<React.SetStateAction<IViewHistory[]>>
): Promise<void> => {
  const uid = firebase.auth().currentUser?.uid ?? null
  let data: IViewHistory[] = []
  if (uid !== null) {
    data = (await findByID(uid)).view_histories ?? []
  }
  setData(data)
}

/**
 * 視聴履歴にvideoを追加する
 */
export const addUserViewHistory = async (
  storeCache: IStoreCache,
  video: IVideo,
  userWeight: number | null | undefined
): Promise<void> =>
  addViewHistory(
    firebase.auth().currentUser,
    storeCache,
    video,
    calcKcal(userWeight, video)
  )
