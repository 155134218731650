import React from 'react'
import { Link } from 'react-router-dom'

interface IBlackLink {
  to: string
  content: string
}

class BlackLink extends React.Component<IBlackLink> {
  render() {
    return (
      <Link
        to={this.props.to}
        style={{
          color: 'black',
        }}
      >
        {this.props.content}
      </Link>
    )
  }
}

export default BlackLink
