import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { reloadCachedCategories } from 'providers/AuthProvider'
import { upload } from 'repositories/storage/firebase_category'
import { store } from 'repositories/store/firebase_category'
import { alertService } from 'services/alert'

/**
 * カテゴリレコード作成処理APIを起動
 */
export const createCategory = async (
  props: any,
  file: File | undefined,
  categoryCreate: ICategory,
  storeCache: IStoreCache
): Promise<void> => {
  if (!categoryCreate.name) {
    alertService.show(false, 'カテゴリ名を入力してください')
    return
  }

  try {
    categoryCreate.image = await upload(categoryCreate, file)
    categoryCreate.created_at = firebase.firestore.Timestamp.now()
    categoryCreate.updated_at = firebase.firestore.Timestamp.now()
    await store(categoryCreate)

    await reloadCachedCategories(storeCache)
    props.history.push(Routes.AdminCategory)
  } catch (error) {
    console.log(error)
  }
}
