import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import Login from 'template/admin/Login'

const PrivateRoute = ({
  path,
  component,
}: {
  path: string
  component: any
}): JSX.Element => {
  const { currentUser } = useContext(AuthContext)
  const Component = currentUser ? component : Login

  return <Route exact path={path} component={Component} />
}

export default PrivateRoute
