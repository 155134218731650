import React from 'react'
import { Link } from 'react-router-dom'
import 'swiper/components/navigation/navigation.min.css'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import './cardswiper.scss'

export enum CardSwiperType {
  VIDEO,
  RECOMMEND_COURSE,
  POPULAR_EXERCISE,
}

const CardSwiper = ({
  children,
  type,
  className,
  showAllLink,
}: {
  children: any
  type: CardSwiperType
  className?: string
  showAllLink?: string
}) => {
  SwiperCore.use([Navigation])
  return (
    <Swiper
      className={className}
      breakpoints={{
        768: { slidesPerView: 2.5, slidesPerGroup: 2 },
        992: { slidesPerView: 3.5, slidesPerGroup: 3 },
      }}
      slidesPerView={1.5}
      slidesPerGroup={1}
      spaceBetween={16}
      navigation={{ disabledClass: 'd-none' }}
    >
      {children}
      {endCard(type, showAllLink)}
    </Swiper>
  )
}

const endCard = (type: CardSwiperType, showAllLink?: string) => {
  if (type !== CardSwiperType.VIDEO) {
    return null
  }

  return (
    <SwiperSlide>
      <Link to={showAllLink!}>
        <div className={'show-all-card'}>
          <div className="card-text">すべて表示</div>
          <div className="card-arrow">
            <i className="fas fa-chevron-right" />
          </div>
        </div>
      </Link>
    </SwiperSlide>
  )
}

export default CardSwiper
