import { Routes } from 'common/enums'
import {
  findCachedCategory,
  findCachedPartTag,
  findCachedTag,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { INullableVideoResponse, IVideo } from 'common/interfaces/video'
import { makeVideoLink, parsePlayTimeParam } from 'common/link'
import VideoCard from 'components/molecules/User/VideoCard/VideoCard'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import {
  getCategoryVideos,
  getNewerAllVideos,
  getPartTagVideos,
  getPlayTimeVideos,
  getPopularAllVideos,
  getTagVideos,
} from 'services/user/video'
import './videolist.scss'

const VideoList: React.FC = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const title = useTitle(storeCache, props)
  const videos = useVideos(props)

  const videoCount = videos?.length ?? 0
  const videoCountElement =
    videoCount > 0 ? (
      <p className="result-count">
        条件に合う検索結果が{videoCount}件ありました
      </p>
    ) : null

  const countPerPage = 15
  const [videoElements, setVideoElements] = useState<JSX.Element[]>([])
  const loadMore = (page: number) => {
    const sliceStart = (page - 1) * countPerPage
    const sliceEnd = sliceStart + countPerPage
    const showVideos = videos?.slice(sliceStart, sliceEnd) ?? null
    if (showVideos !== null && showVideos.length <= 0) {
      setVideoElements([createNoVideoElement()])
    } else if (showVideos !== null) {
      setVideoElements([...videoElements, ...createVideoElements(showVideos)])
    }
  }

  return (
    <div className="video-list">
      <h1>{title}</h1>
      {videoCountElement}
      <InfiniteScroll
        element="div"
        className="row"
        loadMore={loadMore}
        hasMore={
          (videos !== null &&
            videos.length === 0 &&
            videoElements.length === 0) ||
          videoCount > videoElements.length
        }
      >
        {videoElements}
      </InfiniteScroll>
    </div>
  )
}

enum PageType {
  NEWER_VIDEOS,
  POPULAR_VIDEOS,
  CATEGORY_VIDEOS,
  TAG_VIDEOS,
  PLAY_TIME_VIDEOS,
  PART_TAG_VIDEOS,
}

function pageType(): PageType {
  const pathName = window.location.pathname
  switch (pathName) {
    case Routes.UserNewerVideos:
      return PageType.NEWER_VIDEOS
    case Routes.UserPopularVideos:
      return PageType.POPULAR_VIDEOS
  }
  if (pathName.startsWith(Routes.UserCategoryVideos.split(':')[0])) {
    return PageType.CATEGORY_VIDEOS
  } else if (pathName.startsWith(Routes.UserTagVideos.split(':')[0])) {
    return PageType.TAG_VIDEOS
  } else if (pathName.startsWith(Routes.UserPlayTimeVideos.split(':')[0])) {
    return PageType.PLAY_TIME_VIDEOS
  } else if (pathName.startsWith(Routes.UserPartTagVideos.split(':')[0])) {
    return PageType.PART_TAG_VIDEOS
  }
  return PageType.NEWER_VIDEOS
}

function parseParams(
  props: any
): {
  categoryId: string
  tagId: string
  playTime: number[]
  partTagId: string
} {
  const paramCategoryId = props.match.params.categoryId
  const paramTagId = props.match.params.tagId
  const paramPlayTime = props.match.params.playTime
  const paramPartTagId = props.match.params.partTagId
  return {
    categoryId: paramCategoryId ?? '',
    tagId: paramTagId ?? '',
    playTime: parsePlayTimeParam(paramPlayTime),
    partTagId: paramPartTagId ?? '',
  }
}

function useTitle(storeCache: IStoreCache, props: any): string {
  let title: string = ''
  const params = parseParams(props)
  switch (pageType()) {
    case PageType.NEWER_VIDEOS:
      title = '新着の動画'
      break
    case PageType.POPULAR_VIDEOS:
      title = '人気の動画'
      break
    case PageType.CATEGORY_VIDEOS:
      const category = findCachedCategory(storeCache, params.categoryId)
      title = category?.name ?? ''
      break
    case PageType.TAG_VIDEOS:
      const tag = findCachedTag(storeCache, params.tagId)
      title = tag?.name ?? ''
      break
    case PageType.PLAY_TIME_VIDEOS:
      const pt = params.playTime
      title = pt.length > 1 ? `${pt[0]}分〜${pt[1]}分` : `${pt[0]}分〜`
      break
    case PageType.PART_TAG_VIDEOS:
      const partTag = findCachedPartTag(storeCache, params.partTagId)
      title = partTag?.name ?? ''
      break
  }

  let prefix = ''
  if (pageType() === PageType.TAG_VIDEOS) {
    prefix = '#'
  }
  return title ? prefix + title : ''
}

function useVideos(props: any): IVideo[] | null {
  const response: INullableVideoResponse = {
    data: null,
  }
  const [videos, setVideos] = useState<INullableVideoResponse>(response)
  useEffect(() => {
    const params = parseParams(props)
    switch (pageType()) {
      case PageType.NEWER_VIDEOS:
        getNewerAllVideos(setVideos)
        break
      case PageType.POPULAR_VIDEOS:
        getPopularAllVideos(setVideos)
        break
      case PageType.CATEGORY_VIDEOS:
        getCategoryVideos(setVideos, params.categoryId)
        break
      case PageType.TAG_VIDEOS:
        getTagVideos(setVideos, params.tagId)
        break
      case PageType.PLAY_TIME_VIDEOS:
        getPlayTimeVideos(setVideos, params.playTime)
        break
      case PageType.PART_TAG_VIDEOS:
        getPartTagVideos(setVideos, params.partTagId)
        break
    }
  }, [setVideos, props])
  return videos.data
}

function createVideoElements(videos: IVideo[]): JSX.Element[] {
  const result: JSX.Element[] = []
  videos.forEach((video) => {
    const el = (
      <Col
        key={video.id}
        className="video-card-container"
        xs={12}
        sm={6}
        md={4}
      >
        <Link to={makeVideoLink(video.id)}>
          <VideoCard className="video-card" video={video} />
        </Link>
      </Col>
    )
    result.push(el)
  })
  return result
}

function createNoVideoElement(): JSX.Element {
  return (
    <Col key={0} xs={12}>
      <h2 className="no-video-text">条件に合う動画はありませんでした</h2>
    </Col>
  )
}

export default VideoList
