import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { reloadCachedCategories } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_category'
import { remove } from 'repositories/store/firebase_category'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * categoriesテーブルのレコードを削除する
 */
export const removeCategory = (
  category: ICategory,
  storeCache: IStoreCache
) => {
  modalService.show('本当に削除してもよろしいですか？', async () => {
    try {
      await removeImageFile(category)
      await remove(category)
      await reloadCachedCategories(storeCache)
      alertService.show(true, '削除しました')
    } catch (error) {
      console.log(error)
      alertService.show(false, '削除できませんでした')
    }
  })
}
