import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITag } from 'common/interfaces/tag'
import { reloadCachedTags } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_tag'
import { remove } from 'repositories/store/firebase_tag'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * tagsテーブルのレコードを削除する
 */
export const removeVideo = (tag: ITag, storeCache: IStoreCache) => {
  modalService.show('本当に削除してもよろしいですか？', async () => {
    try {
      await removeImageFile(tag)
      await remove(tag)
      await reloadCachedTags(storeCache)
      alertService.show(true, '削除しました')
    } catch (error) {
      console.log(error)
      alertService.show(false, '削除できませんでした')
    }
  })
}
