import { Routes } from 'common/enums'
import firebase, { auth } from 'common/firebase'
import { IRegister } from 'common/interfaces/register'
import { IUser } from 'common/interfaces/user'
import { deleteByEmail } from 'repositories/store/firebase_mail'
import { store } from 'repositories/store/firebase_user'
import { alertService } from './alert'

/**
 * firebaseのAuthenticationを作成し認証する
 */
export async function createUser(
  props: any,
  registerState: IRegister
): Promise<void> {
  if (!registerState.email || !registerState.password) {
    alertService.show(false, 'メールアドレスとパスワードを入力してください')
    return
  }

  let nextPage: string = ''
  if (registerState.permission === 10) {
    nextPage = Routes.AdminAdministrator
  } else {
    nextPage = Routes.PlanSelect
  }

  try {
    const Uid: string | undefined = (
      await auth.createUserWithEmailAndPassword(
        String(registerState.email),
        String(registerState.password)
      )
    ).user?.uid

    const user: IUser = {
      id: String(Uid),
      admin: registerState.admin,
      name: null,
      email: String(registerState.email),
      prefecture: null,
      birthdate: null,
      gender: null,
      height: null,
      weight: null,
      body_fat_percentage: null,
      purpose: null,
      proportion: null,
      symptoms: null,
      sleeping_time: null,
      walking_time: null,
      contract_type: null,
      created_way: null,
      payment: null,
      subscription_plan: null,
      apply_date: null,
      corporate_id: null,
      expiration_at: null,
      view_histories: null,
      created_at: firebase.firestore.Timestamp.now(),
      updated_at: firebase.firestore.Timestamp.now(),
    }
    await store(user)

    if (registerState.admin) {
      deleteByEmail(registerState.email)
    }

    props.history.push(nextPage)
  } catch (error) {
    let message = ''
    switch (error.code) {
      case 'auth/invalid-email':
        message = 'メールアドレスの形式が正しくありません'
        break
      case 'auth/weak-password':
        message = 'パスワードは6文字以上である必要があります'
        break
      case 'auth/wrong-password':
        message =
          'パスワードが無効であるか、ユーザーがパスワードを持っていません'
        break
      case 'auth/email-already-in-use':
        message = 'メールアドレスはすでに別のアカウントで使用されています'
        break
      default:
        message = error.code
    }
    console.log(error)
    alertService.show(false, message)
  }
}
