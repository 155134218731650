import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/User/TableBody'
import React from 'react'
import { Container } from 'reactstrap'

const User: React.FC = (props: any) => {
  return (
    <div className="main-content">
      <H1 title="会員一覧" />

      <Container fluid>
        <TableBody {...props} />
      </Container>
    </div>
  )
}

export default User
