import { Subject } from 'rxjs'

interface IModal {
  isShow: boolean
  message: string
  onOk: () => void
}

const modalSubject = new Subject<IModal>()

export const modalService = {
  onModal,
  show,
  close,
}

function onModal() {
  return modalSubject.asObservable()
}

function show(message: string, onOk: () => void) {
  modalSubject.next({
    isShow: true,
    message,
    onOk,
  })
}

function close(closeModal: IModal) {
  modalSubject.next({ ...closeModal, isShow: false })
}
