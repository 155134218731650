import React from 'react'
import { Button } from 'reactstrap'

interface IPinkButton {
  onClick: () => void
  className: string
  content: string
}

class PinkButton extends React.Component<IPinkButton> {
  render() {
    return (
      <Button
        onClick={this.props.onClick}
        className={this.props.className}
        block
        style={{
          color: 'white',
          background: '#EF6FAB',
          border: '#EF6FAB',
        }}
      >
        {this.props.content}
      </Button>
    )
  }
}

export default PinkButton
