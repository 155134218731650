/**
 * ファイルとファイル名をStateに格納する
 */
export const selectImage = <T = {}>(
  e: React.ChangeEvent<HTMLInputElement>,
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>,
  setState: React.Dispatch<React.SetStateAction<T>>,
  initState: T
): void => {
  try {
    if (!e.target.files) {
      return
    }

    const image: File = e.target.files[0]

    setFile(image)
    setState({ ...initState, image: image.name })
  } catch (e) {
    console.log(e)
  }
}
