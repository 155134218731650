import React, { useContext, useRef, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from 'reactstrap'
import { IRegister } from 'common/interfaces/register'
import { Routes } from 'common/enums'
import PinkLink from 'components/atoms/Link/PinkLink'
import PinkButton from 'components/atoms/Button/Block/PinkButton'
import { AuthContext } from 'providers/AuthProvider'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const initRegister: IRegister = {
    email: null,
    password: null,
    permission: 1,
    admin: false,
  }
  const { registerUser } = useContext(AuthContext)
  const registerElem = useRef<IRegister>(initRegister)
  const [registerState, setRegister] = useState<IRegister>(initRegister)
  const register = (): void => registerUser(props, registerState)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-5">
                  アカウント作成して
                  <br />
                  エクササイズを始めよう！
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText />
                      </InputGroupAddon>
                      <Input
                        ref={registerElem.current.email}
                        onChange={(e) =>
                          setRegister({
                            ...registerState,
                            email: e.target.value,
                          })
                        }
                        placeholder="メールアドレス"
                        type="email"
                        autoComplete="new-email"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText />
                      </InputGroupAddon>
                      <Input
                        ref={registerElem.current.password}
                        onChange={(e) =>
                          setRegister({
                            ...registerState,
                            password: e.target.value,
                          })
                        }
                        placeholder="パスワード"
                        type="password"
                        autoComplete="new-password"
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <PinkButton
                      onClick={register}
                      className="my-4"
                      content="今すぐ始める"
                    />
                  </div>

                  <div className="text-center text-muted mt-4">
                    MASARUをご利用いただく前に、利用規約への
                    <br />
                    同意が必要になります。
                    <br />
                    ご確認の上ご登録ください。
                  </div>
                </Form>
              </CardBody>
            </Card>
            <p>
              <PinkLink to={Routes.Root} content="戻る" />
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
