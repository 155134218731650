import { findCachedTagsNonNull } from 'common/find_store_cache'
import firebase, { db } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IUser, IUserResponse, IViewHistory } from 'common/interfaces/user'
import { IVideo } from 'common/interfaces/video'
import { isInTodayTime, nowUnixtime } from 'common/times'

const Users = db.collection('users')

/**
 * users all get
 */
export const get = async (): Promise<IUser[]> => {
  const users: IUser[] = []
  const userDatas = await Users.get()
  for (const userData of userDatas.docs) {
    users.push(makeUser(userData.data()))
  }

  return users
}

/**
 * administrators all get
 */
export const getAdministrator = async (): Promise<IUserResponse> => {
  const userResponse: IUserResponse = { data: [] }

  try {
    const userDatas = await Users.where('admin', '==', true).get()
    for (const userData of userDatas.docs) {
      userResponse.data.push(makeUser(userData.data()))
    }
  } catch (error) {
    console.log(error)
  }

  return userResponse
}

/**
 * get user by id
 */
export const findByID = async (userID: string): Promise<IUser> => {
  let user: IUser = {} as IUser

  try {
    const userData: firebase.firestore.DocumentData | undefined = (
      await Users.doc(userID).get()
    ).data()
    if (userData !== undefined) {
      user = makeUser(userData)
    }
  } catch (error) {
    console.log(error)
  }

  return user
}

/**
 * get user by corporate_id
 */
export const findByCorporateId = async (
  corporateID: string
): Promise<IUserResponse> => {
  const userResponse: IUserResponse = { data: [] }
  const userDatas = await Users.where('corporate_id', '==', corporateID).get()
  for (const userData of userDatas.docs) {
    userResponse.data.push(makeUser(userData.data()))
  }
  return userResponse
}

/**
 * users add
 */
export const store = async (user: IUser): Promise<void> => {
  try {
    await Users.doc(user.id).set(user)
  } catch (error) {
    console.log(error)
  }
}

/**
 * users update
 */
export const update = async (user: IUser): Promise<void> => {
  try {
    await Users.doc(user.id).update(user)
  } catch (error) {
    console.log(error)
  }
}

function makeUser(user: firebase.firestore.DocumentData): IUser {
  return {
    id: user.id,
    admin: user.admin,
    name: user.name,
    email: user.email ?? null, // emailがundefinedになって、更新がうまくいかない場合がある
    prefecture: user.prefecture,
    birthdate: user.birthdate,
    gender: user.gender,
    height: user.height,
    weight: user.weight,
    body_fat_percentage: user.body_fat_percentage,
    purpose: user.purpose,
    proportion: user.proportion,
    symptoms: user.symptoms,
    sleeping_time: user.sleeping_time,
    walking_time: user.walking_time,
    contract_type: user.contract_type,
    created_way: user.created_way,
    payment: user.payment,
    subscription_plan: user.subscription_plan,
    apply_date: user.apply_date,
    corporate_id: user.corporate_id ?? null,
    expiration_at: user.expiration_at,
    view_histories: user.view_histories,
    created_at: user.created_at,
    updated_at: user.updated_at,
  }
}

/**
 * user add view_histories
 * @param userId user's id
 * @param videoDetail taget `IVideoDetail`
 */
export const addViewHistory = async (
  user: firebase.User | null,
  storeCache: IStoreCache,
  video: IVideo,
  kcal: number
): Promise<void> => {
  if (!user || (await isVideoAlreadyViewedToday(user, video))) {
    return
  }

  const tags: { id: string; name: string | null }[] = []
  const videoTags = findCachedTagsNonNull(storeCache, video.tag_ids)
  videoTags.forEach((tag) => {
    tags.push({
      id: tag.id,
      name: tag.name,
    })
  })

  const viewHistory: IViewHistory = {
    timestamp: nowUnixtime(),
    video_id: video.id,
    image: video.image,
    title: video.title,
    tags: tags,
    play_time: video.play_time,
    mets: video.mets,
    kcal: kcal,
  }

  try {
    await Users.doc(user.uid).update({
      view_histories: firebase.firestore.FieldValue.arrayUnion(viewHistory),
    })
  } catch (error) {
    console.log(error)
  }
}

/**
 * exists same video in today view history
 * @param user firebase user
 * @param videoDetail taget `IVideoDetail`
 */
async function isVideoAlreadyViewedToday(
  user: firebase.User,
  video: IVideo
): Promise<boolean> {
  const userViewHistory = (await findByID(user.uid)).view_histories
  if (!userViewHistory) {
    return false
  }

  let isViewed = false
  for (let i = 0; i < userViewHistory.length; i++) {
    if (
      isInTodayTime(userViewHistory[i].timestamp) &&
      userViewHistory[i].video_id === video.id
    ) {
      isViewed = true
      break
    }
  }

  return isViewed
}
