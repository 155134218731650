import { Routes } from 'common/enums'
import ButtonLeft from 'components/atoms/Button/Left'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/TagCreate/FormBody'
import React from 'react'
import { Container } from 'reactstrap'

const TagCreate: React.FC = (props: any) => {
  return (
    <div className="main-content">
      <H1 title="動画タグ登録" />

      <Container fluid>
        <ButtonLeft
          history={props.history}
          nextPage={Routes.AdminTag}
          content="一覧に戻る"
        />

        <FormBody {...props} />
      </Container>
    </div>
  )
}

export default TagCreate
