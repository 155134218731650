import { selectImage } from 'common/image'
import { ITag } from 'common/interfaces/tag'
import { convertNULLIntoString } from 'common/utils'
import { trim } from 'lodash'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { updateTag, deleteImage } from 'services/admin/tag_edit'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const tag: ITag = props.location.state.tag
  const initTag: ITag = {
    id: tag.id,
    name: tag.name,
    image: tag.image,
    sort_num: tag.sort_num,
    created_at: tag.created_at,
    updated_at: tag.updated_at,
  }
  const [tagEdit, setTagEdit] = useState<ITag>(initTag)
  const [file, setFile] = useState<File>()

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    selectImage<ITag>(e, setFile, setTagEdit, tagEdit)
  }
  const onChangeSortNum = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSortNum =
      trim(e.target.value) === '' ? null : Number(e.target.value)
    setTagEdit({ ...tagEdit, sort_num: newSortNum })
  }
  const delImage = () => deleteImage(props, tagEdit, storeCache)
  const update = () => updateTag(props, file, tagEdit, storeCache)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="tagNameForm">タグ名</Label>
            <Input
              id="tagNameForm"
              value={convertNULLIntoString(tagEdit.name)}
              onChange={(e) => setTagEdit({ ...tagEdit, name: e.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="tagThumbnailForm">サムネイル</Label>
            <CustomInput
              id="tagThumbnailForm"
              onChange={(e) => onChangeImage(e)}
              type="file"
            />
          </FormGroup>
        </Col>
      </Row>

      {tag.image !== null && (
        <Row className="mb-3">
          <Col>
            <Label>設定されているサムネイル</Label>
            <Row>
              <Col md="4">
                <img
                  height={150}
                  width="auto"
                  src={tag.image}
                  alt={String(tag.name)}
                />
              </Col>
              <Col md="8">
                <Button
                  onClick={() => delImage()}
                  className="my-4"
                  color="danger"
                  type="button"
                >
                  削除
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row>
        <Col md="3">
          <FormGroup>
            <Label for="categorySortNumForm">ソート番号</Label>
            <Input
              id="categorySortNumForm"
              value={convertNULLIntoString(tagEdit.sort_num)}
              onChange={(e) => onChangeSortNum(e)}
              type="number"
              step="1"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={update} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
