import React from 'react'

const TopLink: React.FC = () => {
  return (
    <div className="text-center mt-3 mb-3">
      <a href="https://masaru-online.com">MASARU TOPに戻る</a>
    </div>
  )
}

export default TopLink
