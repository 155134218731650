import { Routes } from 'common/enums'
import ButtonLeft from 'components/atoms/Button/Left'
import H1 from 'components/atoms/Head/H1'
import TableBody from 'components/organisms/Admin/PartTag/TableBody'
import React from 'react'
import { Container } from 'reactstrap'

const PartTag: React.FC = (props: any) => {
  return (
    <div className="main-content">
      <H1 title="部位タグ一覧" />

      <Container fluid>
        <ButtonLeft
          history={props.history}
          nextPage={Routes.AdminPartTagCreate}
          content="新規登録"
        />

        <TableBody {...props} />
      </Container>
    </div>
  )
}

export default PartTag
