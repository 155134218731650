import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { signOut } from 'services/user/setting'

interface ISubHeaderProps {
  history: any
}

const SubHeader: React.FC<ISubHeaderProps> = (props: ISubHeaderProps) => {
  const logOut = () => signOut(props.history)

  return (
    <Row className="mt-4">
      <Col className="display-3" sm="10">
        マイアカウント
      </Col>
      <Col sm="2">
        <Button onClick={logOut} color="info" type="button">
          ログアウト
        </Button>
      </Col>
    </Row>
  )
}

export default SubHeader
