import firebase from 'common/firebase'
import {
  IAuthEmailForm,
  IAuthPasswordForm,
} from 'common/interfaces/setting/auth_form'
import React, { useState } from 'react'
import {
  Button,
  Card,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import {
  emailUpdateAndSignIn,
  passwordUpdateAndSignIn,
} from 'services/user/setting'

interface IAuthFormRowProps {
  currentUser: firebase.User | null
}

const AuthFormRow: React.FC<IAuthFormRowProps> = (props: IAuthFormRowProps) => {
  const initAuthEmailForm: IAuthEmailForm = {
    email: '',
    password: '',
  }
  const initAuthPasswordForm: IAuthPasswordForm = {
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
  }
  const [authEmailForm, setAuthEmailForm] = useState<IAuthEmailForm>(
    initAuthEmailForm
  )
  const [authPasswordForm, setAuthPasswordForm] = useState<IAuthPasswordForm>(
    initAuthPasswordForm
  )

  const emailUpdate = () =>
    emailUpdateAndSignIn(props.currentUser, authEmailForm)
  const passwordUpdate = () =>
    passwordUpdateAndSignIn(
      props.currentUser,
      authPasswordForm,
      setAuthPasswordForm
    )

  return (
    <Row>
      <Col lg={6} className="mt-3">
        <Card>
          <CardTitle className="display-4 px-4 my-2">
            メールアドレスの設定
          </CardTitle>

          <Container className="px-4 pt-2 pb-3">
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>現在のメールアドレス</Label>
                  <Input
                    value={String(props.currentUser?.email)}
                    type="text"
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>新しいメールアドレス</Label>
                  <Input
                    value={authEmailForm.email}
                    onChange={(e) =>
                      setAuthEmailForm({
                        ...authEmailForm,
                        email: e.target.value,
                      })
                    }
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>パスワード</Label>
                  <Input
                    value={authEmailForm.password}
                    onChange={(e) =>
                      setAuthEmailForm({
                        ...authEmailForm,
                        password: e.target.value,
                      })
                    }
                    type="password"
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="text-center">
              <Button onClick={emailUpdate} color="info" type="button">
                変更
              </Button>
            </div>
          </Container>
        </Card>
      </Col>
      <Col lg={6} className="mt-3">
        <Card>
          <CardTitle className="display-4 px-4 my-2">
            パスワードの変更
          </CardTitle>

          <Container className="px-4 pt-2 pb-3">
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>現在のパスワード</Label>
                  <Input
                    value={authPasswordForm.password}
                    onChange={(e) =>
                      setAuthPasswordForm({
                        ...authPasswordForm,
                        password: e.target.value,
                      })
                    }
                    type="password"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>新しいパスワード</Label>
                  <Input
                    value={authPasswordForm.newPassword}
                    onChange={(e) =>
                      setAuthPasswordForm({
                        ...authPasswordForm,
                        newPassword: e.target.value,
                      })
                    }
                    type="password"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label>新しいパスワード（確認）</Label>
                  <Input
                    value={authPasswordForm.newPasswordConfirm}
                    onChange={(e) =>
                      setAuthPasswordForm({
                        ...authPasswordForm,
                        newPasswordConfirm: e.target.value,
                      })
                    }
                    type="password"
                  />
                </FormGroup>
              </Col>
            </Row>

            <div className="text-center">
              <Button onClick={passwordUpdate} color="info" type="button">
                変更
              </Button>
            </div>
          </Container>
        </Card>
      </Col>
    </Row>
  )
}

export default AuthFormRow
