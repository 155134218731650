import { Routes } from 'common/enums'
import ButtonLeft from 'components/atoms/Button/Left'
import H1 from 'components/atoms/Head/H1'
import H2 from 'components/atoms/Head/H2'
import TableBody from 'components/organisms/Admin/UserViewHistories/TableBody'
import React from 'react'
import { Container } from 'reactstrap'

const UserViewHistories: React.FC = (props: any) => {
  return (
    <div className="main-content">
      <H1 title="視聴履歴" />
      <H2 title={props.location.state.name} />
      <H2 title="メールアドレス" />

      <Container fluid>
        <ButtonLeft
          history={props.history}
          nextPage={Routes.AdminUser}
          content="一覧に戻る"
        />

        <TableBody {...props} />
      </Container>
    </div>
  )
}

export default UserViewHistories
