import { showKcal } from 'common/utils'
import React from 'react'
import { Col, Row } from 'reactstrap'
import './videovalues.scss'

interface IVideoValues {
  className?: string
  minutes: number
  mets: number
  kcal: number
}

const VideoValues: React.FC<IVideoValues> = (props: IVideoValues) => {
  return (
    <Row className={(props.className ?? '') + ' video-values'}>
      <Col className="col time">
        <div>
          <i className="fa fa-clock" />
          運動時間
        </div>
        <div className="value">
          {props.minutes}
          <span className="suffix">min</span>
        </div>
      </Col>
      <Col className="col strength">
        <div>
          <i className="fa fa-dumbbell" />
          運動強度
        </div>
        <div className="value">{props.mets}</div>
      </Col>
      <Col className="col kcal">
        <div>
          <i className="fa fa-fire-alt" />
          消費カロリー
        </div>
        <div className="value">
          {showKcal(props.kcal)}
          <span className="suffix">kcal</span>
        </div>
      </Col>
    </Row>
  )
}

export default VideoValues
