import { Routes } from 'common/enums'
import { IViewHistory } from 'common/interfaces/user'
import { makeVideoLink } from 'common/link'
import { dateFormat } from 'common/times'
import BlackLink from 'components/atoms/Link/BlackLink'
import Fotter from 'components/molecules/Fotter'
import WideVideoCard from 'components/molecules/User/WideVideoCard/WideVideoCard'
import Header from 'components/organisms/User/Header/Header'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { getUserViewHistories } from 'services/user/user'
import './viewhistory.scss'

const ViewHistory: React.FC = (props: any) => {
  return (
    <div className="user-view-history">
      <Header />
      <Container>
        <h1 className="title">ワークアウト視聴履歴</h1>
        {useViewHistories()}
      </Container>
      <div className="text-center mt-3 mb-3">
        <BlackLink to={Routes.Root} content="MASARU TOPに戻る" />
      </div>
      <Fotter />
    </div>
  )
}

function useViewHistories(): JSX.Element[] {
  const [histories, setHistories] = useState<IViewHistory[]>([])
  useEffect(() => {
    getUserViewHistories(setHistories)
  }, [setHistories])
  return createViews(histories.reverse())
}

function createViews(viewHistories: IViewHistory[]): JSX.Element[] {
  const result: JSX.Element[] = []
  let beforeDate = ''
  viewHistories.forEach((history) => {
    const date = dateFormat(history.timestamp)
    if (beforeDate !== date) {
      result.push(createDateElement(history.timestamp + 1, date))
      beforeDate = date
    }
    result.push(
      <Link
        key={history.timestamp}
        to={makeVideoLink(history.video_id)}
        className="view-history-card-link"
      >
        <WideVideoCard className="view-history-card" viewHistory={history} />
      </Link>
    )
  })
  return result
}

function createDateElement(key: number, formattedDate: string): JSX.Element {
  return (
    <div key={key} className="date">
      {formattedDate}
    </div>
  )
}

export default ViewHistory
