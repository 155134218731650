import { Routes, ViewType } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Sidebar from 'components/molecules/Admin/Sidebar'
import CantLogin, { CantLoginPageType } from 'components/molecules/CantLogin'
import PrivateRoute from 'components/organisms/Admin/PrivateRoute'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { findByEmail } from 'repositories/store/firebase_mail'
import { isLoading } from 'services/auth'
import Administrator from 'template/admin/Administrator'
import AdministratorCreate from 'template/admin/AdministratorCreate'
import Category from 'template/admin/Category'
import CategoryCreate from 'template/admin/CategoryCreate'
import CategoryEdit from 'template/admin/CategoryEdit'
import Corporate from 'template/admin/Corporate'
import CorporateCreate from 'template/admin/CorporateCreate'
import CorporateEdit from 'template/admin/CorporateEdit'
import Course from 'template/admin/Course'
import CourseCreate from 'template/admin/CourseCreate'
import CourseEdit from 'template/admin/CourseEdit'
import Index from 'template/admin/Index'
import Login from 'template/admin/Login'
import PartTag from 'template/admin/PartTag'
import PartTagCreate from 'template/admin/PartTagCreate'
import PartTagEdit from 'template/admin/PartTagEdit'
import Register from 'template/admin/Register'
import Tag from 'template/admin/Tag'
import TagCreate from 'template/admin/TagCreate'
import TagEdit from 'template/admin/TagEdit'
import User from 'template/admin/User'
import UserViewHistories from 'template/admin/UserViewHistories'
import Video from 'template/admin/Video'
import VideoCreate from 'template/admin/VideoCreate'
import VideoEdit from 'template/admin/VideoEdit'

const Admin: React.FC = (props: any) => {
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)
  useEffect(() => {
    window.scrollTo(0, 0)
    auth.onAuthStateChanged((user) => {
      isRegister().then((register: boolean) => {
        if (user === null) {
          if (register) {
            setViewType(ViewType.REGISTER)
          } else {
            setViewType(ViewType.LOGIN)
          }
        } else if (isLoading(storeCache)) {
          setViewType(ViewType.LOADING)
        } else if (storeCache.user?.admin ?? false) {
          setViewType(ViewType.VISIBLE)
        } else {
          setViewType(ViewType.GONE)
        }
      })
    })
  }, [setViewType, storeCache])

  switch (viewType) {
    case ViewType.REGISTER:
      return <Register {...props} />
    case ViewType.LOGIN:
      return <Login {...props} />
    case ViewType.LOADING:
      return <FullLoadingSpinner />
    case ViewType.GONE:
      return <CantLogin pageType={CantLoginPageType.MANAGEMENT} />
    case ViewType.VISIBLE:
      return adminRouteSwitch(props)
  }
}

const isRegister = async (): Promise<boolean> => {
  if (window.location.pathname !== Routes.AdminRegister) {
    return false
  }

  const Url = new URL(window.location.href)
  const Mail = await findByEmail(String(Url.searchParams.get('email')))
  if (!Mail.to) {
    return false
  }
  return true
}

const adminRouteSwitch = (props: any): JSX.Element => {
  return (
    <>
      <Sidebar />
      <Switch>
        <Route
          exact
          path={Routes.AdminRegister}
          component={() => <Register {...props} />}
        />
        <Route
          exact
          path={Routes.AdminLogin}
          component={() => <Login {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminIndex}
          component={() => <Index {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminVideo}
          component={() => <Video {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminVideoCreate}
          component={() => <VideoCreate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminVideoEdit}
          component={() => <VideoEdit {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCourse}
          component={() => <Course {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCourseCreate}
          component={() => <CourseCreate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCourseEdit}
          component={() => <CourseEdit {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCorporate}
          component={() => <Corporate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCorporateCreate}
          component={() => <CorporateCreate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCorporateEdit}
          component={() => <CorporateEdit {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminUser}
          component={() => <User {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminAdministrator}
          component={() => <Administrator {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminAdministratorCreate}
          component={() => <AdministratorCreate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminUserViewHistory}
          component={() => <UserViewHistories {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCategory}
          component={() => <Category {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCategoryCreate}
          component={() => <CategoryCreate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminCategoryEdit}
          component={() => <CategoryEdit {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminTag}
          component={() => <Tag {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminTagCreate}
          component={() => <TagCreate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminTagEdit}
          component={() => <TagEdit {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminPartTag}
          component={() => <PartTag {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminPartTagCreate}
          component={() => <PartTagCreate {...props} />}
        />
        <PrivateRoute
          path={Routes.AdminPartTagEdit}
          component={() => <PartTagEdit {...props} />}
        />
        <Redirect from={Routes.Admin} to={Routes.AdminIndex} />
      </Switch>
    </>
  )
}

export default Admin
