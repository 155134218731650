import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import React from 'react'
import { Redirect } from 'react-router'

export const UserRoutes = [
  Routes.Root,
  Routes.Setting,
  Routes.UserNewerVideos,
  Routes.UserPopularVideos,
  Routes.UserCategoryVideos,
  Routes.UserRecommendCourses,
  Routes.UserPopularExercises,
  Routes.UserCommonWorries,
  Routes.UserTagVideos,
  Routes.UserPartTagVideos,
  Routes.UserPlayTimeVideos,
  Routes.UserVideoDetail,
  Routes.UserViewHistory,
  Routes.UserCourseVideoDetail,
]

/**
 * 会員側のルーティングを定義
 */
export const createRedirect = (user: firebase.User | null): JSX.Element => {
  let redirectPage: string = Routes.Root
  if (!user) {
    redirectPage = Routes.Login
  }

  return <Redirect from="*" to={redirectPage} />
}
