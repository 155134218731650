import firebase, { db } from 'common/firebase'
import { ITag, ITagResponse } from 'common/interfaces/tag'

const Tags = db.collection('tags')

/**
 * tags all get
 */
export const get = async (): Promise<ITagResponse> => {
  const tagResponse: ITagResponse = { data: [] }

  try {
    const tagDatas = await Tags.get()
    tagDatas.docs.forEach((tag) => {
      const tagData: ITag = makeTag(tag)
      tagResponse.data.push(tagData)
    })
  } catch (error) {
    console.log(error)
  }

  return tagResponse
}

function makeTag(
  tag: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): ITag {
  const data = tag.data()
  return {
    id: data.id,
    name: data.name,
    image: data.image,
    sort_num: data.sort_num,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}

/**
 * tags add
 */
export const store = async (tag: ITag): Promise<void> => {
  try {
    await Tags.doc(tag.id).set(tag)
  } catch (error) {
    console.log(error)
  }
}

/**
 * tags update
 */
export const update = async (tag: ITag): Promise<void> => {
  try {
    tag.updated_at = firebase.firestore.Timestamp.now()

    await Tags.doc(tag.id).update(tag)
  } catch (error) {
    console.log(error)
  }
}

/**
 * tags delete
 */
export const remove = async (tag: ITag): Promise<void> => {
  try {
    await Tags.doc(tag.id).delete()
  } catch (error) {
    console.log(error)
  }
}

/**
 * remove image
 */
export const removeImage = async (tag: ITag): Promise<void> => {
  try {
    tag.image = null
    await Tags.doc(tag.id).update(tag)
  } catch (error) {
    console.log(error)
  }
}
