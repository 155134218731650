import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IUser } from 'common/interfaces/user'
import { dateFormat } from 'common/times'
import {
  convertNULLIntoString,
  convertJoinPlan,
  getCorporateName,
} from 'common/utils'
import {
  makeCustomOperationButton,
  makeOperationCustomButtons,
} from 'components/atoms/Table/ElementsOnTable'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { getUsers, removeUser } from 'services/admin/user'
import { makeNames } from 'services/admin/video'
import { textFilter } from 'react-bootstrap-table2-filter'

interface ITableBody {
  props: any
}

interface ITableUser {
  no: number
  id: string
  corporate_id: string
  corporate_name: string | null
  email: string
  name: string
  prefecture: string
  birthdate: string
  gender: string
  height: string
  weight: string
  body_fat_percentage: string
  Purpose: string
  PartTagNames: string
  Symptoms: string
  sleeping_time: string
  walking_time: string
  contract_type: string
  created_way: string
  subscription_plan: string
  created_at: string
  operation: JSX.Element
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const [User, SetUser] = useState<IUser[]>([])
  useEffect(() => {
    getUsers(SetUser)
  }, [SetUser])

  const columns: ColumnDescription<ITableUser>[] = [
    { dataField: 'no', text: 'No' },
    { dataField: 'id', text: 'id', filter: textFilter(), sort: true },
    {
      dataField: 'corporate_id',
      text: '法人ID',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'corporate_name',
      text: '法人名',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'email',
      text: 'メールアドレス',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'name',
      text: 'ニックネーム',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'prefecture',
      text: '居住地',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'birthdate',
      text: '誕生日',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'gender',
      text: '性別',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'height',
      text: '身長',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'weight',
      text: '体重',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'body_fat_percentage',
      text: '体脂肪',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'Purpose',
      text: '利用目的',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'PartTagNames',
      text: '気になるプロポーション・ボディメイクしたい部位',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'Symptoms',
      text: '健康面で気になる症状',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'sleeping_time',
      text: '睡眠時間',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'walking_time',
      text: '1日の歩行時間',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'contract_type',
      text: '契約タイプ',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'created_way',
      text: '作成方法',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'subscription_plan',
      text: '加入プラン',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'created_at',
      text: '加入日',
      filter: textFilter(),
      sort: true,
    },
    { dataField: 'operation', text: '操作' },
  ]

  const data: ITableUser[] = User.sort(
    (a, b) => a.created_at!.seconds - b.created_at!.seconds
  )
    .map((user, i) => {
      const Purposes: string = makeNames(user.purpose)
      const PartTagNames: string = makeNames(user.proportion)
      const Symptoms: string = makeNames(user.symptoms)
      const Subscription_plan: string = convertJoinPlan(user)
      const Created_at: string = dateFormat(user.created_at!.seconds)

      const remove = (userData: IUser): void => removeUser(userData, SetUser)
      return {
        no: i + 1,
        id: convertNULLIntoString(user.id),
        corporate_id: convertNULLIntoString(user.corporate_id),
        corporate_name: getCorporateName(
          user.corporate_id,
          storeCache.corporates
        ),
        email: convertNULLIntoString(user.email),
        name: convertNULLIntoString(user.name),
        prefecture: convertNULLIntoString(user.prefecture),
        birthdate: convertNULLIntoString(user.birthdate),
        gender: convertNULLIntoString(user.gender),
        height: convertNULLIntoString(user.height),
        weight: convertNULLIntoString(user.weight),
        body_fat_percentage: convertNULLIntoString(user.body_fat_percentage),
        Purpose: Purposes,
        PartTagNames: PartTagNames,
        Symptoms: Symptoms,
        sleeping_time: convertNULLIntoString(user.sleeping_time),
        walking_time: convertNULLIntoString(user.walking_time),
        contract_type: convertNULLIntoString(user.contract_type),
        created_way: convertNULLIntoString(user.created_way),
        subscription_plan: Subscription_plan,
        created_at: Created_at,
        operation: makeOperationCustomButtons(
          makeCustomOperationButton('視聴履歴', () =>
            props.history.push({
              pathname: Routes.AdminUserViewHistory,
              state: user,
            })
          ),
          makeCustomOperationButton('削除', () => remove(user))
        ),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITableUser>(columns, data, 'id', true)
}

export default TableBody
