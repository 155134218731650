import { ICategory } from 'common/interfaces/category'
import { IPartTag } from 'common/interfaces/part_tag'
import {
  makeCategoryLink,
  makePartTagLink,
  makePlayTimeLink,
} from 'common/link'
import { convertNULLIntoString } from 'common/utils'
import CategoryBadge from 'components/atoms/Badge/CategoryBadge'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import './choosecategory.scss'

export enum ChooseCategoryType {
  EXERCISES,
  TIMES,
  PARTS,
}

interface IChooseCategory {
  categoryType: ChooseCategoryType
}

const ChooseCategory: React.FC<IChooseCategory> = (props: IChooseCategory) => {
  return (
    <div className="choose-category">
      <div className="title">{createTitle(props.categoryType)}</div>
      <ScrollContainer className="badges">
        {useBadges(props.categoryType)}
      </ScrollContainer>
    </div>
  )
}

function createTitle(chooseCategoryType: ChooseCategoryType): string {
  switch (chooseCategoryType) {
    case ChooseCategoryType.EXERCISES:
      return 'エクササイズで選ぶ'
    case ChooseCategoryType.TIMES:
      return '時間で選ぶ'
    case ChooseCategoryType.PARTS:
      return '部位で選ぶ'
  }
}

function useBadges(chooseCategoryType: ChooseCategoryType): JSX.Element[] {
  const storeCache = useContext(AuthContext).storeCache
  let data: ICategory[] | IPartTag[] | null = null
  switch (chooseCategoryType) {
    case ChooseCategoryType.EXERCISES:
      data = storeCache.categories
      break
    case ChooseCategoryType.TIMES:
      data = getTimes()
      break
    case ChooseCategoryType.PARTS:
      data = storeCache.partTags
      break
  }

  return data.map((d) => {
    const name = convertNULLIntoString(d.name)
    switch (chooseCategoryType) {
      case ChooseCategoryType.EXERCISES:
        return createBadge(d.id, name, makeCategoryLink(d.id))
      case ChooseCategoryType.TIMES:
        return createBadge(d.id, name, makePlayTimeLink(d.id))
      case ChooseCategoryType.PARTS:
        return createBadge(d.id, name, makePartTagLink(d.id))
      default:
        return <></>
    }
  })
}

function getTimes(): ICategory[] {
  const times = [[1, 3], [4, 5], [6, 7], [8, 10], [11, 15], [16]]
  return times.map((time) => {
    const id = time.length > 1 ? `${time[0]}m-${time[1]}m` : `${time[0]}m`
    const name =
      time.length > 1 ? `${time[0]}分〜${time[1]}分` : `${time[0]}分〜`
    return {
      id: id,
      name: name,
      image: null,
      sort_num: null,
      created_at: null,
      updated_at: null,
    }
  })
}

function createBadge(id: string, name: string, linkPath: string): JSX.Element {
  return (
    <CategoryBadge
      key={id}
      className="choose-category-badge"
      name={name}
      linkPath={linkPath}
    />
  )
}

export default ChooseCategory
