import { ITag } from 'common/interfaces/tag'
import { makeTagLink } from 'common/link'
import { sortBySortNumber } from 'common/utils'
import OverlayCard from 'components/atoms/Card/OverlayCard'
import Fotter from 'components/molecules/Fotter'
import Header from 'components/organisms/User/Header/Header'
import ScrollToTopOnMount from 'components/organisms/User/ScrollToTop/ScrollToTopOnMount'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Col, Container } from 'reactstrap'
import './worries.scss'

const Worries: React.FC = () => {
  const worries = useContext(AuthContext).storeCache.tags
  const sortedWorries = sortBySortNumber(worries)

  const countPerPage = 12
  const [worryElements, setWorryElements] = useState<JSX.Element[]>([])
  const loadMore = (page: number) => {
    const sliceStart = (page - 1) * countPerPage
    const sliceEnd = sliceStart + countPerPage
    const showWorries = sortedWorries.slice(sliceStart, sliceEnd)
    setWorryElements([...worryElements, ...createWorries(showWorries)])
  }

  return (
    <div className="user-worries">
      <ScrollToTopOnMount />
      <Header />
      <Container className="content">
        <h1 className="title">よくあるお悩み</h1>
        <InfiniteScroll
          element="div"
          className="row"
          loadMore={loadMore}
          hasMore={sortedWorries.length > worryElements.length}
        >
          {worryElements}
        </InfiniteScroll>
      </Container>
      <Fotter />
    </div>
  )
}

function createWorries(worries: ITag[]): JSX.Element[] {
  const result: JSX.Element[] = []
  worries.forEach((worry) => {
    const id = worry.id
    const image = worry.image ?? ''
    const name = worry.name ?? ''
    const el = (
      <Col key={id} className="overlay-card-container" xs={6} md={3}>
        <Link to={makeTagLink(id)}>
          <OverlayCard
            className="overlay-card details"
            key={id}
            img={image}
            title={name}
          />
        </Link>
      </Col>
    )
    result.push(el)
  })

  return result
}

export default Worries
