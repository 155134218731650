import { Routes } from 'common/enums'
import { ICategory } from 'common/interfaces/category'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import {
  makeOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import { removeCategory } from 'services/admin/category'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'

interface ITableBody {
  props: any
}

interface ITableCategory {
  no: number
  id: string
  name: string | null
  image: string | null
  sort_num: number | null
  operation: JSX.Element
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const categories = storeCache.categories
  const remove = (category: ICategory) => removeCategory(category, storeCache)

  const columns: ColumnDescription<ITableCategory>[] = [
    { dataField: 'no', text: 'No' },
    { dataField: 'id', text: 'id', hidden: true },
    { dataField: 'name', text: 'カテゴリ名' },
    {
      dataField: 'image',
      text: 'サムネイル',
      formatter: (thumbnail, category) =>
        makeThumbnailElement(thumbnail, category.name),
    },
    { dataField: 'sort_num', text: 'ソート番号', sort: true },
    { dataField: 'operation', text: '操作' },
  ]

  const data: ITableCategory[] = categories
    .sort((a, b) => a.created_at!.seconds - b.created_at!.seconds)
    .map((category, i) => {
      return {
        no: i + 1,
        id: category.id,
        name: category.name,
        image: category.image,
        sort_num: category.sort_num,
        operation: makeOperationButtons<ICategory>(
          props,
          'category',
          Routes.AdminCategoryEdit,
          remove,
          category
        ),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITableCategory>(columns, data, 'id', true)
}

export default TableBody
