import { Routes } from 'common/enums'
import { ICourse } from 'common/interfaces/course'
import { makeCourseVideoLink } from 'common/link'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SwiperSlide } from 'swiper/react'
import CardSwiper, { CardSwiperType } from '../CardSwiper/CardSwiper'
import SideBySideTitle from '../SideBySideTitle/SideBySideTitle'
import './recommendcourses.scss'

const RecommendCourses: React.FC = () => {
  const courses = useContext(AuthContext).storeCache.courses
  const sevenCourses = courses.slice(0, 7)
  const courseElements = sevenCourses.map((cs) => createCourse(cs))

  return (
    <div className="recommend-courses">
      <SideBySideTitle
        title="おすすめのコース"
        subTitle="すべて表示"
        subTitleLink={Routes.UserRecommendCourses}
      />
      <CardSwiper
        type={CardSwiperType.RECOMMEND_COURSE}
        className="recommend-courses-container"
      >
        {courseElements}
      </CardSwiper>
    </div>
  )
}

function createCourse(course: ICourse): JSX.Element {
  const id = course.id
  const image = course.image ?? ''
  const title = course.title ?? ''

  const courseId = course.id
  const videoId = course.video_ids.length > 0 ? course.video_ids[0] : ''
  return (
    <SwiperSlide key={id}>
      <Link className="course-link" to={makeCourseVideoLink(courseId, videoId)}>
        <div className="course">
          {image ? <img src={image} alt={title} /> : <div className="box" />}
          <h3>{title}</h3>
        </div>
      </Link>
    </SwiperSlide>
  )
}

export default RecommendCourses
