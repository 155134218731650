import { Routes } from 'common/enums'
import {
  findCachedCategory,
  findCachedPartTags,
  findCachedTags,
} from 'common/find_store_cache'
import {
  makeOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'
import { ICourse } from 'common/interfaces/course'
import { IVideo, IVideoResponse } from 'common/interfaces/video'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { removeCourse } from 'services/admin/course'
import { getVideos } from 'services/admin/video'
import { textFilter } from 'react-bootstrap-table2-filter'

interface ITableBody {
  props: any
}

interface ITableCourse {
  no: number
  id: string
  title: string | null
  thumbnail: string | null
  categoryName: string | null
  tagNames: string | null
  partTagNames: string | null
  videoTitle: string | null
  overview: string | null
  operation: JSX.Element
}

interface PropHistoryState {
  course: ICourse
  allVideos: IVideo[]
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const [videos, setVideos] = useState<IVideoResponse>({ data: [] })
  useEffect(() => {
    getVideos(setVideos)
  }, [setVideos])

  const remove = (course: ICourse) => removeCourse(course, storeCache)

  const columns: ColumnDescription<ITableCourse>[] = [
    { dataField: 'no', text: 'No' },
    {
      dataField: 'id',
      text: 'コースID',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'title',
      text: 'コースタイトル',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'thumbnail',
      text: 'サムネイル',
      formatter: (thumbnail, video) =>
        makeThumbnailElement(thumbnail, video.title),
    },
    {
      dataField: 'categoryName',
      text: 'カテゴリ',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'tagNames',
      text: 'タグ',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'partTagNames',
      text: '部位タグ',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'videoTitle',
      text: 'コース動画',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'overview',
      text: '概要',
      filter: textFilter(),
      sort: true,
    },
    { dataField: 'operation', text: '操作' },
  ]

  const data: ITableCourse[] = storeCache.courses
    .sort((a, b) => a.created_at!.seconds - b.created_at!.seconds)
    .map((course, i) => {
      const categoryName =
        findCachedCategory(storeCache, course.category_id ?? '')?.name ?? ''
      const tagNames = findCachedTags(storeCache, course.tag_ids)
        .map((tag) => tag?.name ?? '')
        .join()
      const partTagNames = findCachedPartTags(storeCache, course.body_part_ids)
        .map((partTag) => partTag?.name ?? '')
        .join()

      return {
        no: i + 1,
        id: course.id,
        title: course.title,
        thumbnail: course.image,
        categoryName: categoryName,
        tagNames: tagNames,
        partTagNames: partTagNames,
        videoTitle: createVideoTitles(videos.data, course.video_ids).join(),
        overview: course.overview,
        operation: makeOperationButtons<ICourse, PropHistoryState>(
          props,
          { course: course, allVideos: videos.data },
          Routes.AdminCourseEdit,
          remove,
          course
        ),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITableCourse>(columns, data, 'id', true)
}

function createVideoTitles(videos: IVideo[], videoIds: string[]): string[] {
  const result: string[] = []
  videoIds.forEach((vId) => {
    const video = videos.find((v) => v.id === vId) ?? null
    if (video === null) {
      result.push('')
    } else {
      result.push(video.title ?? '')
    }
  })
  return result
}

export default TableBody
