import { Routes } from 'common/enums'
import { IPartTag } from 'common/interfaces/part_tag'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'
import {
  makeOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import { removePartTag } from 'services/admin/part_tag'

interface ITableBody {
  props: any
}

interface ITablePartTag {
  no: number
  id: string
  name: string | null
  image: string | null
  sort_num: number | null
  operation: JSX.Element
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const partTags = storeCache.partTags
  const remove = (partTag: IPartTag) => removePartTag(partTag, storeCache)

  const columns: ColumnDescription<ITablePartTag>[] = [
    { dataField: 'no', text: 'No' },
    { dataField: 'id', text: 'id', hidden: true },
    { dataField: 'name', text: 'タグ名' },
    {
      dataField: 'image',
      text: 'サムネイル',
      formatter: (thumbnail, partTag) =>
        makeThumbnailElement(thumbnail, partTag.name),
    },
    { dataField: 'sort_num', text: 'ソート番号', sort: true },
    { dataField: 'operation', text: '操作' },
  ]

  const data: ITablePartTag[] = partTags
    .sort((a, b) => a.created_at!.seconds - b.created_at!.seconds)
    .map((tag, i) => {
      return {
        no: i + 1,
        id: tag.id,
        name: tag.name,
        image: tag.image,
        sort_num: tag.sort_num,
        operation: makeOperationButtons<IPartTag>(
          props,
          'partTag',
          Routes.AdminPartTagEdit,
          remove,
          tag
        ),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITablePartTag>(columns, data, 'id', true)
}

export default TableBody
