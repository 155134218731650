import { Routes } from 'common/enums'
import React from 'react'
import { Link } from 'react-router-dom'
import './cantlogin.scss'

export enum CantLoginPageType {
  MEMBER,
  MANAGEMENT,
}

interface ICantLogin {
  pageType: CantLoginPageType
}

const CantLogin: React.FC<ICantLogin> = (props: ICantLogin) => {
  let type: string
  let backPage: string
  let backTitle: string
  if (props.pageType === CantLoginPageType.MEMBER) {
    type = '管理者'
    backPage = Routes.Admin
    backTitle = '管理ページへ戻る'
  } else {
    type = '会員様'
    backPage = Routes.Root
    backTitle = 'トップページへ戻る'
  }

  return (
    <div className="cant-login-page">
      <h1 className="title">
        <span>{type}はこの画面からは</span>
        <span>ログインできません。</span>
      </h1>
      <h4>
        <Link to={backPage}>{backTitle}</Link>
      </h4>
    </div>
  )
}

export default CantLogin
