import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import { IVideo } from 'common/interfaces/video'
import { upload } from 'repositories/storage/firebase_video'
import { store } from 'repositories/store/firebase_video'
import { alertService } from 'services/alert'

/**
 * 動画レコード作成処理APIを起動
 */
export const createVideo = async (
  props: any,
  file: File | undefined,
  video: IVideo
): Promise<void> => {
  if (!video.title) {
    alertService.show(false, 'タイトルを入力してください')
    return
  }

  try {
    video.image = await upload(video, file)
    video.created_at = firebase.firestore.Timestamp.now()
    video.updated_at = firebase.firestore.Timestamp.now()
    await store(video)

    props.history.push(Routes.AdminVideo)
  } catch (error) {
    console.log(error)
  }
}
