import TimeKcalGraph from 'components/atoms/Graph/TimeKcalGraph'
import React from 'react'
import './myscoregraph.css'

const titles = {
  today: 'TODAY',
  week: 'THIS WEEK',
  month: 'THIS MONTH',
}

const maxValues = {
  today: {
    minutes: 30,
    kcal: 300,
  },
  week: {
    minutes: 210,
    kcal: 2100,
  },
  month: {
    minutes: 900,
    kcal: 9000,
  },
}

export enum MyScoreGraphType {
  TODAY,
  WEEK,
  MONTH,
}

interface IMyScoreGraph {
  className?: string
  graphType: MyScoreGraphType
  minutes: number
  kcal: number
}

const MyScoreGraph: React.FC<IMyScoreGraph> = (props: IMyScoreGraph) => {
  const title = createTitle(props.graphType)
  const max = createMaxValues(props.graphType)

  return (
    <div className={props.className}>
      <div className="my-score-graph__title">{title}</div>
      <TimeKcalGraph
        minutes={props.minutes}
        kcal={props.kcal}
        maxMinutes={max.minutes}
        maxKcal={max.kcal}
      />
    </div>
  )
}

function createTitle(type: MyScoreGraphType): string {
  switch (type) {
    case MyScoreGraphType.TODAY:
      return titles.today
    case MyScoreGraphType.WEEK:
      return titles.week
    case MyScoreGraphType.MONTH:
      return titles.month
  }
}

function createMaxValues(
  type: MyScoreGraphType
): {
  minutes: number
  kcal: number
} {
  switch (type) {
    case MyScoreGraphType.TODAY:
      return maxValues.today
    case MyScoreGraphType.WEEK:
      return maxValues.week
    case MyScoreGraphType.MONTH:
      return maxValues.month
  }
}

export default MyScoreGraph
