import { Routes } from 'common/enums'
import React from 'react'
import { withRouter } from 'react-router'
import { Button } from 'reactstrap'
import './setprofile.scss'

const SetProfile: React.FC = (props: any) => {
  const moveSettingPage = () => {
    props.history.push(Routes.Setting)
  }

  return (
    <div className="set-profile">
      <h1 className="profile">
        プロフィールを設定して
        <br />
        エクササイズを記録しましょう！
      </h1>
      <Button
        // css class is 'btn-outline-danger'
        color="danger"
        outline
        type="button"
        onClick={moveSettingPage}
      >
        プロフィールを設定する
      </Button>
    </div>
  )
}

export default withRouter(SetProfile)
