import { IPartTag } from 'common/interfaces/part_tag'
import { makePartTagLink } from 'common/link'
import { sortBySortNumber } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import './interestparts.scss'

const InterestParts: React.FC = () => {
  const partTags = useContext(AuthContext).storeCache.partTags
  const eightPartTags = sortBySortNumber(partTags).slice(0, 8)
  const interestParts = eightPartTags.map((parts) => createInterestParts(parts))

  return (
    <div className="interest-parts">
      <div className="interest-parts-title">
        <h1>気になる部位</h1>
      </div>
      <Row>{interestParts}</Row>
    </div>
  )
}

function createInterestParts(part: IPartTag): JSX.Element {
  return (
    <Col key={part.id} xs={6} md={3}>
      <Link className="interest-part-link" to={makePartTagLink(part.id)}>
        <div className="interest-part">
          <img className="image" src={part.image ?? ''} alt={part.name ?? ''} />
          <div className="title">{part.name}</div>
        </div>
      </Link>
    </Col>
  )
}

export default InterestParts
