import FormBody from 'components/organisms/Admin/Login/FormBody'
import React from 'react'

interface ILoginProps {
  props: any
}

const Login: React.FC<ILoginProps> = (props: any) => {
  return <FormBody {...props} />
}

export default Login
