import firebase, { db } from 'common/firebase'
import { IMail } from 'common/interfaces/mail'

const Mail = db.collection('mail')

/**
 * mail get by email
 */
export const findByEmail = async (email: string): Promise<IMail> => {
  let mail: IMail = {
    to: '',
    message: {
      subject: '',
      html: '',
    },
  }

  try {
    const MailID = (await Mail.where('to', '==', email).get()).docs[0].id
    const MailData: firebase.firestore.DocumentData | undefined = (
      await Mail.doc(MailID).get()
    ).data()
    if (MailData !== undefined) {
      mail = makeMail(MailData)
    }
  } catch (error) {
    console.log(error)
  }

  return mail
}

/**
 * mail delete by email
 */
export const deleteByEmail = async (email: string): Promise<void> => {
  try {
    ;(
      await Mail.where('to', '==', email).get()
    ).docs.map(
      (
        mail: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
      ) => Mail.doc(mail.id).delete()
    )
  } catch (error) {
    console.log(error)
  }
}

function makeMail(mail: firebase.firestore.DocumentData): IMail {
  return {
    to: mail.to,
    message: {
      subject: mail.subject,
      html: mail.html,
    },
  }
}
