import { Routes } from 'common/enums'
import { ITag } from 'common/interfaces/tag'
import { AuthContext } from 'providers/AuthProvider'
import {
  makeOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'
import React, { useContext } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { removeVideo } from 'services/admin/tag'

interface ITableBody {
  props: any
}

interface ITableTag {
  no: number
  id: string
  name: string | null
  image: string | null
  sort_num: number | null
  operation: JSX.Element
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const tags = storeCache.tags
  const remove = (tag: ITag) => removeVideo(tag, storeCache)

  const columns: ColumnDescription<ITableTag>[] = [
    { dataField: 'no', text: 'No' },
    { dataField: 'id', text: 'id', hidden: true },
    { dataField: 'name', text: 'タグ名' },
    {
      dataField: 'image',
      text: 'サムネイル',
      formatter: (thumbnail, tag) => makeThumbnailElement(thumbnail, tag.name),
    },
    { dataField: 'sort_num', text: 'ソート番号', sort: true },
    { dataField: 'operation', text: '操作' },
  ]

  const data: ITableTag[] = tags
    .sort((a, b) => a.created_at!.seconds - b.created_at!.seconds)
    .map((tag, i) => {
      return {
        no: i + 1,
        id: tag.id,
        name: tag.name,
        image: tag.image,
        sort_num: tag.sort_num,
        operation: makeOperationButtons<ITag>(
          props,
          'tag',
          Routes.AdminTagEdit,
          remove,
          tag
        ),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITableTag>(columns, data, 'id', true)
}

export default TableBody
