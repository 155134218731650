import Fotter from 'components/molecules/Fotter'
import FormBody from 'components/organisms/Register/FormBody'
import React from 'react'

interface IRegisterProps {
  props: any
}

export const Register: React.FC<IRegisterProps> = (props: any) => {
  return (
    <>
      <FormBody {...props} />
      <Fotter />
    </>
  )
}

export default Register
