import { IStoreCache } from 'common/interfaces/auth_provider'
import { IPartTag } from 'common/interfaces/part_tag'
import { reloadCachedPartTags } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_part_tag'
import { remove } from 'repositories/store/firebase_part_tag'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * part_tagsテーブルのレコードを削除する
 */
export const removePartTag = (partTag: IPartTag, storeCache: IStoreCache) => {
  modalService.show('本当に削除してもよろしいですか？', async () => {
    try {
      await removeImageFile(partTag)
      await remove(partTag)
      await reloadCachedPartTags(storeCache)
      alertService.show(true, '削除しました')
    } catch (error) {
      console.log(error)
      alertService.show(false, '削除できませんでした')
    }
  })
}
