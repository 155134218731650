import firebase, { db } from 'common/firebase'
import { ICourse, ICourseResponse } from 'common/interfaces/course'

const Courses = db.collection('courses')

/**
 * courses all get
 */
export const get = async (): Promise<ICourseResponse> => {
  const courseResponse: ICourseResponse = { data: [] }

  try {
    const courseDatas = await Courses.get()
    courseDatas.docs.forEach((course) => {
      const courseData: ICourse = makeCourse(course)
      courseResponse.data.push(courseData)
    })
  } catch (error) {
    console.log(error)
  }

  return courseResponse
}

function makeCourse(
  course: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): ICourse {
  const data = course.data()
  return {
    id: data.id,
    title: data.title,
    image: data.image,
    category_id: data.category_id,
    tag_ids: data.tag_ids,
    body_part_ids: data.body_part_ids,
    video_ids: data.video_ids,
    overview: data.overview,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}

/**
 * course add
 */
export const store = async (course: ICourse): Promise<void> => {
  try {
    await Courses.doc(course.id).set(course)
  } catch (error) {
    console.log(error)
  }
}

/**
 * course update
 */
export const update = async (course: ICourse): Promise<void> => {
  try {
    course.updated_at = firebase.firestore.Timestamp.now()

    await Courses.doc(course.id).update(course)
  } catch (error) {
    console.log(error)
  }
}

/**
 * course delete
 */
export const remove = async (course: ICourse): Promise<void> => {
  try {
    await Courses.doc(course.id).delete()
  } catch (error) {
    console.log(error)
  }
}

/**
 * remove image
 */
export const removeImage = async (course: ICourse): Promise<void> => {
  try {
    course.image = null
    await Courses.doc(course.id).update(course)
  } catch (error) {
    console.log(error)
  }
}
