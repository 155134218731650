import { IVideo, IVideoResponse } from 'common/interfaces/video'
import { removeImageFile } from 'repositories/storage/firebase_video'
import { get, remove } from 'repositories/store/firebase_video'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * videosテーブルのデータをすべて取得する
 */
export const makeNames = (datas: any): string => {
  if (!datas) {
    return ''
  }

  let Names: string[] = []
  for (const Tags of datas as { value: string; label: string }[]) {
    Names.push(Tags.label)
  }

  return Names.join()
}

/**
 * videosテーブルのデータをすべて取得する
 */
export const getVideos = async (
  setData: React.Dispatch<React.SetStateAction<IVideoResponse>>
): Promise<void> => setData(await get())

/**
 * videosテーブルのレコードを削除する
 */
export const removeVideo = (
  video: IVideo,
  setData: React.Dispatch<React.SetStateAction<IVideoResponse>>
) => {
  modalService.show('本当に削除してもよろしいですか？', async () => {
    try {
      await removeImageFile(video)
      await remove(video)

      getVideos(setData)
      alertService.show(true, '削除しました')
    } catch (error) {
      console.log(error)
      alertService.show(false, '削除できませんでした')
    }
  })
}
