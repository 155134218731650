import { getCountSignedUpByCoupon } from 'common/coupon'
import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICorporate } from 'common/interfaces/corporate'
import { reloadCachedCorporates } from 'providers/AuthProvider'
import { update } from 'repositories/store/firebase_corporate'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/corporate'

/**
 * 法人レコード更新処理APIを起動
 */
export const updateCorporate = async (
  props: any,
  corporate: ICorporate,
  initCouponEnabledCount: number,
  storeCache: IStoreCache
): Promise<void> => {
  if (!validateForm(corporate, storeCache)) {
    return
  }

  try {
    if (Number(corporate.coupon_enabled_count) < initCouponEnabledCount) {
      const remainingCouponNum: number =
        initCouponEnabledCount - (await getCountSignedUpByCoupon(corporate))
      const diffCouponNum: number =
        initCouponEnabledCount - Number(corporate.coupon_enabled_count)
      const validCouponNum: number = remainingCouponNum - diffCouponNum
      if (validCouponNum < 0) {
        alertService.show(
          false,
          '残りの有効人数が0よりも少なくなるため更新できません'
        )
        return
      }
    }
    await update(corporate)
    await reloadCachedCorporates(storeCache)

    alertService.show(true, '法人を更新しました')
    props.history.push(Routes.AdminCorporate)
  } catch (error) {
    console.log(error)
    alertService.show(false, '法人の更新に失敗しました')
  }
}
