import { Routes } from 'common/enums'
import ItemAction, { ItemActionType } from 'components/atoms/Nav/ItemAction'
import ItemLink from 'components/atoms/Nav/ItemLink'
import React, { useState } from 'react'
import { Collapse, Container, Nav, Navbar } from 'reactstrap'

const Sidebar: React.FC = () => {
  const [collapseOpen, setCollapseOpen] = useState<boolean>(true)

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <Collapse navbar isOpen={collapseOpen}>
          <h6 className="navbar-heading text-muted">動画管理</h6>
          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminVideo}
              content="動画一覧"
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminVideoCreate}
              content="動画登録"
            />
          </Nav>

          <h6 className="navbar-heading text-muted">コース管理</h6>
          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminCourse}
              content="コース一覧"
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminCourseCreate}
              content="コース登録"
            />
          </Nav>

          <h6 className="navbar-heading text-muted">法人管理</h6>
          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminCorporate}
              content="法人一覧"
            />
          </Nav>

          <h6 className="navbar-heading text-muted">会員管理</h6>
          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminUser}
              content="会員一覧"
            />
          </Nav>

          <h6 className="navbar-heading text-muted">設定</h6>
          <Nav navbar>
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminAdministrator}
              content="管理者マスタ"
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminCategory}
              content="カテゴリマスタ"
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminTag}
              content="タグマスタ"
            />
            <ItemLink
              setCollapseOpen={setCollapseOpen}
              nextPage={Routes.AdminPartTag}
              content="部位タグマスタ"
            />
          </Nav>

          <h6 className="navbar-heading text-muted">アカウント</h6>
          <Nav navbar>
            <ItemAction action={ItemActionType.LOGOUT} />
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default Sidebar
