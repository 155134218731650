import { Routes } from 'common/enums'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { makeOperationButtons } from 'components/atoms/Table/ElementsOnTable'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'
import { ICorporate } from 'common/interfaces/corporate'
import { setCorporatesData, removeCorporate } from 'services/admin/corporate'
import { textFilter } from 'react-bootstrap-table2-filter'

interface ITableBody {
  props: any
}

interface ITableCorporate {
  no: number
  id: string
  name: string | null
  address: string | null
  coupon_code: string | null
  coupon_enabled_count: number | null
  remaining_enabled_count: number | null
  operation: JSX.Element
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const [corporates, setCorporates] = useState<ICorporate[]>([])
  useEffect(() => {
    setCorporatesData(storeCache.corporates, setCorporates)
  }, [storeCache, setCorporates])

  const remove = (corporate: ICorporate): void =>
    removeCorporate(corporate, storeCache)

  const columns: ColumnDescription<ITableCorporate>[] = [
    { dataField: 'no', text: 'No' },
    { dataField: 'id', text: 'id', hidden: true },
    {
      dataField: 'name',
      text: '法人名',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'address',
      text: '法人住所',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'coupon_code',
      text: 'クーポンコード',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'coupon_enabled_count',
      text: 'クーポン有効人数',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'remaining_enabled_count',
      text: '残りの有効人数',
      filter: textFilter(),
      sort: true,
    },
    { dataField: 'operation', text: '操作' },
  ]

  const data: ITableCorporate[] = corporates
    .sort((a, b) => a.created_at!.seconds - b.created_at!.seconds)
    .map((corporate, i) => {
      return {
        no: i + 1,
        id: corporate.id,
        name: corporate.name,
        address: corporate.address,
        coupon_code: corporate.coupon_code,
        coupon_enabled_count: corporate.coupon_enabled_count,
        remaining_enabled_count: corporate.remaining_enabled_count,
        operation: makeOperationButtons<ICorporate>(
          props,
          'corporate',
          Routes.AdminCorporateEdit,
          remove,
          corporate
        ),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITableCorporate>(
    columns,
    data,
    'id',
    true
  )
}

export default TableBody
