import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import { Ilogin } from 'common/interfaces/login'
import { IUser } from 'common/interfaces/user'
import { findByID, update } from 'repositories/store/firebase_user'
import { IsSettled } from 'services/auth'
import { alertService } from './alert'

/**
 * firebaseのAuthenticationで認証する
 */
export async function firebaseBySignInWithEmailAndPassword(
  props: any,
  loginState: Ilogin
): Promise<void> {
  if (!loginState.email || !loginState.password) {
    alertService.show(false, 'ログインIDとパスワードを入力してください')
    return
  }

  try {
    const uid: string | undefined = (
      await firebase
        .auth()
        .signInWithEmailAndPassword(
          String(loginState.email),
          String(loginState.password)
        )
    ).user?.uid
    const user: IUser = await findByID(String(uid))
    if (user.expiration_at === undefined) {
      // expiration_atフィールドがない場合はNULLを入れて更新する
      user.expiration_at = null
      await update(user)
    }

    let nextPage: string
    if (loginState.permission !== 10) {
      // 一般会員側
      if (IsSettled(user)) {
        // 認証成功し、会員限定のページに遷移
        nextPage = Routes.Root
      } else {
        // プラン選択画面を入力していただく
        nextPage = Routes.PlanSelect
      }
    } else {
      // 管理側
      nextPage = Routes.AdminIndex
    }

    // props.history.push(nextPage)
    window.location.href = nextPage
  } catch (error) {
    let message = ''
    switch (error.code) {
      case 'auth/invalid-email':
        message = 'メールアドレスの形式が正しくありません'
        break
      case 'auth/weak-password':
        message = 'パスワードは6文字以上である必要があります'
        break
      case 'auth/wrong-password':
        message =
          'パスワードが無効であるか、ユーザーがパスワードを持っていません'
        break
      case 'auth/user-not-found':
        message = 'メールアドレスかパスワードが間違っています'
        break
      default:
        message = error.code
    }
    console.log(error)
    alertService.show(false, message)
  }
}
