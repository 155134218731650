import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { modalService } from 'services/modal'

const Confirm: React.FC = () => {
  const [modal, setModal] = useState<{
    isShow: boolean
    message: string
    onOk: () => void
  }>({
    isShow: false,
    message: '',
    onOk: () => {},
  })

  useEffect(() => {
    const subscription = modalService
      .onModal()
      .subscribe((modal) => setModal(modal))
    return () => subscription.unsubscribe()
  }, [])

  const close = () => modalService.close(modal)
  const ok = () => {
    close()
    modal.onOk()
  }

  return (
    <Modal isOpen={modal.isShow} toggle={close} centered onClosed={close}>
      <ModalBody>{modal.message}</ModalBody>
      <ModalFooter style={{ borderTop: 'unset' }}>
        <Button color="secondary" onClick={close}>
          Cancel
        </Button>{' '}
        <Button color="primary" onClick={ok}>
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Confirm
