import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { ICourse } from 'common/interfaces/course'
import { makeCategoryLink, makeCourseVideoLink } from 'common/link'
import Fotter from 'components/molecules/Fotter'
import ChooseCategory, {
  ChooseCategoryType,
} from 'components/molecules/User/ChooseCategory/ChooseCategory'
import GenreCard from 'components/molecules/User/GenreCard/GenreCard'
import Header from 'components/organisms/User/Header/Header'
import ScrollToTopOnMount from 'components/organisms/User/ScrollToTop/ScrollToTopOnMount'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './genrelist.scss'

enum PageType {
  RECOMMEND,
  EXERCISE,
}

const GenreList: React.FC = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache

  return (
    <div className="user-genre-list">
      <ScrollToTopOnMount />
      <Header />
      <Container className="categories">
        <ChooseCategory categoryType={ChooseCategoryType.EXERCISES} />
        <ChooseCategory categoryType={ChooseCategoryType.TIMES} />
        <ChooseCategory categoryType={ChooseCategoryType.PARTS} />
      </Container>
      <hr />
      <Container className="content">
        <h1 className="title">{createTitle()}</h1>
        <Row>{createGenreElements(storeCache)}</Row>
      </Container>
      <Fotter />
    </div>
  )
}

const pageType = (): PageType => {
  const pathName = window.location.pathname
  switch (pathName) {
    case Routes.UserRecommendCourses:
      return PageType.RECOMMEND
    case Routes.UserPopularExercises:
      return PageType.EXERCISE
  }
  return PageType.RECOMMEND
}

const createTitle = (): string => {
  switch (pageType()) {
    case PageType.RECOMMEND:
      return 'おすすめのコース'
    case PageType.EXERCISE:
      return '人気のエクササイズ'
  }
}

function createGenreElements(storeCache: IStoreCache): JSX.Element[] {
  const type = pageType()
  const data = (() => {
    switch (type) {
      case PageType.RECOMMEND:
        return storeCache.courses
      case PageType.EXERCISE:
        return storeCache.categories
    }
  })()

  const getData = (d: any) => {
    const name = type === PageType.RECOMMEND ? d.title : d.name
    const link =
      type === PageType.RECOMMEND
        ? makeCourseVideoLink(
            d.id,
            d.video_ids.length > 0 ? d.video_ids[0] : ''
          )
        : makeCategoryLink(d.id)
    const image = d.image
    return { name, link, image }
  }

  return (data as Array<ICourse | ICategory>).map((d) => {
    const { name, link, image } = getData(d)
    return (
      <Col key={d.id} className="genre-col" xs="6" md="4" xl="3">
        <GenreCard name={name} link={link} image={image} />
      </Col>
    )
  })
}

export default GenreList
