export enum Routes {
  Root = '/',
  UserChangeInfo = '/change_info',
  UserNewerVideos = '/videos/newer',
  UserPopularVideos = '/videos/popular',
  UserCategoryVideos = '/videos/category/:categoryId',
  UserRecommendCourses = '/courses/recommends',
  UserPopularExercises = '/exercises',
  UserCommonWorries = '/worries',
  UserTagVideos = '/videos/tag/:tagId',
  UserPartTagVideos = '/videos/part_tag/:partTagId',
  UserPlayTimeVideos = '/videos/playtime/:playTime',
  UserVideoDetail = '/video/:videoId',
  UserViewHistory = '/view_history',
  UserCourseVideoDetail = '/course/:courseId/:videoId',
  Register = '/register',
  Login = '/login',
  PasswordResetRequest = '/password_reset_request',
  PlanSelect = '/plan_select',
  Unsubscribe = '/unsubscribe',
  Setting = '/setting',
  Home = '/home',
  Admin = '/admin',
  AdminRegister = '/admin/register',
  AdminLogin = '/admin/login',
  AdminIndex = '/admin/index',
  AdminVideo = '/admin/video',
  AdminVideoCreate = '/admin/video_create',
  AdminVideoEdit = '/admin/video_edit',
  AdminCourse = '/admin/course',
  AdminCourseCreate = '/admin/course_create',
  AdminCourseEdit = '/admin/course_edit',
  AdminCorporate = '/admin/corporate',
  AdminCorporateCreate = '/admin/corporate_create',
  AdminCorporateEdit = '/admin/corporate_edit',
  AdminUser = '/admin/user',
  AdminUserCreate = '/admin/user_create',
  AdminAdministrator = '/admin/administrator',
  AdminAdministratorCreate = '/admin/administrator_create',
  AdminAdministratorEdit = '/admin/administrator_edit',
  AdminUserViewHistory = '/admin/users_history',
  AdminCategory = '/admin/category',
  AdminCategoryCreate = '/admin/category_create',
  AdminCategoryEdit = '/admin/category_edit',
  AdminTag = '/admin/tag',
  AdminTagCreate = '/admin/tag_create',
  AdminTagEdit = '/admin/tag_edit',
  AdminPartTag = '/admin/part_tag',
  AdminPartTagCreate = '/admin/part_tag_create',
  AdminPartTagEdit = '/admin/part_tag_edit',
}

export enum ViewType {
  LOGIN,
  REGISTER,
  LOADING,
  GONE,
  VISIBLE,
}

export enum CorporateInputType {
  NAME,
  ADDRESS,
  COUPON_CODE,
  COUPON_ENABLED_COUNT,
}
