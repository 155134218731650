import React from 'react'
import { NavItem, NavLink } from 'reactstrap'
import { signOut } from 'services/admin/account'

export enum ItemActionType {
  LOGOUT,
}

interface IItemAction {
  action: ItemActionType
}

const ItemAction: React.FC<IItemAction> = (props: IItemAction) => {
  return (
    <NavItem>
      <NavLink
        onClick={() => doAction(props.action)}
        style={{ cursor: 'pointer' }}
      >
        {getContent(props.action)}
      </NavLink>
    </NavItem>
  )
}

function getContent(action: ItemActionType): string {
  if (action === ItemActionType.LOGOUT) {
    return 'ログアウト'
  }
  return ''
}

function doAction(action: ItemActionType) {
  if (action === ItemActionType.LOGOUT) {
    signOut()
  }
}

export default ItemAction
