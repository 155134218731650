import { Routes } from 'common/enums'
import { IVideo } from 'common/interfaces/video'
import { removeImageFile, upload } from 'repositories/storage/firebase_video'
import { removeImage, update } from 'repositories/store/firebase_video'
import { alertService } from 'services/alert'

/**
 * 動画レコード更新処理APIを起動
 */
export const updateVideo = async (
  props: any,
  file: File | undefined,
  video: IVideo
): Promise<void> => {
  if (!video.title) {
    alertService.show(false, 'タイトルを入力してください')
    return
  }

  try {
    if (file) {
      video.image = await upload(video, file)
    }
    await update(video)

    props.history.push(Routes.AdminVideo)
  } catch (error) {
    console.log(error)
  }
}

/**
 * 画像を削除する(ファイル削除 & DBへの更新)
 */
export const deleteImage = async (props: any, video: IVideo): Promise<void> => {
  try {
    await removeImageFile(video)
    await removeImage(video)

    props.history.push(Routes.AdminVideo)
  } catch (error) {
    console.log(error)
  }
}
