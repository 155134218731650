import React, { useContext, useRef, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
  Container,
  CustomInput,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  UncontrolledCollapse,
} from 'reactstrap'
import PinkButton from 'components/atoms/Button/Block/PinkButton'
import { IPlanSelect } from 'common/interfaces/plan/select'
import {
  couponByUseStart,
  transitionToClearingRegister,
} from 'services/plan/select'
import './FormBody.scss'
import { AuthContext } from 'providers/AuthProvider'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const planSelectElem = useRef<IPlanSelect>({} as IPlanSelect)
  const [planSelectState, setPlanSelect] = useState<IPlanSelect>(
    {} as IPlanSelect
  )
  const [couponCode, setCouponCode] = useState<string>('')
  const transition = (): Promise<void> =>
    transitionToClearingRegister(planSelectState)
  const coupon = (): Promise<void> => couponByUseStart(storeCache, couponCode)

  const consumedRate = 1.1
  const price12monthSingle = 1980
  const price12monthCalculated = price12monthSingle * consumedRate
  const priceMonthlySingle = 2400
  const priceMonthCalculated = priceMonthlySingle * consumedRate

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-3 font-weight-bold">
                  プランを選択してください
                </div>
                <div className="text-center text-red mb-1 font-weight-bold">
                  ２週間の無料体験キャンペーン中！
                </div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <CustomInput
                      ref={planSelectElem.current.plan}
                      onChange={() =>
                        setPlanSelect({
                          ...planSelectState,
                          plan: '1012',
                        })
                      }
                      type="radio"
                      id="yearPlan"
                      name="planSelect"
                      className="price-plan-space"
                    >
                      <div className="card bg-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col font-weight-bold">
                              12ヶ月プラン
                            </div>
                          </div>
                          <div className="row no-gutters">
                            <div className="col-3 text-right mt-2 ml-1 price-label-12">
                              月々
                              <br />￥
                            </div>
                            <div className="col-7 text-left ml-3">
                              <h1 className="price-label price-label-12">
                                {price12monthSingle}
                              </h1>
                              <span className="text-muted">
                                (税込価格
                                {price12monthCalculated}
                                円)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CustomInput>
                    <CustomInput
                      ref={planSelectElem.current.plan}
                      onChange={() =>
                        setPlanSelect({
                          ...planSelectState,
                          plan: '1011',
                        })
                      }
                      type="radio"
                      id="monthPlan"
                      name="planSelect"
                      className="price-plan-space"
                    >
                      <div className="card bg-secondary">
                        <div className="card-body">
                          <div className="row">
                            <div className="col font-weight-bold">
                              月額プラン
                            </div>
                          </div>
                          <div className="row no-gutters">
                            <div className="col-3 text-right mt-2 ml-1 price-label-month">
                              月々
                              <br />￥
                            </div>
                            <div className="col-7 text-left ml-3">
                              <h1 className="price-label price-label-month">
                                {priceMonthlySingle}
                              </h1>
                              <span className="text-muted">
                                (税込価格
                                {priceMonthCalculated}
                                円)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CustomInput>
                  </FormGroup>

                  <div className="text-center">
                    <PinkButton
                      onClick={transition}
                      className="my-4"
                      content="クレジットカード登録へ"
                    />
                  </div>
                </Form>

                <Form role="form" className="mt-5">
                  <div className="text-center mb-3 font-weight-bold">
                    <a
                      href="#inputCouponCodeToggler"
                      id="inputCouponCodeToggler"
                    >
                      クーポンコードをお持ちの方
                    </a>
                  </div>
                  <UncontrolledCollapse toggler="#inputCouponCodeToggler">
                    <InputGroup>
                      <Input
                        placeholder="クーポンコード"
                        type="text"
                        onChange={(e) => setCouponCode(e.target.value)}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="default" outline onClick={coupon}>
                          適用
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </UncontrolledCollapse>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
