import axios from 'axios'
import { Routes } from 'common/enums'
import { alertService } from 'services/alert'

/**
 * 管理者レコード作成処理APIを起動
 */
export const SendAdministrator = async (
  props: any,
  email: String
): Promise<void> => {
  if (!email) {
    alertService.show(false, '全項目を入力してください')
    return
  }

  try {
    const URI =
      String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI) + 'user/invite'
    await axios.post(URI, { email: email })

    alertService.show(true, 'メールを送信しました')

    props.history.push(Routes.AdminAdministrator)
  } catch (error) {
    alertService.show(false, `Error: ${error.response.status}`)
  }
}
