import { storage } from 'common/firebase'
import { ICategory } from 'common/interfaces/category'

const SubDirName = 'categories'

/**
 * categories image upload
 */
export const upload = async (
  category: ICategory,
  file: File | undefined
): Promise<string> => {
  let fullPath: string = ''
  try {
    if (file) {
      const uploadTask = await storage
        .ref()
        .child(SubDirName)
        .child(category.id)
        .put(file)

      fullPath = await uploadTask.ref.getDownloadURL()
    }
  } catch (error) {
    console.log(error)
  }

  return fullPath
}

/**
 * delete specified category image file
 */
export const removeImageFile = async (category: ICategory): Promise<void> => {
  try {
    await storage.ref().child(SubDirName).child(category.id).delete()
  } catch (error) {
    console.log(error)
  }
}
