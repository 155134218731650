import { auth } from 'common/firebase'
import { modalService } from 'services/modal'
import { alertService } from 'services/alert'

/**
 * Firebaseの認証を解除する
 */
export const signOut = () => {
  modalService.show('ログアウトしますがよろしいですか？', async () => {
    try {
      await auth.signOut()
      alertService.show(true, 'ログアウトしました')
    } catch (error) {
      console.log(error)
      alertService.show(false, 'ログアウトできませんでした')
    }
  })
}
