import React from 'react'
import { Container } from 'reactstrap'

interface IH1 {
  title: string
}

class H1 extends React.Component<IH1> {
  render() {
    return (
      <div className="header pb-3 pt-md-3">
        <Container fluid>
          <h2>{this.props.title}</h2>
        </Container>
      </div>
    )
  }
}

export default H1
