import React from 'react'
import { NavLink as NavLinkRRD } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap'

interface IItemLink {
  setCollapseOpen: React.Dispatch<React.SetStateAction<boolean>>
  nextPage: string
  content: string
}

class ItemLink extends React.Component<IItemLink> {
  render() {
    return (
      <NavItem>
        <NavLink
          to={this.props.nextPage}
          tag={NavLinkRRD}
          onClick={() => this.props.setCollapseOpen(false)}
        >
          {this.props.content}
        </NavLink>
      </NavItem>
    )
  }
}

export default ItemLink
