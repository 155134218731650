import React from 'react'
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next'
import 'components/atoms/Table/FixedColumn.css'
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import filterFactory from 'react-bootstrap-table2-filter'

/**
 * **pagination button Design** for size of page that showable in one page
 */
const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}: any) => (
  <div className="btn-group" role="group">
    {options.map((option: any) => {
      const isSelect = currSizePerPage === `${option.page}`
      return (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
        >
          {option.text}
        </button>
      )
    })}
  </div>
)

/**
 * create pagination option object for table
 */
function create_options(row_size: number) {
  return {
    sizePerPageRenderer,
    custom: true,
    totalSize: row_size,
  }
}

/**
 * create default table
 * fixed_operation_column => 操作ボタンの右固定, true で固定
 */
export function CreateTable<T>(
  columns: ColumnDescription[],
  data: T[],
  key: string,
  fixed_operation_column: boolean,
  opt?: any
): JSX.Element {
  return (
    <div className={fixed_operation_column ? 'fixedOperationCol' : ''}>
      <BootstrapTable
        {...opt}
        bootstrap4
        striped
        hover
        wrapperClasses="responsive-table"
        columns={columns}
        keyField={key}
        data={data}
        filter={filterFactory()}
      />
    </div>
  )
}

/**
 * Create Table with pagination function
 */
export function CreateNewTableWithPagination<T>(
  columns: ColumnDescription[],
  data: T[],
  key: string,
  fixed_operation_column: boolean
): JSX.Element {
  const crt_pg_stl = (float_at: string): any => {
    // create_pagination_elements_style
    return { float: float_at, marginTop: '10px' }
  }
  return (
    <PaginationProvider
      pagination={paginationFactory(create_options(data.length))}
    >
      {({ paginationProps, paginationTableProps }: any) => (
        <div>
          {CreateTable<T>(
            columns,
            data,
            key,
            fixed_operation_column,
            paginationTableProps
          )}
          <div style={crt_pg_stl('left')}>
            <SizePerPageDropdownStandalone {...paginationProps} />
          </div>
          <div style={crt_pg_stl('right')}>
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  )
}
