import axios from 'axios'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICorporate } from 'common/interfaces/corporate'
import { IUser } from 'common/interfaces/user'
import { reloadCachedCorporates } from 'providers/AuthProvider'
import { remove } from 'repositories/store/firebase_corporate'
import { findByCorporateId } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * 各有効人数を算出し取得する
 */
export const setCorporatesData = async (
  corporates: ICorporate[],
  setCorporates: React.Dispatch<React.SetStateAction<ICorporate[]>>
): Promise<void> => {
  try {
    for (const corporate of corporates) {
      const users: IUser[] = (await findByCorporateId(corporate.id)).data
      corporate.remaining_enabled_count =
        Number(corporate.coupon_enabled_count) - users.length
    }
    setCorporates(corporates)
  } catch (error) {
    console.log(error)
  }
}

/**
 * corporatesテーブルのレコードを削除する
 */
export const removeCorporate = (
  corporate: ICorporate,
  storeCache: IStoreCache
): void => {
  modalService.show('本当に削除してもよろしいですか？', async () => {
    try {
      // クーポンで入会したユーザを全削除
      const users: IUser[] = (await findByCorporateId(corporate.id)).data
      const URL =
        String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI) +
        'user/remove_signed_up_by_coupon'
      await axios.post(URL, { users: users })

      // 法人削除
      await remove(corporate)
      await reloadCachedCorporates(storeCache)

      alertService.show(true, '削除しました')
    } catch (error) {
      console.log(error)
      alertService.show(false, '削除できませんでした')
    }
  })
}
