import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITag } from 'common/interfaces/tag'
import { reloadCachedTags } from 'providers/AuthProvider'
import { upload } from 'repositories/storage/firebase_tag'
import { store } from 'repositories/store/firebase_tag'
import { alertService } from 'services/alert'

/**
 * 動画レコード作成処理APIを起動
 */
export const createTag = async (
  props: any,
  file: File | undefined,
  tag: ITag,
  storeCache: IStoreCache
): Promise<void> => {
  if (!tag.name) {
    alertService.show(false, 'タグ名を入力してください')
    return
  }

  try {
    tag.image = await upload(tag, file)
    tag.created_at = firebase.firestore.Timestamp.now()
    tag.updated_at = firebase.firestore.Timestamp.now()
    await store(tag)

    await reloadCachedTags(storeCache)
    props.history.push(Routes.AdminTag)
  } catch (error) {
    console.log(error)
  }
}
