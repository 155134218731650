import { auth } from 'common/firebase'
import { IResetRequest } from 'common/interfaces/password/reset_request'
import { alertService } from 'services/alert'

/**
 * パスワードをリセットするためのメールを操作ユーザに送付する
 */
export const SendPasswordResetEmail = async (
  resetRequest: IResetRequest
): Promise<void> => {
  if (!resetRequest.email) {
    alertService.show(false, 'メールアドレスを入力してください')
    return
  }

  try {
    await auth.sendPasswordResetEmail(resetRequest.email)

    alertService.show(true, 'ご入力いただいたメールアドレスに送信いたしました')
  } catch (error: any) {
    let message = ''
    switch (error.code) {
      case 'auth/invalid-email':
        message = 'メールアドレスの形式が正しくありません'
        break
      case 'auth/user-not-found':
        message =
          'ご入力いただいたメールアドレスからはユーザーが見つかりません。ユーザーが削除された可能性があります。'
        break
      default:
        message = error.code
    }
    alertService.show(false, message)
  }
}
