import React from 'react'
import { ICategory } from './interfaces/category'
import { ICorporate } from './interfaces/corporate'
import { IPartTag } from './interfaces/part_tag'
import { ITag } from './interfaces/tag'
import { IUser } from './interfaces/user'
import { IVideo } from './interfaces/video'

/**
 * 値がnullの場合、空文字に変換する
 * @param val
 */
export const convertNULLIntoString = (
  val: string | number | null | undefined
): string => {
  if (val === null || val === undefined || String(val) === 'null') {
    return ''
  }
  return String(val)
}

/**
 * スターテスを文字列に変換する
 *
 * status: 1 → 非公開, 2 → 公開
 */
export const convertStatusIntoString = (status: number): string => {
  let statString: string = '非公開'
  if (status === 2) {
    statString = '公開'
  }

  return statString
}

/**
 * 加入プラン（月額・年額）を文字列に変換する
 *
 * code: 1011 → 月額, 1012 → 年額, -> 法人プラン
 */
export const convertJoinPlan = (user: IUser): string => {
  if (!user.payment || user.payment.length === 0) {
    return ''
  }

  if (user.payment[0].result === '999') {
    return '法人プラン'
  } else if (user.subscription_plan === '1012') {
    return '年額'
  } else {
    return '月額'
  }
}

/**
 * react-selectで使用するタグ・部位タグの選択肢を生成する
 */
export const createTagOptions = (
  tags: ITag[] | IPartTag[]
): {
  value: string
  label: string
}[] => {
  const options: { value: string; label: string }[] = []
  tags.forEach((tag: ITag | IPartTag) => {
    options.push({
      value: tag.id,
      label: tag.name ?? '',
    })
  })
  return options
}

/**
 * react-selectで使用する動画の選択肢を生成する
 */
export const createVideoOptions = (
  videos: IVideo[]
): {
  value: string
  label: string
}[] => {
  const options: { value: string; label: string }[] = []
  videos.forEach((video) => {
    options.push({
      value: video.id,
      label: video.title ?? '',
    })
  })
  return options
}

/**
 * 改行を<br>に置換する
 */
export const nl2br = (text: string | null): (string | JSX.Element)[] => {
  if (text === null) {
    return []
  }
  const regex = /(\n)/g
  return text.split(regex).map((line, index) => {
    if (line.match(regex)) {
      return React.createElement('br', { key: index })
    } else {
      return line
    }
  })
}

/**
 * 性別の一覧を生成する
 */
export const genderList = (): string[] => {
  return ['', '男性', '女性', 'その他']
}

/**
 * 都道府県の一覧を生成する
 */
export const prefectureList = (): string[] => {
  return [
    '',
    '北海道',
    '青森県',
    '岩手県',
    '宮城県',
    '秋田県',
    '山形県',
    '福島県',
    '茨城県',
    '栃木県',
    '群馬県',
    '埼玉県',
    '千葉県',
    '東京都',
    '神奈川県',
    '新潟県',
    '富山県',
    '石川県',
    '福井県',
    '山梨県',
    '長野県',
    '岐阜県',
    '静岡県',
    '愛知県',
    '三重県',
    '滋賀県',
    '京都府',
    '大阪府',
    '兵庫県',
    '奈良県',
    '和歌山県',
    '鳥取県',
    '島根県',
    '岡山県',
    '広島県',
    '山口県',
    '徳島県',
    '香川県',
    '愛媛県',
    '高知県',
    '福岡県',
    '佐賀県',
    '長崎県',
    '熊本県',
    '大分県',
    '宮崎県',
    '鹿児島県',
    '沖縄県',
  ]
}

/**
 * 利用目的の一覧を生成する
 */
export const purposeOption = (): { value: string; label: string }[] => {
  return [
    { value: 'ダイエット', label: 'ダイエット' },
    { value: 'ボディメイク', label: 'ボディメイク' },
    { value: 'スタイルUP', label: 'スタイルUP' },
    { value: '体力UP', label: '体力UP' },
    { value: '筋力UP', label: '筋力UP' },
    { value: 'ストレッチ', label: 'ストレッチ' },
    { value: '姿勢改善', label: '姿勢改善' },
    { value: '腰痛肩こり改善', label: '腰痛肩こり改善' },
    { value: 'その他', label: 'その他' },
  ]
}

/**
 * 健康面で気になる症状の一覧を生成する
 */
export const symptomsOption = (): { value: string; label: string }[] => {
  return [
    { value: '運動不足', label: '運動不足' },
    { value: 'メタボ', label: 'メタボ' },
    { value: '首こり', label: '首こり' },
    { value: '肩こり', label: '肩こり' },
    { value: '腰痛', label: '腰痛' },
    { value: '猫背', label: '猫背' },
    { value: '眼精疲労', label: '眼精疲労' },
    { value: '生理不順', label: '生理不順' },
    { value: 'その他', label: 'その他' },
  ]
}

/**
 * 睡眠時間の一覧を生成する
 */
export const sleepingTimeList = (): string[] => {
  return ['', '~5時間', '6~7時間', '8時間~']
}

/**
 * １日の歩行時間の一覧を生成する
 */
export const walkingTimeList = (): string[] => {
  return ['', '~10分', '20~40分', '60分~']
}

/**
 * `sort_num`でソートした配列を返却
 * @param array `ICategory[] | IPartTag[] | ITag[]`
 * @returns `ICategory[] | IPartTag[] | ITag[]`
 */
export const sortBySortNumber = (
  array: ICategory[] | IPartTag[] | ITag[]
): ICategory[] | IPartTag[] | ITag[] => {
  return array.sort((a, b) => {
    if (a.sort_num === null) return 1
    if (b.sort_num === null) return -1
    if (a.sort_num! < b.sort_num!) return -1
    if (a.sort_num! > b.sort_num!) return 1
    return 0
  })
}

/**
 * 運動時間の数値を分表記へ
 */
export const numberPlayTime2string = (playTime: number): string => {
  return `${playTime}分`
}

/**
 * 消費カロリーを計算する
 *
 * 少数点以下第二位切り捨て、第一位まで
 */
export const calcKcal = (
  weight: number | null | undefined,
  video: IVideo
): number => {
  if ((weight ?? null) === null || weight === 0) {
    return 0
  }

  const mets = Number(video.mets)

  const kcal = ((weight! * 1.05 * video.play_time) / 60) * mets
  return Math.floor(kcal * 10) / 10
}

/**
 * 消費カロリーを表示する
 *
 * 0以下の場合は'-'表示。
 */
export const showKcal = (kcal: number): string => {
  return kcal > 0 ? kcal.toString() : '-'
}

/**
 * is vimeo video url?
 * @param url video url
 * @returns isMatched
 */
export const isVimeoVideoUrl = (url: string | null): boolean => {
  return (
    url !== null &&
    url.match(RegExp(/^https?:\/\/vimeo\.com\/\d+?\/.+?$/)) !== null
  )
}

/**
 * is youtube video url?
 * @param url video url
 * @returns isMatched
 */
export const isYoutubeVideoUrl = (url: string | null): boolean => {
  return extractYoutubeVideoId(url) !== null
}

/**
 * extract youtube video id
 * @param url youtube video url
 * @returns if matched videoId else null
 */
export const extractYoutubeVideoId = (url: string | null): string | null => {
  const matcher =
    url?.match(
      RegExp(/^https?:\/\/(www\.youtube\.com|youtu\.be)\/(watch\?v=)?(.+?)$/)
    ) ?? null
  if (matcher !== null) {
    return matcher[3]
  }
  return null
}

/**
 * 誕生日の年を表示する(現在年から100年前まで)
 */
export const birthYear = (): number[] => {
  const thisYear = new Date().getFullYear()
  const offset = 100
  return Array.from({ length: offset + 1 }, (_, i) => thisYear - i)
}

/**
 * 誕生日の月を表示する
 */
export const birthMonth = (): number[] => {
  return Array.from({ length: 12 }, (_, i) => i + 1)
}

/**
 * 誕生日の日を表示する
 */
export const birthDay = (): number[] => {
  return Array.from({ length: 31 }, (_, i) => i + 1)
}

/**
 * 誕生日の年を取得する
 */
export const getBirthYear = (birthdate: string | null | undefined) => {
  return birthdate?.split('-')[0]
}

/**
 * 誕生日の月を取得する
 */
export const getBirthMonth = (birthdate: string | null | undefined) => {
  return birthdate?.split('-')[1]
}

/**
 * 誕生日の日を取得する
 */
export const getBirthDay = (birthdate: string | null | undefined) => {
  return birthdate?.split('-')[2]
}

/**
 * 誕生日の年を変更する
 */
export const changeBirthYear = (
  birthdate: string | null | undefined,
  year: string
): string => {
  const birthMonth = birthdate?.split('-')[1]
  const birthDay = birthdate?.split('-')[2]
  return `${year}-${birthMonth}-${birthDay}`
}

/**
 * 誕生日の月を変更する
 */
export const changeBirthMonth = (
  birthdate: string | null | undefined,
  month: string
): string => {
  const birthYear = birthdate?.split('-')[0]
  const birthDay = birthdate?.split('-')[2]
  return `${birthYear}-${month}-${birthDay}`
}

/**
 * 誕生日の日を変更する
 */
export const changeBirthDay = (
  birthdate: string | null | undefined,
  day: string
): string => {
  const birthYear = birthdate?.split('-')[0]
  const birthMonth = birthdate?.split('-')[1]
  return `${birthYear}-${birthMonth}-${day}`
}

/**
 * クーポンで入会した法人名を取得する
 */
export const getCorporateName = (
  corporateID: string | null,
  corporates: ICorporate[]
): string | null => {
  if (corporateID === null) {
    return null
  }

  const corporateData: ICorporate | undefined = corporates.find(
    (corporate: ICorporate) => corporate.id === corporateID
  )
  return corporateData === undefined ? null : corporateData.name
}
