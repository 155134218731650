import { findCachedTagsNonNull } from 'common/find_store_cache'
import { IVideo } from 'common/interfaces/video'
import { calcKcal, showKcal } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Card, CardImg } from 'reactstrap'
import './videocard.scss'

interface IVideoCard {
  className?: string
  video: IVideo
}

const VideoCard: React.FC<IVideoCard> = (props: IVideoCard) => {
  const storeCache = useContext(AuthContext).storeCache

  const imageElement = props.video.image ? (
    <CardImg top src={props.video.image} />
  ) : (
    <div className="box" />
  )

  const tagElements: JSX.Element[] = []
  findCachedTagsNonNull(storeCache, props.video.tag_ids).forEach((tag) => {
    tagElements.push(<div key={tag.id}>{tag.name}</div>)
  })

  return (
    <Card className={(props.className ?? '') + ' video-card'}>
      <div>{imageElement}</div>
      <div className="body">
        <h3 className="title">{props.video.title}</h3>
        <div className="tags-kcal">
          <div className="tags">{tagElements}</div>
          <div className="kcal">
            <span>
              {showKcal(calcKcal(storeCache.user?.weight, props.video))}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default VideoCard
