import {
  findCachedPartTagsNonNull,
  findCachedTagsNonNull,
} from 'common/find_store_cache'
import { selectImage } from 'common/image'
import { ICategory } from 'common/interfaces/category'
import { ICourse } from 'common/interfaces/course'
import { IVideo } from 'common/interfaces/video'
import {
  convertNULLIntoString,
  createTagOptions,
  createVideoOptions,
} from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import Select from 'react-select'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { deleteImage, updateCourse } from 'services/admin/course_edit'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const course: ICourse = props.location.state.course
  const allVideos: IVideo[] = props.location.state.allVideos
  const initCourse: ICourse = {
    id: course.id,
    title: course.title,
    image: course.image,
    category_id: course.category_id,
    tag_ids: course.tag_ids,
    body_part_ids: course.body_part_ids,
    video_ids: course.video_ids,
    overview: course.overview,
    created_at: course.created_at,
    updated_at: course.updated_at,
  }
  const [courseEdit, setCourseEdit] = useState<ICourse>(initCourse)
  const [file, setFile] = useState<File>()

  const videoOptions = createVideoOptions(allVideos)
  const defaultVideos = createDefaultVideos(allVideos, courseEdit.video_ids)

  const categories = storeCache.categories
  const tagOptions = createTagOptions(storeCache.tags)
  const partTagOptions = createTagOptions(storeCache.partTags)

  const defaultTags: { value: string; label: string }[] = []
  findCachedTagsNonNull(storeCache, courseEdit.tag_ids).forEach((tag) => {
    defaultTags.push({ value: tag.id, label: tag.name ?? '' })
  })
  const defaultPartTags: { value: string; label: string }[] = []
  findCachedPartTagsNonNull(storeCache, courseEdit.body_part_ids).forEach(
    (pt) => {
      defaultPartTags.push({ value: pt.id, label: pt.name ?? '' })
    }
  )

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void =>
    selectImage<ICourse>(e, setFile, setCourseEdit, courseEdit)
  const delImage = () => deleteImage(props, courseEdit, storeCache)
  const update = () => updateCourse(props, file, courseEdit, storeCache)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="courseTitleForm">コースタイトル</Label>
            <Input
              id="courseTitleForm"
              value={convertNULLIntoString(courseEdit.title)}
              onChange={(e) =>
                setCourseEdit({ ...courseEdit, title: e.target.value })
              }
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="courseThumbnailForm">サムネイル</Label>
            <CustomInput
              id="courseThumbnailForm"
              onChange={(e) => onChangeImage(e)}
              type="file"
            />
          </FormGroup>
        </Col>
      </Row>
      {course.image !== null && (
        <Row className="mb-3">
          <Col>
            <Label>設定されているサムネイル</Label>
            <Row>
              <Col md="4">
                <img
                  height={150}
                  width="auto"
                  src={course.image}
                  alt={String(course.title)}
                />
              </Col>
              <Col md="8">
                <Button
                  onClick={() => delImage()}
                  className="my-4"
                  color="danger"
                  type="button"
                >
                  削除
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="courseCategoryForm">カテゴリ</Label>
            <Input
              id="courseCategoryForm"
              value={courseEdit.category_id ?? undefined}
              onChange={(e) =>
                setCourseEdit({ ...courseEdit, category_id: e.target.value })
              }
              type="select"
            >
              <option />
              {categories.map((category: ICategory, key: number) => {
                return (
                  <option key={key} value={category.id}>
                    {category.name}
                  </option>
                )
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="courseTagsForm">タグ</Label>
            <Select
              id="courseTagsForm"
              defaultValue={defaultTags}
              onChange={(e) => {
                setCourseEdit({
                  ...courseEdit,
                  tag_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }}
              options={tagOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="coursePartTagsForm">部位</Label>
            <Select
              id="coursePartTagsForm"
              defaultValue={defaultPartTags}
              onChange={(e) => {
                setCourseEdit({
                  ...courseEdit,
                  body_part_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }}
              options={partTagOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="courseVideosForm">動画タイトル</Label>
            <Select
              id="courseVideosForm"
              defaultValue={defaultVideos}
              onChange={(e) => {
                setCourseEdit({
                  ...courseEdit,
                  video_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }}
              options={videoOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="courseOverviewForm">概要</Label>
            <Input
              id="courseOverviewForm"
              defaultValue={convertNULLIntoString(courseEdit.overview)}
              onChange={(e) =>
                setCourseEdit({ ...courseEdit, overview: e.target.value })
              }
              type="textarea"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={update} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

function createDefaultVideos(
  videos: IVideo[],
  videoIds: string[]
): {
  value: string
  label: string
}[] {
  const result: { value: string; label: string }[] = []
  videoIds.forEach((vId) => {
    const video = videos.find((v) => v.id === vId) ?? null
    if (video === null) {
      result.push({ value: '', label: '' })
    } else {
      result.push({
        value: video.id,
        label: video.title ?? '',
      })
    }
  })
  return result
}

export default FormBody
