export const makeCategoryLink = (categoryId: string): string => {
  return `/videos/category/${categoryId}`
}

export const makeTagLink = (tagId: string): string => {
  return `/videos/tag/${tagId}`
}

export const makePlayTimeLink = (minutes: string): string => {
  return `/videos/playtime/${minutes}`
}

/**
 * URLの運動時間パラメーターをパース
 *
 * ex. '1m-3m' -> [1, 3]
 *
 * ex. '16m' -> [16]
 */
export const parsePlayTimeParam = (playTimeParam: any): number[] => {
  if (!playTimeParam) {
    return [0, 0]
  }
  const params = String(playTimeParam).replace(/m/g, '').split('-')
  if (params.length > 1) {
    return [Number(params[0]), Number(params[1])]
  } else {
    return [Number(params[0])]
  }
}

export const makePartTagLink = (partTagId: string): string => {
  return `/videos/part_tag/${partTagId}`
}

export const makeVideoLink = (videoId: string): string => {
  return `/video/${videoId}`
}

export const makeCourseVideoLink = (courseId: string, videoId: string) => {
  return `/course/${courseId}/${videoId}`
}
