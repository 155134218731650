import firebase, { db } from 'common/firebase'
import { ICorporate, ICorporateResponse } from 'common/interfaces/corporate'

const Corporates = db.collection('corporate')

/**
 * corporate all get
 */
export const get = async (): Promise<ICorporateResponse> => {
  const corporateResponse: ICorporateResponse = { data: [] }
  const corporateDatas = await Corporates.get()
  corporateDatas.docs.forEach((corporate) => {
    const courseData: ICorporate = makeCorporate(corporate)
    corporateResponse.data.push(courseData)
  })

  return corporateResponse
}

function makeCorporate(
  corporate: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): ICorporate {
  const data = corporate.data()
  return {
    id: data.id,
    name: data.name,
    address: data.address,
    coupon_code: data.coupon_code,
    coupon_enabled_count: data.coupon_enabled_count,
    remaining_enabled_count: data.remaining_enabled_count,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}

/**
 * corporate add
 */
export const store = async (corporate: ICorporate): Promise<void> => {
  await Corporates.doc(corporate.id).set(corporate)
}

/**
 * corporate update
 */
export const update = async (corporate: ICorporate): Promise<void> => {
  corporate.updated_at = firebase.firestore.Timestamp.now()

  await Corporates.doc(corporate.id).update(corporate)
}

/**
 * corporate delete
 */
export const remove = async (corporate: ICorporate): Promise<void> => {
  await Corporates.doc(corporate.id).delete()
}
