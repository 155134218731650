import axios from 'axios'
import { IUser } from 'common/interfaces/user'
import { get } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * usersのデータをすべて取得する
 */
export const getUsers = async (
  setData: React.Dispatch<React.SetStateAction<IUser[]>>
): Promise<void> => {
  try {
    setData(await get())
  } catch (error) {
    console.log(error)
  }
}

/**
 * usersでドキュメントを削除する
 */
export const removeUser = (
  user: IUser,
  SetData: React.Dispatch<React.SetStateAction<IUser[]>>
): void => {
  try {
    modalService.show(`${user.id}を本当に削除しますか？`, async () => {
      const URL =
        String(process.env.REACT_APP_FIREBASE_FUNCTIONS_URI) +
        'user/remove/' +
        user.id
      await axios.post(URL)
      await getUsers(SetData)

      alertService.show(true, `${user.id}の削除に成功しました`)
    })
  } catch (error) {
    console.log(error)
    alertService.show(false, error)
  }
}
