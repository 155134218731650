import Fotter from 'components/molecules/Fotter'
import FormBody from 'components/organisms/Login/FormBody'
import TopLink from 'components/organisms/Login/TopLink'
import React from 'react'

interface ILoginProps {
  props: any
}

export const Login: React.FC<ILoginProps> = (props: any) => {
  return (
    <>
      <FormBody {...props} />
      <TopLink />
      <Fotter />
    </>
  )
}

export default Login
