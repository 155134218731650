import { Col, Container, Nav, NavItem, NavLink, Row } from 'reactstrap'
import React from 'react'
import ImgIconComponent from 'components/atoms/Icon/ImgIcon'
import InstagramLogo from 'components/atoms/Icon/img/instaicon.svg'

const Fotter: React.FC = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col>
            <Nav className="justify-content-center">
              <NavItem>
                <NavLink href="https://www.instagram.com/online_fitness_masaru/">
                  <ImgIconComponent
                    iconPath={InstagramLogo}
                    description="インスタグラム"
                  />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Nav className="justify-content-center">
              <NavItem>
                <NavLink href="https://masaru-online.com/info/about.html">
                  会社概要
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://masaru-online.com/info/pg3045472.html">
                  利用規約
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Nav className="justify-content-center small">
              <NavItem>
                <NavLink>Copyright APPLICA. All Right Reserved.</NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Fotter
