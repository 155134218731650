import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICourse } from 'common/interfaces/course'
import { reloadCachedCourses } from 'providers/AuthProvider'
import { removeImageFile } from 'repositories/storage/firebase_course'
import { remove } from 'repositories/store/firebase_course'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * coursesテーブルのレコードを削除する
 */
export const removeCourse = (course: ICourse, storeCache: IStoreCache) => {
  modalService.show('本当に削除してもよろしいですか？', async () => {
    try {
      await removeImageFile(course)
      await remove(course)
      await reloadCachedCourses(storeCache)
      alertService.show(true, '削除しました')
    } catch (error) {
      console.log(error)
      alertService.show(false, '削除できませんでした')
    }
  })
}
