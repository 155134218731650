import * as H from 'history'
import React from 'react'
import { Button, Row } from 'reactstrap'

interface IButtonLeft {
  history: H.History
  nextPage: string
  content: string
}

class ButtonLeft extends React.Component<IButtonLeft> {
  render() {
    return (
      <Row className="justify-content-md-end mb-3">
        <Button
          onClick={() => this.props.history.push(this.props.nextPage)}
          color="default"
          size="sm"
          type="button"
        >
          {this.props.content}
        </Button>
      </Row>
    )
  }
}

export default ButtonLeft
