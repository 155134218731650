import React from 'react'
import { Link } from 'react-router-dom'
import './sidebysidetitle.css'

interface ISideBySideTitle {
  title: string
  subTitle: string
  subTitleLink: string
}

const SideBySideTitle: React.FC<ISideBySideTitle> = (
  props: ISideBySideTitle
) => {
  return (
    <div className="side-by-side-title">
      <h1>{props.title}</h1>
      <Link to={props.subTitleLink}>
        <span>{props.subTitle}</span>
      </Link>
    </div>
  )
}

export default SideBySideTitle
