import { Routes } from 'common/enums'
import ButtonLeft from 'components/atoms/Button/Left'
import H1 from 'components/atoms/Head/H1'
import FormBody from 'components/organisms/Admin/PartTagEdit/FormBody'
import React from 'react'
import { Container } from 'reactstrap'

const PartTagEdit: React.FC = (props: any) => {
  return (
    <div className="main-content">
      <H1 title="部位タグ編集" />

      <Container fluid>
        <ButtonLeft
          history={props.history}
          nextPage={Routes.AdminPartTag}
          content="一覧に戻る"
        />

        <FormBody {...props} />
      </Container>
    </div>
  )
}

export default PartTagEdit
