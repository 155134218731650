import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICorporate } from 'common/interfaces/corporate'
import { alertService } from 'services/alert'

/**
 * 法人フォーム用バリデーション
 */
export const validateForm = (
  corporate: ICorporate,
  storeCache: IStoreCache
): boolean => {
  if (!corporate.name) {
    alertService.show(false, 'カテゴリ名を入力してください')
    return false
  }
  if (!corporate.coupon_code || Number(corporate.coupon_code?.length) < 6) {
    alertService.show(false, 'クーポンコードは6文字以上で入力してください')
    return false
  }
  if (
    !corporate.coupon_enabled_count ||
    Number(corporate.coupon_enabled_count) < 0
  ) {
    alertService.show(false, 'クーポン有効人数を正常な値で入力してください')
    return false
  }
  for (const corporateData of storeCache.corporates) {
    if (corporateData.coupon_code === corporate.coupon_code) {
      alertService.show(false, '入力されたクーポンコードはすでに使われています')
      return false
    }
  }

  return true
}
