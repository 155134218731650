import React from 'react'

interface ImgIcon {
  iconPath: string
  description: string
}

export default class ImgIconComponent extends React.Component<ImgIcon> {
  render() {
    return (
      <React.Fragment>
        <img
          src={this.props.iconPath}
          alt={this.props.description}
          className="image"
        ></img>
      </React.Fragment>
    )
  }
}
