import React from 'react'
import { convertNULLIntoString } from 'common/utils'
import { IViewHistory } from 'common/interfaces/user'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { datetimeFormat } from 'common/times'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'

interface ITableBody {
  props: any
}

interface ITableUserViewHistory {
  timestamp: number
  viewed_date: string // timestamp
  title: string // title
  kcal: string
  mets: string
  exercise_length: string // TODO: playtime?
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  // TODO: use storeCache?
  const histories: IViewHistory[] | null = props.location.state.view_histories

  if (!histories) {
    return <>履歴がありません。</>
  }

  const columns: ColumnDescription<ITableUserViewHistory>[] = [
    { dataField: 'timestamp', text: 'as_id', hidden: true },
    { dataField: 'viewed_date', text: '視聴日時' },
    { dataField: 'title', text: '動画タイトル' },
    { dataField: 'kcal', text: 'kcal' },
    { dataField: 'mets', text: 'mets' },
    { dataField: 'exercise_length', text: '運動時間' },
  ]

  const data: ITableUserViewHistory[] = histories!.map(
    (history: IViewHistory) => {
      return {
        timestamp: history.timestamp,
        viewed_date: datetimeFormat(history.timestamp),
        title: convertNULLIntoString(history.title),
        kcal: convertNULLIntoString(history.kcal) + ' kcal',
        mets: convertNULLIntoString(history.mets) + ' mets',
        exercise_length: convertNULLIntoString(history.play_time) + ' min',
      }
    }
  )

  return CreateNewTableWithPagination<ITableUserViewHistory>(
    columns,
    data,
    'timestamp',
    false
  )
}

export default TableBody
