import Fotter from 'components/molecules/Fotter'
import FormBody from 'components/organisms/PlanSelect/FormBody'
import React from 'react'

interface IPlanSelectProps {
  props: any
}

export const PlanSelect: React.FC<IPlanSelectProps> = (props: any) => {
  return (
    <>
      <FormBody {...props} />
      <Fotter />
    </>
  )
}

export default PlanSelect
