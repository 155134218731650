import React from 'react'
import { Container } from 'reactstrap'

interface IH2 {
  title: string
}

class H2 extends React.Component<IH2> {
  render() {
    return (
      <div className="header pt-md-2">
        <Container fluid>
          <h3>{this.props.title}</h3>
        </Container>
      </div>
    )
  }
}

export default H2
