import { IUser, IViewHistory } from 'common/interfaces/user'
import { calc3typeMyScores } from 'common/view_history'
import Fotter from 'components/molecules/Fotter'
import ChooseCategory, {
  ChooseCategoryType,
} from 'components/molecules/User/ChooseCategory/ChooseCategory'
import CommonWorry from 'components/molecules/User/CommonWorry/CommonWorry'
import InterestParts from 'components/molecules/User/InterestParts/InterestParts'
import MyScoreGraph, {
  MyScoreGraphType,
} from 'components/molecules/User/MyScoreGraph/MyScoreGraph'
import PopularExercises from 'components/molecules/User/PopularExercises/PopularExercises'
import RecommendCourses from 'components/molecules/User/RecommendCourses/RecommendCourses'
import SetProfile from 'components/molecules/User/SetProfile/SetProfile'
import Header from 'components/organisms/User/Header/Header'
import SideScrollVideoList, {
  SideScrollVideoListType,
} from 'components/organisms/User/SideScrollVideoList/SideScrollVideoList'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import { getUserViewHistories } from 'services/user/user'
import './index.scss'

const Index: React.FC = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const [viewHistory, setViewHistory] = useState<IViewHistory[]>([])

  useEffect(() => {
    getUserViewHistories(setViewHistory)
  }, [setViewHistory])

  const isShowNameGraph =
    storeCache.user !== null && storeCache.user.weight! > 0
  const topElement = isShowNameGraph ? (
    [createNameElement(storeCache.user!), createMyScoreGraphs(viewHistory)]
  ) : (
    <SetProfile />
  )

  return (
    <div className="user-top">
      <Header />
      <Container className="circle-container">{topElement}</Container>
      <Container className="category-container">
        <ChooseCategory categoryType={ChooseCategoryType.EXERCISES} />
        <ChooseCategory categoryType={ChooseCategoryType.TIMES} />
        <ChooseCategory categoryType={ChooseCategoryType.PARTS} />
      </Container>
      <hr />
      <SideScrollVideoList
        sideScrollVideoListType={SideScrollVideoListType.NEW_VIDEOS}
      />
      <SideScrollVideoList
        sideScrollVideoListType={SideScrollVideoListType.POPULAR_VIDEOS}
      />
      <Container>
        <RecommendCourses />
        <PopularExercises />
        <CommonWorry />
        <InterestParts />
      </Container>
      <Fotter />
    </div>
  )
}

export default Index

function createNameElement(user: IUser): JSX.Element {
  const username = user?.name ?? null
  const myscoreTitle =
    username === null || username.trim() === ''
      ? 'マイスコア'
      : `${username}さんのマイスコア`
  return (
    <div key="username" className="username">
      <h1 className="title">{myscoreTitle}</h1>
    </div>
  )
}

function createMyScoreGraphs(viewHistories: IViewHistory[]): JSX.Element {
  const scores = calc3typeMyScores(viewHistories)

  return (
    <div key="my-score" className="my-score">
      <div className="my-score-graphs row">
        <MyScoreGraph
          key="month"
          className="graph col-12 col-md-4"
          graphType={MyScoreGraphType.MONTH}
          minutes={scores.month.minutes}
          kcal={scores.month.kcal}
        />
        <MyScoreGraph
          key="week"
          className="graph col-12 col-md-4"
          graphType={MyScoreGraphType.WEEK}
          minutes={scores.week.minutes}
          kcal={scores.week.kcal}
        />
        <MyScoreGraph
          key="today"
          className="graph col-12 col-md-4"
          graphType={MyScoreGraphType.TODAY}
          minutes={scores.today.minutes}
          kcal={scores.today.kcal}
        />
      </div>
      <div className="my-score-descriptions">
        <div className="total exe">
          <div className="bar"></div>
          <div className="text">合計運動時間</div>
        </div>
        <div className="total cal">
          <div className="bar"></div>
          <p className="text">合計消費カロリー</p>
        </div>
      </div>
    </div>
  )
}
