import { IStoreCache } from 'common/interfaces/auth_provider'
import { IUser } from 'common/interfaces/user'
import {
  birthDay,
  birthMonth,
  birthYear,
  changeBirthDay,
  changeBirthMonth,
  changeBirthYear,
  convertNULLIntoString,
  createTagOptions,
  genderList,
  getBirthDay,
  getBirthMonth,
  getBirthYear,
  prefectureList,
  purposeOption,
  sleepingTimeList,
  symptomsOption,
  walkingTimeList,
} from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import Select from 'react-select'
import {
  Button,
  Card,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { userUpdate } from 'services/user/setting'

interface IFormRowProps {
  storeCache: IStoreCache
  user: IUser
  setUser: React.Dispatch<React.SetStateAction<IUser>>
}

const FormRow: React.FC<IFormRowProps> = (props: IFormRowProps) => {
  const partTags = useContext(AuthContext).storeCache.partTags
  const partTagOption = createTagOptions(partTags)

  const update = async (isCounseling: boolean): Promise<void> =>
    userUpdate(props.storeCache, props.user, isCounseling)

  const updateBirthYear = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newBirthDate = changeBirthYear(props.user.birthdate, e.target.value)
    props.setUser({
      ...props.user,
      birthdate: newBirthDate,
    })
  }
  const updateBirthMonth = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newBirthDate = changeBirthMonth(props.user.birthdate, e.target.value)
    props.setUser({
      ...props.user,
      birthdate: newBirthDate,
    })
  }
  const updateBirthDay = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newBirthDate = changeBirthDay(props.user.birthdate, e.target.value)
    props.setUser({
      ...props.user,
      birthdate: newBirthDate,
    })
  }

  return (
    <Row>
      <Col lg={6} className="mt-3">
        <Card>
          <CardTitle className="display-4 px-4 my-2">
            プロフィール情報の設定
          </CardTitle>
          <Container className="px-4 pt-2 pb-3">
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label for="nameForm">ニックネーム</Label>
                  <Input
                    id="nameForm"
                    value={convertNULLIntoString(props.user.name)}
                    onChange={(e) =>
                      props.setUser({ ...props.user, name: e.target.value })
                    }
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <FormGroup>
                  <Label for="prefectureForm">居住地</Label>
                  <Input
                    id="prefectureForm"
                    value={String(props.user.prefecture)}
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        prefecture: e.target.value,
                      })
                    }
                    type="select"
                  >
                    {prefectureList().map((prefecture: string, key: number) => {
                      return <option key={key}>{prefecture}</option>
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>誕生日</Label>
                  <Input
                    value={getBirthYear(props.user.birthdate)}
                    onChange={(e) => updateBirthYear(e)}
                    id="birthYear"
                    type="select"
                  >
                    <option key={0} value="" />
                    {birthYear().map((year) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      )
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="1" className="my-auto">
                <span>/</span>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <Input
                    value={getBirthMonth(props.user.birthdate)}
                    onChange={(e) => updateBirthMonth(e)}
                    id="birthMonth"
                    type="select"
                  >
                    <option key={0} value="" />
                    {birthMonth().map((month) => {
                      return (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      )
                    })}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="1" className="my-auto">
                <span>/</span>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label>&nbsp;</Label>
                  <Input
                    value={getBirthDay(props.user.birthdate)}
                    onChange={(e) => updateBirthDay(e)}
                    id="birthDay"
                    type="select"
                  >
                    <option key={0} value="" />
                    {birthDay().map((day) => {
                      return (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      )
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="genderForm">性別</Label>
                  <Input
                    id="genderForm"
                    value={String(props.user.gender)}
                    onChange={(e) =>
                      props.setUser({ ...props.user, gender: e.target.value })
                    }
                    type="select"
                  >
                    {genderList().map((gender: string, key: number) => {
                      return <option key={key}>{gender}</option>
                    })}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="heightForm">身長</Label>
                  <Input
                    id="heightForm"
                    value={convertNULLIntoString(props.user.height)}
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        height: Number(e.target.value),
                      })
                    }
                    type="number"
                    step="0.1"
                  />
                  <div className="text-right">cm</div>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="weightForm">体重</Label>
                  <Input
                    id="weightForm"
                    value={convertNULLIntoString(props.user.weight)}
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        weight: Number(e.target.value),
                      })
                    }
                    type="number"
                    step="0.1"
                  />
                  <div className="text-right">kg</div>
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label for="bodyFatPercentageForm">体脂肪率</Label>
                  <Input
                    id="bodyFatPercentageForm"
                    value={convertNULLIntoString(
                      props.user.body_fat_percentage
                    )}
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        body_fat_percentage: e.target.value,
                      })
                    }
                    type="number"
                    step="0.1"
                  />
                  <div className="text-right">%</div>
                </FormGroup>
              </Col>
            </Row>

            <div className="text-center">
              <Button onClick={() => update(false)} color="info" type="button">
                保存
              </Button>
            </div>
          </Container>
        </Card>
      </Col>
      <Col lg={6} className="mt-3">
        <Card>
          <CardTitle className="display-4 px-4 my-2">カウンセリング</CardTitle>
          <Container className="px-4 pt-2 pb-3">
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label for="purposeForm">利用目的</Label>
                  <Select
                    id="purposeForm"
                    value={
                      props.user.purpose as { value: string; label: string }[]
                    }
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        purpose: e as { value: string; label: string }[],
                      })
                    }
                    options={purposeOption()}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label for="proportionForm">
                    気になるプロポーション・ボディメイクしたい部位
                  </Label>
                  <Select
                    id="proportionForm"
                    value={
                      props.user.proportion as {
                        value: string
                        label: string
                      }[]
                    }
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        proportion: e as { value: string; label: string }[],
                      })
                    }
                    options={partTagOption}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label for="symptomsForm">健康面で気になる症状</Label>
                  <Select
                    id="symptomsForm"
                    value={
                      props.user.symptoms as { value: string; label: string }[]
                    }
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        symptoms: e as { value: string; label: string }[],
                      })
                    }
                    options={symptomsOption()}
                    closeMenuOnSelect={false}
                    isMulti
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label for="sleepingTimeForm">睡眠時間</Label>
                  <Input
                    id="sleepingTimeForm"
                    value={convertNULLIntoString(props.user.sleeping_time)}
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        sleeping_time: e.target.value,
                      })
                    }
                    type="select"
                  >
                    {sleepingTimeList().map(
                      (sleepingTime: string, key: number) => {
                        return (
                          <option key={key} value={sleepingTime}>
                            {sleepingTime}
                          </option>
                        )
                      }
                    )}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="11">
                <FormGroup>
                  <Label for="walkingTimeForm">1日の歩行時間</Label>
                  <Input
                    id="walkingTimeForm"
                    value={convertNULLIntoString(props.user.walking_time)}
                    onChange={(e) =>
                      props.setUser({
                        ...props.user,
                        walking_time: e.target.value,
                      })
                    }
                    type="select"
                  >
                    {walkingTimeList().map(
                      (walkingTime: string, key: number) => {
                        return (
                          <option key={key} value={walkingTime}>
                            {walkingTime}
                          </option>
                        )
                      }
                    )}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <div className="text-center">
              <Button onClick={() => update(true)} color="info" type="button">
                保存
              </Button>
            </div>
          </Container>
        </Card>
      </Col>
    </Row>
  )
}

export default FormRow
