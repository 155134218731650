import { selectImage } from 'common/image'
import { ICategory } from 'common/interfaces/category'
import { ICourse } from 'common/interfaces/course'
import { IVideoResponse } from 'common/interfaces/video'
import { createTagOptions, createVideoOptions } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { createCourse } from 'services/admin/course_create'
import { getVideos } from 'services/admin/video'
import { v4 as uuidv4 } from 'uuid'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const initCourse: ICourse = {
    id: uuidv4(),
    title: null,
    image: null,
    category_id: null,
    tag_ids: [],
    body_part_ids: [],
    video_ids: [],
    overview: null,
    created_at: null,
    updated_at: null,
  }
  const courseRef = useRef<ICourse>(initCourse)
  const [course, setCourse] = useState<ICourse>(initCourse)
  const [file, setFile] = useState<File>()

  const [videos, setVideos] = useState<IVideoResponse>({ data: [] })
  useEffect(() => {
    getVideos(setVideos)
  }, [setVideos])
  const videoOptions = createVideoOptions(videos.data)

  const categories = storeCache.categories
  const tagOptions = createTagOptions(storeCache.tags)
  const partTagOptions = createTagOptions(storeCache.partTags)

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    selectImage<ICourse>(e, setFile, setCourse, course)
  }
  const create = (): Promise<void> =>
    createCourse(props, file, course, storeCache)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="courseTitleForm">コースタイトル</Label>
            <Input
              id="courseTitleForm"
              ref={courseRef.current.title}
              onChange={(e) => setCourse({ ...course, title: e.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="courseThumbnailForm">サムネイル</Label>
            <CustomInput
              id="courseThumbnailForm"
              ref={courseRef.current.image}
              onChange={(e) => onChangeImage(e)}
              type="file"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="courseCategoryForm">カテゴリ</Label>
            <Input
              id="courseCategoryForm"
              ref={courseRef.current.category_id}
              onChange={(e) =>
                setCourse({ ...course, category_id: e.target.value })
              }
              type="select"
            >
              <option />
              {categories.map((category: ICategory, key: number) => {
                return (
                  <option key={key} value={category.id}>
                    {category.name}
                  </option>
                )
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="courseTagsForm">タグ</Label>
            <Select
              id="courseTagsForm"
              onChange={(e) => {
                setCourse({
                  ...course,
                  tag_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }}
              options={tagOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="coursePartTagsForm">部位</Label>
            <Select
              id="coursePartTagsForm"
              onChange={(e) => {
                setCourse({
                  ...course,
                  body_part_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }}
              options={partTagOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="courseVideosForm">動画タイトル</Label>
            <Select
              id="courseVideosForm"
              onChange={(e) => {
                setCourse({
                  ...course,
                  video_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }}
              options={videoOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="courseOverviewForm">概要</Label>
            <Input
              id="courseOverviewForm"
              ref={courseRef.current.overview}
              onChange={(e) =>
                setCourse({ ...course, overview: e.target.value })
              }
              type="textarea"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={create} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
