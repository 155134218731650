import { storage } from 'common/firebase'
import { ICourse } from 'common/interfaces/course'

const SubDirName = 'courses'

/**
 * courses image upload
 */
export const upload = async (
  course: ICourse,
  file: File | undefined
): Promise<string> => {
  let fullPath: string = ''
  try {
    if (file) {
      const uploadTask = await storage
        .ref()
        .child(SubDirName)
        .child(course.id)
        .put(file)

      fullPath = await uploadTask.ref.getDownloadURL()
    }
  } catch (error) {
    console.log(error)
  }

  return fullPath
}

/**
 * delete specified courses image file
 */
export const removeImageFile = async (course: ICourse): Promise<void> => {
  try {
    await storage.ref().child(SubDirName).child(course.id).delete()
  } catch (error) {
    console.log(error)
  }
}
