import React, { useRef, useState } from 'react'
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  CardText,
} from 'reactstrap'
import PinkButton from 'components/atoms/Button/Block/PinkButton'
import { IResetRequest } from 'common/interfaces/password/reset_request'
import { SendPasswordResetEmail } from 'services/password/reset_request'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const InitResetRequest: IResetRequest = {
    email: null,
  }
  const ResetRequestElem = useRef<IResetRequest>(InitResetRequest)
  const [ResetRequestState, SetResetRequest] = useState<IResetRequest>(
    InitResetRequest
  )
  const SendEmail = (): Promise<void> =>
    SendPasswordResetEmail(ResetRequestState)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-3">
                  パスワードリセット
                </div>
                <div className="text-center mb-5">
                  <CardText>
                    登録に使用したメールアドレスを入力してください。
                    <br />
                    パスワードをリセットするためのリンクを記載したメールをお送りいたします。
                  </CardText>
                </div>

                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText />
                      </InputGroupAddon>
                      <Input
                        ref={ResetRequestElem.current.email}
                        onChange={(e) =>
                          SetResetRequest({
                            ...ResetRequestElem,
                            email: e.target.value,
                          })
                        }
                        placeholder="メールアドレス"
                        type="email"
                        autoComplete="new-email"
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <PinkButton
                      onClick={SendEmail}
                      className="my-4"
                      content="送信"
                    />
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
