import { IViewHistory } from 'common/interfaces/user'
import { monthUnixtimes, todayUnixtimes, weekUnixtimes } from 'common/times'

interface IMyScore {
  minutes: number
  kcal: number
}

interface IMyScores {
  month: IMyScore
  week: IMyScore
  today: IMyScore
}

/**
 * 視聴履歴から月、週、日ごとのマイスコアを算出
 * @param viewHistories `IViewHistory` array
 */
export const calc3typeMyScores = (viewHistories: IViewHistory[]): IMyScores => {
  const monthTimes = monthUnixtimes()
  const weekTimes = weekUnixtimes()
  const todayTimes = todayUnixtimes()
  const data: IMyScores = {
    month: {
      minutes: 0,
      kcal: 0,
    },
    week: {
      minutes: 0,
      kcal: 0,
    },
    today: {
      minutes: 0,
      kcal: 0,
    },
  }

  viewHistories.forEach((vh) => {
    if (monthTimes[0] <= vh.timestamp && vh.timestamp <= monthTimes[1]) {
      data.month.minutes += vh.play_time
      data.month.kcal += vh.kcal
    }
    if (weekTimes[0] <= vh.timestamp && vh.timestamp <= weekTimes[1]) {
      data.week.minutes += vh.play_time
      data.week.kcal += vh.kcal
    }
    if (todayTimes[0] <= vh.timestamp && vh.timestamp <= todayTimes[1]) {
      data.today.minutes += vh.play_time
      data.today.kcal += vh.kcal
    }
  })

  return {
    month: {
      minutes: data.month.minutes,
      kcal: Math.ceil(data.month.kcal),
    },
    week: {
      minutes: data.week.minutes,
      kcal: Math.ceil(data.week.kcal),
    },
    today: {
      minutes: data.today.minutes,
      kcal: Math.ceil(data.today.kcal),
    },
  }
}
