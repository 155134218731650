import { Ilogin } from 'common/interfaces/login'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from 'reactstrap'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const initLogin: Ilogin = {
    email: null,
    password: null,
    permission: 10,
  }
  const { loginUser } = useContext(AuthContext)
  const loginElem = useRef<Ilogin>(initLogin)
  const [loginState, setLogin] = useState<Ilogin>(initLogin)
  const login = (): void => loginUser(props, loginState)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">管理システム</div>
                <Form role="form">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText />
                      </InputGroupAddon>
                      <Input
                        ref={loginElem.current.email}
                        onChange={(e) =>
                          setLogin({ ...loginState, email: e.target.value })
                        }
                        placeholder="ログインID"
                        type="email"
                        autoComplete="new-email"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText />
                      </InputGroupAddon>
                      <Input
                        ref={loginElem.current.password}
                        onChange={(e) =>
                          setLogin({ ...loginState, password: e.target.value })
                        }
                        placeholder="パスワード"
                        type="password"
                        autoComplete="new-password"
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      onClick={login}
                      className="my-4"
                      color="primary"
                      type="button"
                    >
                      ログイン
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
