import React from 'react'
import { Link } from 'react-router-dom'
import './genrecard.scss'

interface IGenreCard {
  className?: string
  name: string
  link: string
  image: string | null
}

const GenreCard: React.FC<IGenreCard> = (props: IGenreCard) => {
  return (
    <div className={(props.className ?? '') + ' genre-card'}>
      <Link className="genre-link" to={props.link}>
        <div className="genre">
          {props.image ? (
            <img src={props.image} alt={props.name} />
          ) : (
            <div className="box" />
          )}
          <h3>{props.name}</h3>
        </div>
      </Link>
    </div>
  )
}

export default GenreCard
