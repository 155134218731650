import dayjs from 'dayjs'

/**
 * @returns now unix time
 */
export const nowUnixtime = (): number => dayjs().unix()

/**
 * ex. today is '2021-03-15'
 * @returns month [ 2021-03-01 00:00:00 unixtime, 2021-03-31 23:59:59]
 */
export const monthUnixtimes = (): number[] => {
  const startUnixtime = dayjs().startOf('month').unix()
  const endUnixtime = dayjs().endOf('month').unix()
  return [startUnixtime, endUnixtime]
}

/**
 * start of monday
 *
 * ex. today is '2021-03-31'
 * @returns week [ 2021-03-29 00:00:00 unixtime, 2021-04-04 23:59:59 ]
 */
export const weekUnixtimes = (): number[] => {
  const startUnixtime = dayjs().startOf('week').add(1, 'day').unix()
  const endUnixtime = dayjs().endOf('week').add(1, 'day').unix()
  return [startUnixtime, endUnixtime]
}

/**
 * @returns today [ 00:00:00 unixtime, 23:59:59 unixtime ]
 */
export const todayUnixtimes = (): number[] => {
  const startUnixtime = dayjs().startOf('date').unix()
  const endUnixtime = dayjs().endOf('date').unix()
  return [startUnixtime, endUnixtime]
}

/**
 * is today's time?
 * @param unixtime judge target unixtime
 */
export const isInTodayTime = (unixtime: number): boolean => {
  const todayTimes = todayUnixtimes()
  return todayTimes[0] <= unixtime && unixtime <= todayTimes[1]
}

/**
 * to date format
 * @param unixtime unixtime
 * @returns YYYY年M月D日
 */
export const dateFormat = (unixtime: number): string => {
  return dayjs.unix(unixtime).format('YYYY年M月D日')
}

export const datetimeFormat = (unixtime: number): string => {
  return dayjs.unix(unixtime).format('YYYY年M月D日 HH時mm分')
}
