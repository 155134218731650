import React, { useRef, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { SendAdministrator } from 'services/admin/administrator_create'
import { v4 as uuidv4 } from 'uuid'

interface IFormBodyProps {
  props: any
}

interface IFormAdministrator {
  id: string
  name: string | null
  email: string | null
  password: string | null
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const initAdministrator: IFormAdministrator = {
    id: uuidv4(),
    name: null,
    email: null,
    password: null,
  }
  const AdministratorElem = useRef<IFormAdministrator>(initAdministrator)
  const [Email, SetEmail] = useState<String>('')

  const Send = (): Promise<void> => SendAdministrator(props, Email)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="categoryNameForm">メールアドレス</Label>
            <Input
              id="categoryNameForm"
              ref={AdministratorElem.current.email}
              onChange={(e) => SetEmail(e.target.value)}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={Send} className="my-4" color="primary" type="button">
          送信
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
