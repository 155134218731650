import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ITag } from 'common/interfaces/tag'
import { reloadCachedTags } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_tag'
import { removeImage, update } from 'repositories/store/firebase_tag'
import { alertService } from 'services/alert'

/**
 * タグレコード更新処理APIを起動
 */
export const updateTag = async (
  props: any,
  file: File | undefined,
  tag: ITag,
  storeCache: IStoreCache
): Promise<void> => {
  if (!tag.name) {
    alertService.show(false, 'タグ名を入力してください')
    return
  }

  try {
    if (file) {
      tag.image = await upload(tag, file)
    }
    await update(tag)

    await reloadCachedTags(storeCache)
    props.history.push(Routes.AdminTag)
  } catch (error) {
    console.log(error)
  }
}

/**
 * 画像を削除する(ファイル削除 & DBへの更新)
 */
export const deleteImage = async (
  props: any,
  tag: ITag,
  storeCache: IStoreCache
): Promise<void> => {
  try {
    await removeImageFile(tag)
    await removeImage(tag)

    await reloadCachedTags(storeCache)
    props.history.push(Routes.AdminTag)
  } catch (error) {
    console.log(error)
  }
}
