import Fotter from 'components/molecules/Fotter'
import FormBody from 'components/organisms/PasswordResetRequest/FormBody'
import React from 'react'

interface IPasswordResetRequestProps {
  props: any
}

export const PasswordResetRequest: React.FC<IPasswordResetRequestProps> = (
  props: any
) => {
  return (
    <>
      <FormBody {...props} />
      <Fotter />
    </>
  )
}

export default PasswordResetRequest
