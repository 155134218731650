import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import './categorybadge.scss'

interface ICategoryBadge {
  className?: string
  name: string
  linkPath: string
}

const CategoryBadge: React.FC<ICategoryBadge> = (props: ICategoryBadge) => {
  return (
    <Link className="category-badge" to={props.linkPath}>
      <Badge
        className={(props.className ?? '') + ' badge'}
        color="primary"
        pill
      >
        {props.name}
      </Badge>
    </Link>
  )
}

export default CategoryBadge
