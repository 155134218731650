import { IStoreCache } from './interfaces/auth_provider'
import { ICategory } from './interfaces/category'
import { IPartTag } from './interfaces/part_tag'
import { ITag } from './interfaces/tag'

/*
 * Category
 */
export const findCachedCategory = (
  storeCache: IStoreCache,
  findId: string
): ICategory | null => {
  const categories = storeCache.categories
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].id === findId) {
      return categories[i]
    }
  }
  return null
}

/*
 * PartTag
 */
export const findCachedPartTag = (
  storeCache: IStoreCache,
  findId: string
): IPartTag | null => {
  const partTags = storeCache.partTags
  for (let i = 0; i < partTags.length; i++) {
    if (partTags[i].id === findId) {
      return partTags[i]
    }
  }
  return null
}

export const findCachedPartTags = (
  storeCache: IStoreCache,
  findIds: string[]
): (IPartTag | null)[] => {
  const result: (IPartTag | null)[] = []
  findIds.forEach((id) => {
    result.push(findCachedPartTag(storeCache, id))
  })
  return result
}

export const findCachedPartTagsNonNull = (
  storeCache: IStoreCache,
  findIds: string[]
): IPartTag[] => {
  return findCachedPartTags(storeCache, findIds).filter(
    (pt): pt is IPartTag => pt !== null
  )
}

/*
 * Tag
 */
export const findCachedTag = (
  storeCache: IStoreCache,
  findId: string
): ITag | null => {
  const tags = storeCache.tags
  for (let i = 0; i < tags.length; i++) {
    if (tags[i].id === findId) {
      return tags[i]
    }
  }
  return null
}

export const findCachedTags = (
  storeCache: IStoreCache,
  findIds: string[]
): (ITag | null)[] => {
  const result: (ITag | null)[] = []
  findIds.forEach((id) => {
    result.push(findCachedTag(storeCache, id))
  })
  return result
}

export const findCachedTagsNonNull = (
  storeCache: IStoreCache,
  findIds: string[]
): ITag[] => {
  return findCachedTags(storeCache, findIds).filter(
    (t): t is ITag => t !== null
  )
}
