import React, { useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router'
import { Routes } from 'common/enums'
import Admin from 'routes/Admin'
import { BrowserRouter as Router } from 'react-router-dom'
import Register from 'template/Register'
import { createRedirect, UserRoutes } from 'common/route'
import Login from 'template/Login'
import { AuthContext, AuthProvider } from 'providers/AuthProvider'
import User from 'routes/User'
import PlanSelect from 'template/PlanSelect'
import PasswordResetRequest from 'template/PasswordResetRequest'
import Alert from 'components/atoms/Alert/Alert'
import Confirm from 'components/atoms/Alert/Confirm'
import Unsubscribe from 'template/Unsubscribe'
import ChangeInfo from 'template/ChangeInfo'

const App: React.FC = () => {
  const { currentUser } = useContext(AuthContext)
  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <AuthProvider>
        <Router>
          <Switch>
            <Route
              path={Routes.Register}
              component={(props: any) => <Register {...props} />}
            />
            <Route
              path={Routes.Login}
              component={(props: any) => <Login {...props} />}
            />
            <Route
              path={Routes.PasswordResetRequest}
              component={(props: any) => <PasswordResetRequest {...props} />}
            />
            <Route
              path={Routes.PlanSelect}
              component={(props: any) => <PlanSelect {...props} />}
            />
            <Route
              path={Routes.Unsubscribe}
              component={(props: any) => <Unsubscribe {...props} />}
            />
            <Route
              path={Routes.UserChangeInfo}
              component={(props: any) => <ChangeInfo {...props} />}
            />
            <Route
              path={Routes.Admin}
              component={(props: any) => <Admin {...props} />}
            />
            <Route
              exact
              path={UserRoutes}
              component={(props: any) => <User {...props} />}
            />
            {createRedirect(currentUser)}
          </Switch>
        </Router>
      </AuthProvider>
      <Alert />
      <Confirm />
    </>
  )
}

export default App
