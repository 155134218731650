import Vimeo from '@u-wave/react-vimeo'
import {
  findCachedCategory,
  findCachedPartTagsNonNull,
  findCachedTagsNonNull,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IVideo } from 'common/interfaces/video'
import {
  makeCategoryLink,
  makeCourseVideoLink,
  makePartTagLink,
  makeTagLink,
  makeVideoLink,
} from 'common/link'
import {
  calcKcal,
  extractYoutubeVideoId,
  isVimeoVideoUrl,
  isYoutubeVideoUrl,
  nl2br,
} from 'common/utils'
import CategoryBadge from 'components/atoms/Badge/CategoryBadge'
import OverlayCard from 'components/atoms/Card/OverlayCard'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import Fotter from 'components/molecules/Fotter'
import VideoCard from 'components/molecules/User/VideoCard/VideoCard'
import VideoValues from 'components/molecules/User/VideoValues/VideoValues'
import WideVideoCard from 'components/molecules/User/WideVideoCard/WideVideoCard'
import Header from 'components/organisms/User/Header/Header'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import { findVideo } from 'repositories/store/firebase_video'
import { addUserViewHistory } from 'services/user/user'
import { addViewCount, getSameCategory3Videos } from 'services/user/video'
import './videodetail.scss'

const VideoDetail: React.FC = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const userWeight = storeCache.user?.weight

  const courseId = props.match.params.courseId ?? null
  const videoId = props.match.params.videoId
  const { video, nextVideo, sameCategoryVideos } = useVideoData(
    storeCache,
    courseId,
    videoId,
    userWeight
  )
  const commonWorries = createCommonWorry(storeCache)

  useEffect(() => {
    const addViewCountTimer = setTimeout(() => addViewCount(videoId), 10 * 1000)
    return () => clearTimeout(addViewCountTimer)
  }, [videoId])

  if (!video) {
    return <FullLoadingSpinner />
  }

  let videoElement = <></>
  if (isVimeoVideoUrl(video.url)) {
    videoElement = <Vimeo video={video.url!} responsive />
  } else if (isYoutubeVideoUrl(video.url)) {
    const ytId = extractYoutubeVideoId(video.url)
    videoElement = (
      <div className="youtube-video-responsive">
        <iframe
          width={853}
          height={480}
          title={ytId!}
          src={`https://www.youtube.com/embed/${ytId}`}
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    )
  }

  const nextStep =
    courseId !== null ? createNextStep(courseId, nextVideo) : null

  return (
    <div className="user-video-detail">
      <Header />
      <Container>
        {createCategoryBadge(storeCache, video.category_id)}
        <h1 className="video-title">{video.title}</h1>
        <div className="video-container">{videoElement}</div>
        <div className="video-values">
          {createVideoValues(video, userWeight)}
        </div>
        <div className="overview">
          <h1>動画の概要</h1>
          <div className="content">
            <div>{nl2br(video.overview)}</div>
            <div className="tags">{createTags(storeCache, video.tag_ids)}</div>
            <div className="body-part-tags">
              <h3>よく効く部位</h3>
              <div>{createPartTags(storeCache, video.body_part_ids)}</div>
            </div>
          </div>
        </div>
        {nextStep}
        <div className="same-category-videos">
          <h1 className="title">同じジャンルの動画</h1>
          <Row>{createSameCategoryVideos(sameCategoryVideos)}</Row>
        </div>
        <div className="common-worry">
          <h1>よくあるお悩み</h1>
          <Row>{commonWorries}</Row>
        </div>
      </Container>
      <Fotter />
    </div>
  )
}

interface IData {
  video: IVideo | null
  nextVideo: IVideo | null
  sameCategoryVideos: IVideo[]
}

function useVideoData(
  storeCache: IStoreCache,
  courseId: string | null,
  videoId: string,
  userWeight: number | null | undefined
): IData {
  const response: IData = {
    video: null,
    nextVideo: null,
    sameCategoryVideos: [],
  }
  const [data, setData] = useState<IData>(response)
  useEffect(() => {
    let addViewHistoryTimer: NodeJS.Timeout | null = null

    ;(async () => {
      let nextVideo = null
      if (courseId !== null) {
        const thisCourse = storeCache.courses.filter(
          (c) => c.id === courseId
        )[0]
        const thisVideoIndex = thisCourse.video_ids.indexOf(videoId)
        if (thisVideoIndex + 1 < thisCourse.video_ids.length) {
          nextVideo = await findVideo(thisCourse.video_ids[thisVideoIndex + 1])
        }
      }

      const video = await findVideo(videoId)
      let sameCategory3Videos: IVideo[] = []
      if (video !== null && video.category_id !== null) {
        const categoryId =
          findCachedCategory(storeCache, video.category_id)?.id ?? null
        if (categoryId !== null) {
          sameCategory3Videos = await getSameCategory3Videos(
            categoryId,
            videoId
          )
        }
      }

      setData({
        video: video,
        nextVideo: nextVideo,
        sameCategoryVideos: sameCategory3Videos,
      })

      if (video !== null) {
        addViewHistoryTimer = setTimeout(
          () => addUserViewHistory(storeCache, video, userWeight),
          60 * 1000
        )
      }
    })()

    return () => {
      if (addViewHistoryTimer) {
        clearTimeout(addViewHistoryTimer)
      }
    }
  }, [storeCache, setData, courseId, videoId, userWeight])
  return data
}

function createCommonWorry(storeCache: IStoreCache): JSX.Element[] {
  const worries = storeCache.tags.slice(0, 12)
  return worries.slice(0, 8).map((worry) => {
    return (
      <Col key={worry.id} className="overlay-card-container" xs={6} md={3}>
        <Link to={makeTagLink(worry.id)}>
          <OverlayCard
            img={worry.image ?? ''}
            title={worry.name ?? ''}
            smallCard
          />
        </Link>
      </Col>
    )
  })
}

function createCategoryBadge(
  storeCache: IStoreCache,
  categoryId: string | null
): JSX.Element {
  const category = findCachedCategory(storeCache, categoryId ?? '')
  if (category === null) {
    return <></>
  }
  return (
    <CategoryBadge
      className="category-badge"
      name={category.name ?? ''}
      linkPath={makeCategoryLink(category.id)}
    />
  )
}

function createVideoValues(
  video: IVideo | null,
  userWeight: number | null | undefined
): JSX.Element {
  if (video === null) {
    return <></>
  }
  return (
    <VideoValues
      minutes={video.play_time}
      mets={video.mets}
      kcal={calcKcal(userWeight, video)}
    />
  )
}

function createTags(storeCache: IStoreCache, tagIds: string[]): JSX.Element[] {
  return findCachedTagsNonNull(storeCache, tagIds).map((tag) => {
    return (
      <div key={tag.id}>
        <Link to={makeTagLink(tag.id)}>#{tag.name}</Link>
      </div>
    )
  })
}

function createPartTags(
  storeCache: IStoreCache,
  partTagIds: string[]
): JSX.Element[] {
  return findCachedPartTagsNonNull(storeCache, partTagIds).map((partTag) => {
    return (
      <CategoryBadge
        key={partTag.id}
        className="body-part-badge"
        name={partTag.name ?? ''}
        linkPath={makePartTagLink(partTag.id)}
      />
    )
  })
}

function createNextStep(
  courseId: string,
  nextVideo: IVideo | null
): JSX.Element {
  if (nextVideo === null) {
    return <h1 className="next-step-video">コースはすべて完了しました</h1>
  }

  return (
    <div className="next-step-video">
      <h1>Next Step</h1>
      <Link
        to={makeCourseVideoLink(courseId, nextVideo.id)}
        className="next-step-video-link"
      >
        <WideVideoCard video={nextVideo} />
      </Link>
    </div>
  )
}

function createSameCategoryVideos(videos: IVideo[]): JSX.Element[] {
  return videos.map((video) => {
    return (
      <Col key={video.id} xs={12} sm={6} md={4}>
        <Link to={makeVideoLink(video.id)}>
          <VideoCard className="video-card" video={video} />
        </Link>
      </Col>
    )
  })
}

export default VideoDetail
