import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICourse } from 'common/interfaces/course'
import { reloadCachedCourses } from 'providers/AuthProvider'
import { upload } from 'repositories/storage/firebase_course'
import { store } from 'repositories/store/firebase_course'
import { alertService } from 'services/alert'

/**
 * コースレコード作成処理APIを起動
 */
export const createCourse = async (
  props: any,
  file: File | undefined,
  course: ICourse,
  storeCache: IStoreCache
): Promise<void> => {
  if (!course.title) {
    alertService.show(false, 'タイトルを入力してください')
    return
  }

  try {
    course.image = await upload(course, file)
    course.created_at = firebase.firestore.Timestamp.now()
    course.updated_at = firebase.firestore.Timestamp.now()
    await store(course)

    await reloadCachedCourses(storeCache)
    props.history.push(Routes.AdminCourse)
  } catch (error) {
    console.log(error)
  }
}
