import { selectImage } from 'common/image'
import { ICategory } from 'common/interfaces/category'
import { IVideo } from 'common/interfaces/video'
import { createTagOptions } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useRef, useState } from 'react'
import Select from 'react-select'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { createVideo } from 'services/admin/video_create'
import { v4 as uuidv4 } from 'uuid'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const initVideoCreate: IVideo = {
    id: uuidv4(),
    title: null,
    image: null,
    url: null,
    category_id: null,
    tag_ids: [],
    body_part_ids: [],
    overview: null,
    play_time: 0,
    mets: 0,
    status: 1,
    view_count: 0,
    created_at: null,
    updated_at: null,
  }
  const videoElem = useRef<IVideo>(initVideoCreate)
  const [video, setVideo] = useState<IVideo>(initVideoCreate)
  const [file, setFile] = useState<File>()
  const categories = storeCache.categories
  const tags = storeCache.tags
  const partTags = storeCache.partTags

  const tagOptions = createTagOptions(tags)
  const partTagOptions = createTagOptions(partTags)

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void =>
    selectImage<IVideo>(e, setFile, setVideo, video)
  const create = (): Promise<void> => createVideo(props, file, video)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="videoTitleForm">タイトル</Label>
            <Input
              id="videoTitleForm"
              ref={videoElem.current.title}
              onChange={(e) => setVideo({ ...video, title: e.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="videoThumbnailForm">サムネイル</Label>
            <CustomInput
              id="videoThumbnailForm"
              ref={videoElem.current.image}
              onChange={(e) => onChangeImage(e)}
              type="file"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="videoUrlForm">動画URL</Label>
            <Input
              id="videoUrlForm"
              ref={videoElem.current.url}
              onChange={(e) => setVideo({ ...video, url: e.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="videoCategoryForm">カテゴリ</Label>
            <Input
              id="videoCategoryForm"
              ref={videoElem.current.category_id}
              onChange={(e) =>
                setVideo({ ...video, category_id: e.target.value })
              }
              type="select"
            >
              <option></option>
              {categories.map((category: ICategory, key: number) => {
                return (
                  <option key={key} value={category.id}>
                    {category.name}
                  </option>
                )
              })}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="videoTagsForm">タグ</Label>
            <Select
              id="videoTagsForm"
              onChange={(e) =>
                setVideo({
                  ...video,
                  tag_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }
              options={tagOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <FormGroup>
            <Label for="videoPartTagsForm">部位</Label>
            <Select
              id="videoPartTagsForm"
              onChange={(e) =>
                setVideo({
                  ...video,
                  body_part_ids: (e as { value: string; label: string }[]).map(
                    (x) => x.value
                  ),
                })
              }
              options={partTagOptions}
              closeMenuOnSelect={false}
              isMulti
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="videoOverviewForm">概要</Label>
            <Input
              id="videoOverviewForm"
              ref={videoElem.current.overview}
              onChange={(e) => setVideo({ ...video, overview: e.target.value })}
              type="textarea"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="videoPlayTimeForm">運動時間（分）</Label>
            <Input
              id="videoPlayTimeForm"
              ref={() => String(videoElem.current.play_time)}
              onChange={(e) =>
                setVideo({ ...video, play_time: Number(e.target.value) })
              }
              type="number"
              step={1}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="videoMetsForm">MET'S（運動強度）</Label>
            <Input
              id="videoMetsForm"
              ref={() => String(videoElem.current.mets)}
              onChange={(e) =>
                setVideo({ ...video, mets: Number(e.target.value) })
              }
              type="number"
              step={0.1}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={create} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
