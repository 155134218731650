import React from 'react'
import { Button } from 'reactstrap'

// compoenets/organisms/Admin/.　のテーブルで使用

/**
 * テーブルにボタン生成
 */
export function makeCustomOperationButton(
  name: string,
  action: () => void
): JSX.Element {
  return (
    <Button onClick={action} color="secondary" type="button" size="sm" outline>
      {name}
    </Button>
  )
}

/**
 * テーブルの操作（編集、削除）
 */
export function makeOperationButtons<T, O = {}>(
  props: any,
  state: string | O,
  pathname: string,
  remove: (e: T) => void,
  e: T
): JSX.Element {
  const _state: O | object = typeof state === 'string' ? { [state]: e } : state
  return (
    <>
      {makeCustomOperationButton('編集', () =>
        props.history.push({
          pathname: pathname,
          state: _state,
        })
      )}
      {makeCustomOperationButton('削除', () => remove(e))}
    </>
  )
}

/**
 * １列に2つのボタンを生成する
 */
export function makeOperationCustomButtons(
  button1: JSX.Element,
  button2: JSX.Element
): JSX.Element {
  return (
    <>
      {button1}
      {button2}
    </>
  )
}

/**
 *
 */
export function makeThumbnailElement(
  image: string | null,
  alt: string | null
): JSX.Element {
  return image ? (
    <img src={String(image)} alt={String(alt)} width={100} height="auto" />
  ) : (
    <></>
  )
}
