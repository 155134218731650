import { Routes } from 'common/enums'
import firebase from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICorporate } from 'common/interfaces/corporate'
import { reloadCachedCorporates } from 'providers/AuthProvider'
import { store } from 'repositories/store/firebase_corporate'
import { alertService } from 'services/alert'
import { validateForm } from 'services/validation/corporate'
import { v4 as uuidv4 } from 'uuid'

/**
 * 法人レコード作成処理APIを起動
 */
export const createCorporate = async (
  props: any,
  corporate: ICorporate,
  storeCache: IStoreCache
): Promise<void> => {
  if (!validateForm(corporate, storeCache)) {
    return
  }

  try {
    corporate.id = uuidv4()
    corporate.remaining_enabled_count = null
    corporate.created_at = firebase.firestore.Timestamp.now()
    corporate.updated_at = firebase.firestore.Timestamp.now()

    await store(corporate)
    await reloadCachedCorporates(storeCache)

    alertService.show(true, '法人を登録しました')
    props.history.push(Routes.AdminCorporate)
  } catch (error) {
    console.log(error)
    alertService.show(false, '法人の登録に失敗しました')
  }
}
