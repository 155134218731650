import { IUser } from 'common/interfaces/user'
import Fotter from 'components/molecules/Fotter'
import Header from 'components/organisms/User/Header/Header'
import AuthFormRow from 'components/organisms/User/Setting/AuthFormRow'
import FormRow from 'components/organisms/User/Setting/FormRow'
import PlanRow from 'components/organisms/User/Setting/PlanRow'
import SubHeader from 'components/organisms/User/Setting/SubHeader'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Container } from 'reactstrap'

interface ISettingProps {
  props: any
}

const Setting: React.FC<ISettingProps> = (props: any) => {
  const { currentUser, storeCache } = useContext(AuthContext)
  const [User, SetUser] = useState<IUser>(storeCache.user!)

  return (
    <>
      <Header />
      <Container>
        <SubHeader history={props.history} />
        <FormRow storeCache={storeCache} user={User} setUser={SetUser} />
        <AuthFormRow currentUser={currentUser} />
        <PlanRow user={User} />
        <Fotter />
      </Container>
    </>
  )
}

export default Setting
