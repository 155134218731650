import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICourse } from 'common/interfaces/course'
import { reloadCachedCourses } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_course'
import { removeImage, update } from 'repositories/store/firebase_course'
import { alertService } from 'services/alert'

/**
 * コースレコード更新処理APIを起動
 */
export const updateCourse = async (
  props: any,
  file: File | undefined,
  course: ICourse,
  storeCache: IStoreCache
): Promise<void> => {
  if (!course.title) {
    alertService.show(false, 'コースタイトルを入力してください')
    return
  }
  try {
    if (file) {
      course.image = await upload(course, file)
    }
    await update(course)

    await reloadCachedCourses(storeCache)
    props.history.push(Routes.AdminCourse)
  } catch (error) {
    console.log(error)
  }
}

/**
 * 画像を削除する(ファイル削除 & DBへの更新)
 */
export const deleteImage = async (
  props: any,
  course: ICourse,
  storeCache: IStoreCache
): Promise<void> => {
  try {
    await removeImageFile(course)
    await removeImage(course)

    await reloadCachedCourses(storeCache)
    props.history.push(Routes.AdminCourse)
  } catch (error) {
    console.log(error)
  }
}
