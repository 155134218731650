import { Routes } from 'common/enums'
import { ICategory } from 'common/interfaces/category'
import { makeCategoryLink } from 'common/link'
import { sortBySortNumber } from 'common/utils'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SwiperSlide } from 'swiper/react'
import CardSwiper, { CardSwiperType } from '../CardSwiper/CardSwiper'
import SideBySideTitle from '../SideBySideTitle/SideBySideTitle'
import './popularexercises.scss'

const PopularExercises: React.FC = () => {
  const categories = useContext(AuthContext).storeCache.categories
  const sevenCategories = sortBySortNumber(categories).slice(0, 7)
  const exercises = sevenCategories.map((ex) => createExercises(ex))

  return (
    <div className="popular-exercises">
      <SideBySideTitle
        title="人気のエクササイズ"
        subTitle="もっと見る"
        subTitleLink={Routes.UserPopularExercises}
      />
      <CardSwiper
        type={CardSwiperType.POPULAR_EXERCISE}
        className="popular-exercises-container"
      >
        {exercises}
      </CardSwiper>
    </div>
  )
}

function createExercises(exercise: ICategory): JSX.Element {
  const id = exercise.id
  const image = exercise.image ?? ''
  const name = exercise.name ?? ''
  return (
    <SwiperSlide key={id}>
      <Link className="exercises-link" to={makeCategoryLink(id)}>
        <div className="exercises">
          {image ? <img src={image} alt={name} /> : <div className="box" />}
          <h3>{name}</h3>
        </div>
      </Link>
    </SwiperSlide>
  )
}

export default PopularExercises
