import React, { useEffect, useState } from 'react'
import { IUserResponse } from 'common/interfaces/user'
import { convertNULLIntoString } from 'common/utils'
import { ColumnDescription } from 'react-bootstrap-table-next'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'
import { getAdministrators } from 'services/admin/administrator'

interface ITableBody {
  props: any
}

interface ITableUser {
  no: number
  id: string
  email: string
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const response: IUserResponse = {
    data: [],
  }
  const [User, SetUser] = useState<IUserResponse>(response)

  useEffect(() => {
    getAdministrators(SetUser)
  }, [SetUser])

  const columns: ColumnDescription<ITableUser>[] = [
    { dataField: 'no', text: 'No' },
    { dataField: 'id', text: 'id' },
    { dataField: 'email', text: 'メールアドレス' },
  ]

  const data: ITableUser[] = User.data
    .sort((a, b) => a.created_at!.seconds - b.created_at!.seconds)
    .map((user, i) => {
      return {
        no: i + 1,
        id: convertNULLIntoString(user.id),
        email: convertNULLIntoString(user.email),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITableUser>(columns, data, 'id', false)
}

export default TableBody
