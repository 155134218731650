import firebase, { db } from 'common/firebase'
import { ICategory, ICategoryResponse } from 'common/interfaces/category'

const Categories = db.collection('categories')

/**
 * categories all get
 */
export const get = async (): Promise<ICategoryResponse> => {
  const categoryResponse: ICategoryResponse = { data: [] }

  try {
    const categoryDatas = await Categories.get()
    categoryDatas.docs.forEach((category) => {
      const categoryData: ICategory = makeCategory(category)
      categoryResponse.data.push(categoryData)
    })
  } catch (error) {
    console.log(error)
  }

  return categoryResponse
}

function makeCategory(
  category: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): ICategory {
  const data = category.data()
  return {
    id: data.id,
    name: data.name,
    image: data.image,
    sort_num: data.sort_num,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}

/**
 * categories add
 */
export const store = async (category: ICategory): Promise<void> => {
  try {
    await Categories.doc(category.id).set(category)
  } catch (error) {
    console.log(error)
  }
}

/**
 * videos update
 */
export const update = async (category: ICategory): Promise<void> => {
  try {
    category.updated_at = firebase.firestore.Timestamp.now()

    await Categories.doc(category.id).update(category)
  } catch (error) {
    console.log(error)
  }
}

/**
 * categories delete
 */
export const remove = async (category: ICategory): Promise<void> => {
  try {
    await Categories.doc(category.id).delete()
  } catch (error) {
    console.log(error)
  }
}

/**
 * remove image
 */
export const removeImage = async (category: ICategory): Promise<void> => {
  try {
    category.image = null
    await Categories.doc(category.id).update(category)
  } catch (error) {
    console.log(error)
  }
}
