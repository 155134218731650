import { selectImage } from 'common/image'
import { ICategory } from 'common/interfaces/category'
import { convertNULLIntoString } from 'common/utils'
import { trim } from 'lodash'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { createCategory } from 'services/admin/category_create'
import { v4 as uuidv4 } from 'uuid'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const initCategory: ICategory = {
    id: uuidv4(),
    name: null,
    image: null,
    sort_num: null,
    created_at: null,
    updated_at: null,
  }
  const categoryElem = useRef<ICategory>(initCategory)
  const [category, setCategory] = useState<ICategory>(initCategory)
  const [file, setFile] = useState<File>()

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void =>
    selectImage<ICategory>(e, setFile, setCategory, category)
  const onChangeSortNum = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSortNum =
      trim(e.target.value) === '' ? null : Number(e.target.value)
    setCategory({ ...category, sort_num: newSortNum })
  }
  const create = (): Promise<void> =>
    createCategory(props, file, category, storeCache)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="categoryNameForm">カテゴリ名</Label>
            <Input
              id="categoryNameForm"
              ref={categoryElem.current.name}
              onChange={(e) =>
                setCategory({ ...category, name: e.target.value })
              }
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="categoryThumbnailForm">サムネイル</Label>
            <CustomInput
              id="categoryThumbnailForm"
              ref={categoryElem.current.image}
              onChange={(e) => onChangeImage(e)}
              type="file"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label for="categorySortNumForm">ソート番号</Label>
            <Input
              id="categorySortNumForm"
              value={convertNULLIntoString(category.sort_num)}
              onChange={(e) => onChangeSortNum(e)}
              type="number"
              step="1"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={create} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
