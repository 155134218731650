import { Routes } from 'common/enums'
import { IUser } from 'common/interfaces/user'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Row, Col, Container, Button } from 'reactstrap'
import {
  transitionAccount,
  transitionUnsubscribeForm,
} from 'services/unsubscribe'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const user: IUser | null = useContext(AuthContext).storeCache.user
  if (!user) {
    props.history.replace(Routes.Login)
    return null
  }

  const backMyAccount = (): void => transitionAccount(props, user)
  const unsubscribe = (): void => transitionUnsubscribeForm()

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="mb-4">退会手続き</Row>
        <Row className="my-4">
          退会を完了するとサービスがご利用いただけなくなります。
        </Row>
        <Row>
          <Col lg="3" md="3">
            <Button
              onClick={backMyAccount}
              color="info"
              size="lg"
              className="my-4"
              block
            >
              マイアカウントに戻る
            </Button>
          </Col>
          <Col lg="3" md="3">
            <Button
              onClick={unsubscribe}
              color="default"
              size="lg"
              className="my-4"
              outline
              block
            >
              退会する
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
