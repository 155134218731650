import React from 'react'

const strokeColors = {
  minutes: ['#e9ecef', '#e55e59', '#E04A6A', '#E04A6A'],
  kcal: ['#e9ecef', '#b59fce', '#8172B3', '#8172B3'],
}

interface ITimeKcalGraph {
  minutes: number
  kcal: number
  maxMinutes: number
  maxKcal: number
}

const TimeKcalGraph: React.FC<ITimeKcalGraph> = (props: ITimeKcalGraph) => {
  const minutesColors = minutesStrokeColors(props.minutes, props.maxMinutes)
  const minuteDashes = minutesSvgDashes(props.minutes, props.maxMinutes)
  const kcalColors = kcalStrokeColors(props.kcal, props.maxKcal)
  const kcalDashes = kcalSvgDashes(props.kcal, props.maxKcal)

  return (
    <svg viewBox="0 0 200 200">
      <circle cx="100" cy="100" r="90" fill="#00000000" />
      <path
        fill="none"
        strokeLinecap="round"
        strokeWidth="12"
        stroke={minutesColors.back}
        strokeDashoffset={minuteDashes.back.dashOffset}
        strokeDasharray={minuteDashes.back.dashArray}
        d="M100 20 a 35 35 0 0 1 0 115 a 35 35 0 0 1 0 -115"
      />
      <path
        fill="none"
        strokeLinecap="round"
        strokeWidth="12"
        stroke={minutesColors.front}
        strokeDasharray={minuteDashes.front.dashArray}
        d="M100 20 a 35 35 0 0 1 0 115 a 35 35 0 0 1 0 -115"
      />
      <path
        fill="none"
        strokeLinecap="round"
        strokeWidth="12"
        stroke={kcalColors.back}
        strokeDashoffset={kcalDashes.back.dashOffset}
        strokeDasharray={kcalDashes.back.dashArray}
        d="M100 40 a 5 5 0 0 1 0 75 a 5 5 0 0 1 0 -75"
      />
      <path
        fill="none"
        strokeLinecap="round"
        strokeWidth="12"
        stroke={kcalColors.front}
        strokeDasharray={kcalDashes.front.dashArray}
        d="M100 40 a 5 5 0 0 1 0 75 a 5 5 0 0 1 0 -75"
      />
      <text
        x="100"
        y="172"
        textAnchor="middle"
        fontSize="16"
        fontWeight="bold"
        fill={minutesColors.front}
        letterSpacing="1"
      >
        {minutesSvgText(props.minutes)}
      </text>
      <text
        x="104"
        y="198"
        textAnchor="middle"
        fontSize="16"
        fontWeight="bold"
        fill={kcalColors.front}
        letterSpacing="1"
      >
        {kcalSvgText(props.kcal)}
      </text>
    </svg>
  )
}

function minutesStrokeColors(
  minutes: number,
  maxMinutes: number
): {
  back: string
  front: string
} {
  const isJust = Number.isInteger(minutes / maxMinutes)
  let arrayNumber =
    Math.floor(minutes / maxMinutes) % (strokeColors.minutes.length - 1)
  if (isJust && --arrayNumber < 0) {
    arrayNumber = strokeColors.minutes.length - 2
  }
  if (minutes === 0) {
    arrayNumber = 0
  }
  return {
    back: strokeColors.minutes[arrayNumber],
    front: strokeColors.minutes[arrayNumber + 1],
  }
}

function kcalStrokeColors(
  kcal: number,
  maxKcal: number
): {
  back: string
  front: string
} {
  const isJust = Number.isInteger(kcal / maxKcal)
  let arrayNumber = Math.floor(kcal / maxKcal) % (strokeColors.kcal.length - 1)
  if (isJust && --arrayNumber < 0) {
    arrayNumber = strokeColors.kcal.length - 2
  }
  if (kcal === 0) {
    arrayNumber = 0
  }
  return {
    back: strokeColors.kcal[arrayNumber],
    front: strokeColors.kcal[arrayNumber + 1],
  }
}

function minutesSvgDashes(minutes: number, maxMinutes: number) {
  const minutesPercentage = (() => {
    const per = (minutes % maxMinutes) / maxMinutes
    return per === 0 && minutes !== 0 ? 1 : per
  })()
  const minuteDashArray: number[] = [
    502.4 * minutesPercentage,
    502.4 - 502.4 * minutesPercentage,
  ]
  return {
    front: {
      dashArray: minuteDashArray.join(','),
    },
    back: {
      dashOffset: -minuteDashArray[0],
      dashArray: minuteDashArray.reverse().join(','),
    },
  }
}

function kcalSvgDashes(kcal: number, maxKcal: number) {
  const kcalPercentage = (() => {
    const per = (kcal % maxKcal) / maxKcal
    return per === 0 && kcal !== 0 ? 1 : per
  })()
  const kcalDashArray: number[] = [
    439.6 * kcalPercentage,
    439.6 - 439.6 * kcalPercentage,
  ]
  return {
    front: {
      dashArray: kcalDashArray.join(','),
    },
    back: {
      dashOffset: -kcalDashArray[0],
      dashArray: kcalDashArray.reverse().join(','),
    },
  }
}

function minutesSvgText(minutes: number): JSX.Element {
  const minArray: number[] = [Math.floor(minutes / 60), minutes % 60]
  return (
    <>
      {minArray[0]}
      <tspan fontSize="10">h</tspan>
      {String(minArray[1]).padStart(2, '0')}
      <tspan fontSize="10">min</tspan>
    </>
  )
}

function kcalSvgText(kcal: number): JSX.Element {
  return (
    <>
      {String(kcal).padStart(4, '0')}
      <tspan fontSize="10">kcal</tspan>
    </>
  )
}

export default TimeKcalGraph
