import { Routes } from 'common/enums'
import firebase, { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import {
  IAuthEmailForm,
  IAuthPasswordForm,
} from 'common/interfaces/setting/auth_form'
import { IUser } from 'common/interfaces/user'
import { reloadCachedUser } from 'providers/AuthProvider'
import { findByID, update } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { modalService } from 'services/modal'

/**
 * Firebaseの認証を解除する
 */
export const signOut = (history: any) => {
  modalService.show('ログアウトしますがよろしいですか？', async () => {
    try {
      await auth.signOut()
      history.push(Routes.Login)
      alertService.show(true, 'ログアウトしました')
    } catch (error) {
      console.log(error)
      alertService.show(false, 'ログアウトできませんでした')
    }
  })
}

/**
 * usersの特定のドキュメントを更新する
 */
export const userUpdate = async (
  storeCache: IStoreCache,
  user: IUser,
  isCounseling: boolean
): Promise<void> => {
  try {
    const oldUser: IUser = await findByID(user.id)
    let message: string = ''
    if (isCounseling) {
      // カウンセリング
      oldUser.purpose = user.purpose
      oldUser.proportion = user.proportion
      oldUser.symptoms = user.symptoms
      oldUser.sleeping_time = user.sleeping_time
      oldUser.walking_time = user.walking_time

      message = 'カウンセリング情報を更新しました'
    } else {
      // プロフィール情報
      oldUser.name = user.name
      oldUser.prefecture = user.prefecture
      oldUser.birthdate = user.birthdate
      oldUser.gender = user.gender
      oldUser.height = user.height
      oldUser.weight = user.weight
      oldUser.body_fat_percentage = user.body_fat_percentage

      message = 'プロフィール情報を更新しました'
    }

    await update(oldUser)
    await reloadCachedUser(storeCache)

    alertService.show(true, message)
  } catch (error) {
    console.log(error)
    alertService.show(false, '更新に失敗しました')
  }
}

/**
 * FireAuthのメールアドレスを更新する
 */
export const emailUpdateAndSignIn = (
  currentUser: firebase.User | null,
  authForm: IAuthEmailForm
) => {
  if (!authForm.email || !authForm.password) {
    alertService.show(
      false,
      '新しいメールアドレスとパスワードを入力してください'
    )
    return
  }

  modalService.show('メールアドレスを変更してよろしいですか？', async () => {
    try {
      await auth.signInWithEmailAndPassword(
        String(currentUser?.email),
        authForm.password
      )
      await currentUser?.updateEmail(authForm.email)

      if (currentUser !== null) {
        const user = await findByID(currentUser.uid)
        user.email = authForm.email
        await update(user)
      }

      alertService.show(true, 'メールアドレスを更新しました')
    } catch (error) {
      let message: string = ''
      switch (error.code) {
        case 'auth/invalid-email':
          message = 'メールアドレスの形式が正しくありません'
          break
        case 'auth/requires-recent-login':
          message =
            'この操作は機密性が高く、最近の認証が必要です。このリクエストを再試行する前に、再度ログインしてください。'
          break
        case 'auth/email-already-in-use':
          message = 'メールアドレスはすでに別のアカウントで使用されています'
          break
        case 'auth/wrong-password':
          message =
            'パスワードが無効であるか、ユーザーがパスワードを持っていません'
          break
        default:
          message = error.code
      }
      console.log(error)
      alertService.show(false, message)
    }
  })
}

/**
 * FireAuthのパスワードを更新する
 */
export const passwordUpdateAndSignIn = (
  currentUser: firebase.User | null,
  authForm: IAuthPasswordForm,
  setData: React.Dispatch<React.SetStateAction<IAuthPasswordForm>>
) => {
  let message: string = ''
  if (
    !authForm.password ||
    !authForm.newPassword ||
    !authForm.newPasswordConfirm
  ) {
    message = 'パスワードを入力してください'
  }
  if (authForm.newPassword !== authForm.newPasswordConfirm) {
    message = '新しいパスワードと確認用のパスワードが違います'
  }
  if (message) {
    alertService.show(false, message)
    return
  }

  modalService.show('パスワードを変更してよろしいですか？', async () => {
    try {
      await auth.signInWithEmailAndPassword(
        String(currentUser?.email),
        authForm.password
      )
      await currentUser?.updatePassword(authForm.newPassword)

      alertService.show(true, 'パスワードを変更しました')

      setData({
        ...authForm,
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      })
    } catch (error) {
      let message: string = ''
      switch (error.code) {
        case 'auth/requires-recent-login':
          message =
            'この操作は機密性が高く、最近の認証が必要です。このリクエストを再試行する前に、再度ログインしてください。'
          break
        case 'auth/wrong-password':
          message =
            'パスワードが無効であるか、ユーザーがパスワードを持っていません'
          break
        case 'auth/weak-password':
          message = 'パスワードは6文字以上である必要があります'
          break
        default:
          message = error.code
      }
      console.log(error)
      alertService.show(false, message)
    }
  })
}
