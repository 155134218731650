import { CorporateInputType } from 'common/enums'
import { ICorporate } from 'common/interfaces/corporate'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { createCorporate } from 'services/admin/corporate_create'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const [corporate, setCorporate] = useState<ICorporate>({} as ICorporate)

  const onChangeInput = (type: CorporateInputType, e: any) => {
    const val = e.target?.value
    setCorporate({
      ...corporate,
      name: type === CorporateInputType.NAME ? val : corporate.name,
      address: type === CorporateInputType.ADDRESS ? val : corporate.address,
      coupon_code:
        type === CorporateInputType.COUPON_CODE ? val : corporate.coupon_code,
      coupon_enabled_count:
        type === CorporateInputType.COUPON_ENABLED_COUNT
          ? val
          : corporate.coupon_enabled_count,
    })
  }
  const create = (): Promise<void> =>
    createCorporate(props, corporate, storeCache)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="corporateNameForm">法人名</Label>
            <Input
              id="corporateNameForm"
              onChange={(e) => onChangeInput(CorporateInputType.NAME, e)}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="corporateAddressForm">法人住所</Label>
            <Input
              id="corporateAddressForm"
              onChange={(e) => onChangeInput(CorporateInputType.ADDRESS, e)}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="corporateCouponCodeForm">クーポンコード</Label>
            <Input
              id="corporateCouponCodeForm"
              onChange={(e) => onChangeInput(CorporateInputType.COUPON_CODE, e)}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="corporateCouponEnabledCountForm">
              クーポン有効人数
            </Label>
            <Input
              id="corporateCouponEnabledCountForm"
              onChange={(e) =>
                onChangeInput(CorporateInputType.COUPON_ENABLED_COUNT, e)
              }
              type="number"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={create} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
