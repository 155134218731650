import { Routes } from 'common/enums'
import { IVideoResponse } from 'common/interfaces/video'
import { makeVideoLink } from 'common/link'
import CardSwiper, {
  CardSwiperType,
} from 'components/molecules/User/CardSwiper/CardSwiper'
import SideBySideTitle from 'components/molecules/User/SideBySideTitle/SideBySideTitle'
import VideoCard from 'components/molecules/User/VideoCard/VideoCard'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'reactstrap'
import { getNewer7Videos, getPopular7Videos } from 'services/user/video'
import { SwiperSlide } from 'swiper/react'
import './sidescrollvideolist.scss'

export enum SideScrollVideoListType {
  NEW_VIDEOS,
  POPULAR_VIDEOS,
}

interface ISideScrollVideoList {
  className?: string
  sideScrollVideoListType: SideScrollVideoListType
}

const SideScrollVideoList: React.FC<ISideScrollVideoList> = (
  props: ISideScrollVideoList
) => {
  const showAllLink = createShowAllLink(props.sideScrollVideoListType)

  return (
    <div className={(props.className ?? '') + ' side-scroll-video-list'}>
      <Container>
        <SideBySideTitle
          title={createTitle(props.sideScrollVideoListType)}
          subTitle="すべて表示"
          subTitleLink={showAllLink}
        />
        <CardSwiper
          type={CardSwiperType.VIDEO}
          showAllLink={showAllLink}
          className="video-container"
        >
          {useVideos(props.sideScrollVideoListType)}
        </CardSwiper>
      </Container>
    </div>
  )
}

function createTitle(sideScrollVideoListType: SideScrollVideoListType): string {
  switch (sideScrollVideoListType) {
    case SideScrollVideoListType.NEW_VIDEOS:
      return '新着の動画'
    case SideScrollVideoListType.POPULAR_VIDEOS:
      return '人気の動画'
  }
}

function createShowAllLink(
  sideScrollVideoListType: SideScrollVideoListType
): string {
  switch (sideScrollVideoListType) {
    case SideScrollVideoListType.NEW_VIDEOS:
      return Routes.UserNewerVideos
    case SideScrollVideoListType.POPULAR_VIDEOS:
      return Routes.UserPopularVideos
  }
}

function useVideos(
  sideScrollVideoListType: SideScrollVideoListType
): JSX.Element[] {
  const response: IVideoResponse = {
    data: [],
  }
  const [videos, setVideos] = useState<IVideoResponse>(response)
  useEffect(() => {
    switch (sideScrollVideoListType) {
      case SideScrollVideoListType.NEW_VIDEOS:
        getNewer7Videos(setVideos)
        break
      case SideScrollVideoListType.POPULAR_VIDEOS:
        getPopular7Videos(setVideos)
        break
    }
  }, [sideScrollVideoListType, setVideos])
  return videos.data.map((video) => {
    return (
      <SwiperSlide key={video.id}>
        <Link to={makeVideoLink(video.id)}>
          <VideoCard className="video-card" video={video} />
        </Link>
      </SwiperSlide>
    )
  })
}

export default SideScrollVideoList
