import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { reloadCachedCategories } from 'providers/AuthProvider'
import { removeImageFile, upload } from 'repositories/storage/firebase_category'
import { removeImage, update } from 'repositories/store/firebase_category'
import { alertService } from 'services/alert'

/**
 * カテゴリレコード更新処理APIを起動
 */
export const updateCategory = async (
  props: any,
  file: File | undefined,
  category: ICategory,
  storeCache: IStoreCache
): Promise<void> => {
  if (!category.name) {
    alertService.show(false, 'カテゴリ名を入力してください')
    return
  }
  try {
    if (file) {
      category.image = await upload(category, file)
    }
    await update(category)

    await reloadCachedCategories(storeCache)
    props.history.push(Routes.AdminCategory)
  } catch (error) {
    console.log(error)
  }
}

/**
 * 画像を削除する(ファイル削除 & DBへの更新)
 */
export const deleteImage = async (
  props: any,
  category: ICategory,
  storeCache: IStoreCache
): Promise<void> => {
  try {
    await removeImageFile(category)
    await removeImage(category)

    await reloadCachedCategories(storeCache)
    props.history.push(Routes.AdminCategory)
  } catch (error) {
    console.log(error)
  }
}
