import React from 'react'
import { Routes } from 'common/enums'
import { Container, Navbar, NavbarBrand } from 'reactstrap'
import './header.scss'
import { Link } from 'react-router-dom'

const Header: React.FC = () => {
  return (
    <Navbar
      className="header user_header navbar-horizontal navbar-dark"
      expand="lg"
    >
      <Container>
        <NavbarBrand href={Routes.Root}>
          <img src={require('assets/images/logo.png')} alt="masaru" />
        </NavbarBrand>
        <Link to={Routes.Setting}>
          <i className="header-avatar fa fa-user-circle" />
        </Link>
      </Container>
    </Navbar>
  )
}

export default Header
