import Fotter from 'components/molecules/Fotter'
import ChooseCategory, {
  ChooseCategoryType,
} from 'components/molecules/User/ChooseCategory/ChooseCategory'
import Header from 'components/organisms/User/Header/Header'
import VideoList from 'components/organisms/User/VideoList/VideoList'
import React from 'react'
import { Container } from 'reactstrap'
import './videos.scss'

const Videos: React.FC = (props: any) => {
  return (
    <div className="user-videos">
      <Header />
      <Container className="categories">
        <ChooseCategory categoryType={ChooseCategoryType.EXERCISES} />
        <ChooseCategory categoryType={ChooseCategoryType.TIMES} />
        <ChooseCategory categoryType={ChooseCategoryType.PARTS} />
      </Container>
      <hr />
      <Container className="video-list-container">
        <VideoList {...props} />
      </Container>
      <Fotter />
    </div>
  )
}

export default Videos
