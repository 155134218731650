import { Routes } from 'common/enums'
import {
  findCachedCategory,
  findCachedPartTags,
  findCachedTags,
} from 'common/find_store_cache'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IVideo, IVideoResponse } from 'common/interfaces/video'
import {
  convertStatusIntoString,
  nl2br,
  numberPlayTime2string,
} from 'common/utils'
import {
  makeOperationButtons,
  makeThumbnailElement,
} from 'components/atoms/Table/ElementsOnTable'
import { CreateNewTableWithPagination } from 'components/atoms/Table/Pagination'
import 'components/organisms/Admin/admin-table-style.scss'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { ColumnDescription } from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { getVideos, removeVideo } from 'services/admin/video'
import { textFilter } from 'react-bootstrap-table2-filter'

interface ITableBody {
  props: any
}

interface ITableVideo {
  no: number
  id: string
  title: string | null
  thumbnail: string | null
  url: string | null
  category: string
  tags: string
  partTags: string
  overview: JSX.Element | string | null
  playTime: number
  mets: number
  status: string
  operation: JSX.Element
}

const TableBody: React.FC<ITableBody> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const response: IVideoResponse = {
    data: [],
  }
  const [video, setVideo] = useState<IVideoResponse>(response)
  const remove = (video: IVideo) => removeVideo(video, setVideo)

  useEffect(() => {
    getVideos(setVideo)
  }, [setVideo])

  const columns: ColumnDescription<ITableVideo>[] = [
    { dataField: 'no', text: 'No' },
    { dataField: 'id', text: 'id', hidden: true },
    {
      dataField: 'title',
      text: '動画タイトル',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'thumbnail',
      text: 'サムネイル',
      formatter: (thumbnail, video) =>
        makeThumbnailElement(thumbnail, video.title),
    },
    {
      dataField: 'url',
      text: '動画URL',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'category',
      text: 'カテゴリ',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'tags',
      text: 'タグ',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'partTags',
      text: '部位',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'overview',
      text: '概要',
      formatter: (overview) => <>{nl2br(overview)}</>,
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'playTime',
      text: '運動時間',
      formatter: (playTime) => numberPlayTime2string(playTime),
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'mets',
      text: "MET'S（運動強度）",
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'operation',
      text: '操作',
    },
  ]

  const data: ITableVideo[] = video.data
    .sort((a, b) => a.created_at!.seconds - b.created_at!.seconds)
    .map((v, i) => {
      return {
        no: i + 1,
        id: v.id,
        title: v.title,
        thumbnail: v.image,
        url: v.url,
        category:
          findCachedCategory(storeCache, v.category_id ?? '')?.name ?? '',
        tags: makeJoinedTagNames(storeCache, v.tag_ids),
        partTags: makeJoinedPartTagNames(storeCache, v.body_part_ids),
        overview: v.overview,
        playTime: v.play_time,
        mets: v.mets,
        status: convertStatusIntoString(v.status),
        operation: makeOperationButtons<IVideo>(
          props,
          'video',
          Routes.AdminVideoEdit,
          remove,
          v
        ),
      }
    })
    .reverse()

  return CreateNewTableWithPagination<ITableVideo>(columns, data, 'id', true)
}

function makeJoinedTagNames(storeCache: IStoreCache, tagIds: string[]): string {
  return findCachedTags(storeCache, tagIds)
    .map((tag) => tag?.name ?? '')
    .join()
}

function makeJoinedPartTagNames(
  storeCache: IStoreCache,
  partTagIds: string[]
): string {
  return findCachedPartTags(storeCache, partTagIds)
    .map((partTag) => partTag?.name ?? '')
    .join()
}

export default TableBody
