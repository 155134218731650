import FormBody from 'components/organisms/Admin/Register/FormBody'
import React from 'react'

interface IRegisterProps {
  props: any
}

const Register: React.FC<IRegisterProps> = (props: any) => {
  return <FormBody {...props} />
}

export default Register
