import React from 'react'
import { Card, CardImg, CardImgOverlay } from 'reactstrap'
import './overlaycard.scss'

interface IOverlayCard {
  className?: string
  title: string
  img?: string
  smallCard?: boolean
}

const OverlayCard: React.FC<IOverlayCard> = (props: IOverlayCard) => {
  if (props.smallCard) {
    return smallCard(props)
  } else {
    return overlayCard(props)
  }
}

function overlayCard(props: IOverlayCard): JSX.Element {
  const cardImage = props.img ? (
    <CardImg className="image" src={props.img} />
  ) : (
    <div className="no-image" />
  )

  return (
    <Card inverse className={(props.className ?? '') + ' overlay-card'}>
      {cardImage}
      <CardImgOverlay className="content">
        <div className="title">{props.title}</div>
      </CardImgOverlay>
    </Card>
  )
}

function smallCard(props: IOverlayCard): JSX.Element {
  return (
    <Card
      inverse
      className={(props.className ?? '') + ' overlay-card small-card'}
    >
      <div className="no-image" />
      <CardImgOverlay className="content">
        <div className="title">{props.title}</div>
      </CardImgOverlay>
    </Card>
  )
}

export default OverlayCard
