import { IRegister } from 'common/interfaces/register'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
} from 'reactstrap'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const Url = new URL(window.location.href)
  const initRegister: IRegister = {
    email: Url.searchParams.get('email'),
    password: null,
    permission: 10,
    admin: true,
  }
  const { registerUser } = useContext(AuthContext)
  const RegisterElem = useRef<IRegister>(initRegister)
  const [RegisterState, SetRegister] = useState<IRegister>(initRegister)
  const Register = (): void => registerUser(props, RegisterState)

  return (
    <div className="main-content">
      <div className="header py-7 py-lg-8" />

      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">管理システム</div>
                <Form role="form">
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText />
                      </InputGroupAddon>
                      <Input
                        ref={RegisterElem.current.password}
                        onChange={(e) =>
                          SetRegister({
                            ...RegisterState,
                            password: e.target.value,
                          })
                        }
                        placeholder="パスワード"
                        type="password"
                        autoComplete="new-password"
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      onClick={Register}
                      className="my-4"
                      color="primary"
                      type="button"
                    >
                      登録
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormBody
