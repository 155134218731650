import { Routes } from 'common/enums'
import { ITag } from 'common/interfaces/tag'
import { makeTagLink } from 'common/link'
import { sortBySortNumber } from 'common/utils'
import OverlayCard from 'components/atoms/Card/OverlayCard'
import SideBySideTitle from 'components/molecules/User/SideBySideTitle/SideBySideTitle'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import './commonworry.scss'

const CommonWorry: React.FC = () => {
  const tags = useContext(AuthContext).storeCache.tags
  const twelveWorries = sortBySortNumber(tags).slice(0, 12)
  const rows = createRows(twelveWorries)

  return (
    <div className="common-worry">
      <SideBySideTitle
        title="よくあるお悩み"
        subTitle="すべて表示"
        subTitleLink={Routes.UserCommonWorries}
      />
      {rows}
    </div>
  )
}

function createRows(worries: ITag[]): JSX.Element[] {
  const result: JSX.Element[] = []
  for (let i = 0; i < 3; i++) {
    result.push(
      <Row key={i} className="worry-cards-container">
        {worries.slice(i * 4, i * 4 + 4).map((worry) => {
          const isSmallCard = i !== 0
          return (
            <Col
              key={worry.id}
              className="overlay-card-container"
              xs={6}
              md={3}
            >
              <Link to={makeTagLink(worry.id)}>
                <OverlayCard
                  className="overlay-card"
                  img={worry.image ?? ''}
                  title={worry.name ?? ''}
                  smallCard={isSmallCard}
                />
              </Link>
            </Col>
          )
        })}
      </Row>
    )
  }
  return result
}

export default CommonWorry
