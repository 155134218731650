import React from 'react'
import H1 from 'components/atoms/Head/H1'

const Index: React.FC = (props) => {
  return (
    <>
      <div className="main-content">
        <H1 title="お知らせ" />
      </div>
    </>
  )
}

export default Index
