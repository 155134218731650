import { findByCorporateId } from 'repositories/store/firebase_user'
import { ICorporate } from './interfaces/corporate'
import { IUser } from './interfaces/user'

/**
 * クーポンで入会した人数を取得する
 */
export const getCountSignedUpByCoupon = async (
  corporate: ICorporate
): Promise<number> => {
  const users: IUser[] = (await findByCorporateId(corporate.id)).data

  return users.length
}

/**
 * 残りの有効人数を算出し取得する
 */
export const getRemainingEnabledCount = async (
  corporate: ICorporate
): Promise<number> => {
  const users: IUser[] = (await findByCorporateId(corporate.id)).data

  return Number(corporate.coupon_enabled_count) - users.length
}
