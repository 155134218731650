import { IStoreCache } from 'common/interfaces/auth_provider'
import { IUser } from 'common/interfaces/user'
import { isAfter } from 'date-fns'

/**
 * 読み込み
 */
export const isLoading = (storeCache: IStoreCache) => {
  return (
    storeCache.user === null ||
    storeCache.categories.length <= 0 ||
    storeCache.tags.length <= 0 ||
    storeCache.partTags.length <= 0
  )
}

/**
 * 認証済み且つ決済済みユーザを確認する
 */
export function IsSettled(user: IUser | null): boolean {
  return (
    user !== null &&
    user.payment !== null &&
    user.payment.length > 0 &&
    (user.payment[user.payment.length - 1].result === '1' ||
      user.payment[0].result === '10' ||
      user.payment[0].result === '999')
  )
}

/**
 * 有効期限内のユーザかを確認する
 */
export function IsInExpirationAt(user: IUser): boolean {
  if (user?.expiration_at === undefined) {
    return false
  }

  if (user?.expiration_at === null) {
    return true
  }
  return isAfter(user.expiration_at.toDate(), new Date())
}
