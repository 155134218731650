import { Routes } from 'common/enums'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { IPartTag } from 'common/interfaces/part_tag'
import { reloadCachedPartTags } from 'providers/AuthProvider'
import { upload, removeImageFile } from 'repositories/storage/firebase_part_tag'
import { update, removeImage } from 'repositories/store/firebase_part_tag'
import { alertService } from 'services/alert'

/**
 * 部位タグレコード更新処理APIを起動
 */
export const updatePartTag = async (
  props: any,
  file: File | undefined,
  partTag: IPartTag,
  storeCache: IStoreCache
): Promise<void> => {
  if (!partTag.name) {
    alertService.show(false, 'タグ名を入力してください')
    return
  }

  try {
    if (file) {
      partTag.image = await upload(partTag, file)
    }
    await update(partTag)

    await reloadCachedPartTags(storeCache)
    props.history.push(Routes.AdminPartTag)
  } catch (error) {
    console.log(error)
  }
}

/**
 * 画像を削除する(ファイル削除 & DBへの更新)
 */
export const deleteImage = async (
  props: any,
  partTag: IPartTag,
  storeCache: IStoreCache
): Promise<void> => {
  try {
    await removeImageFile(partTag)
    await removeImage(partTag)

    await reloadCachedPartTags(storeCache)
    props.history.push(Routes.AdminPartTag)
  } catch (error) {
    console.log(error)
  }
}
