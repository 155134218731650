import { Routes } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import CantLogin, { CantLoginPageType } from 'components/molecules/CantLogin'
import Fotter from 'components/molecules/Fotter'
import FormBody from 'components/organisms/Unsubscribe/FormBody'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { IsInExpirationAt, isLoading, IsSettled } from 'services/auth'

interface IUnsubscribeProps {
  props: any
}

enum ViewType {
  LOGIN,
  LOADING,
  CHANGE_INFO,
  GONE,
  VISIBLE,
}

const Unsubscribe: React.FC<IUnsubscribeProps> = (props: any) => {
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        setViewType(ViewType.LOGIN)
      } else if (isLoading(storeCache)) {
        setViewType(ViewType.LOADING)
      } else if (!IsSettled(storeCache.user)) {
        setViewType(ViewType.LOGIN)
      } else if (!IsInExpirationAt(storeCache.user!)) {
        setViewType(ViewType.CHANGE_INFO)
      } else if (storeCache.user?.admin ?? false) {
        setViewType(ViewType.GONE)
      } else {
        setViewType(ViewType.VISIBLE)
      }
    })
  }, [setViewType, storeCache])

  switch (viewType) {
    case ViewType.LOADING:
      return <FullLoadingSpinner />
    case ViewType.LOGIN:
      props.history.replace(Routes.Login)
      return null
    case ViewType.CHANGE_INFO:
      props.history.replace(Routes.UserChangeInfo)
      return null
    case ViewType.GONE:
      return <CantLogin pageType={CantLoginPageType.MEMBER} />
    case ViewType.VISIBLE:
      break
  }

  return (
    <>
      <FormBody {...props} />
      <Fotter />
    </>
  )
}

export default Unsubscribe
