import {
  INullableVideoResponse,
  IVideo,
  IVideoResponse,
} from 'common/interfaces/video'
import {
  getInCategory,
  getInPartTag,
  getInTag,
  getNewer,
  getPlayTime,
  getPopular,
  incrementViewCount,
} from 'repositories/store/firebase_video'

/**
 * 新着の動画をすべて取得する
 */
export const getNewerAllVideos = async (
  setData: React.Dispatch<React.SetStateAction<INullableVideoResponse>>
): Promise<void> => setData(await getNewer(0))

/**
 * 新着の動画を7件取得する
 */
export const getNewer7Videos = async (
  setData: React.Dispatch<React.SetStateAction<IVideoResponse>>
): Promise<void> => setData(await getNewer(7))

/**
 * 人気の動画をすべて取得する
 */
export const getPopularAllVideos = async (
  setData: React.Dispatch<React.SetStateAction<INullableVideoResponse>>
): Promise<void> => setData(await getPopular(0))

/**
 * 人気の動画を7件取得する
 */
export const getPopular7Videos = async (
  setData: React.Dispatch<React.SetStateAction<IVideoResponse>>
): Promise<void> => setData(await getPopular(7))

/**
 * カテゴリーの動画を取得
 */
export const getCategoryVideos = async (
  setData: React.Dispatch<React.SetStateAction<INullableVideoResponse>>,
  categoryId: string
): Promise<void> => setData(await getInCategory(categoryId))

/**
 * 動画詳細ページの「同じジャンルの動画」取得
 * @param categoryId category id
 * @param videoId 動画詳細ページで開いている動画ID
 */
export const getSameCategory3Videos = async (
  categoryId: string,
  videoId: string
): Promise<IVideo[]> => {
  return (await getInCategory(categoryId, 4)).data
    .filter((v) => v.id !== videoId)
    .slice(0, 3)
}

/**
 * タグを含んでいる動画を取得
 */
export const getTagVideos = async (
  setData: React.Dispatch<React.SetStateAction<INullableVideoResponse>>,
  tagId: string
): Promise<void> => setData(await getInTag(tagId))

/**
 * 運動時間の動画を取得
 */
export const getPlayTimeVideos = async (
  setData: React.Dispatch<React.SetStateAction<INullableVideoResponse>>,
  minutes: number[]
): Promise<void> => setData(await getPlayTime(minutes))

/**
 * 部位タグを含んでいる動画を取得
 */
export const getPartTagVideos = async (
  setData: React.Dispatch<React.SetStateAction<INullableVideoResponse>>,
  partTagId: string
): Promise<void> => setData(await getInPartTag(partTagId))

/**
 * videoのview_countを+1する
 */
export const addViewCount = async (targetVideoId: string): Promise<void> =>
  incrementViewCount(targetVideoId)
