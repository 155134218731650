import { IUser } from 'common/interfaces/user'
import { convertJoinPlan } from 'common/utils'
import React from 'react'
import { Button, Card, Col, Container, Row } from 'reactstrap'
import { transitionCreditCardChangeForm } from 'services/unsubscribe'

interface IPlanRowProps {
  user: IUser
}

const PlanRow: React.FC<IPlanRowProps> = (props: IPlanRowProps) => {
  const creditCardChange = (): void =>
    transitionCreditCardChangeForm(props.user)

  return (
    <>
      <Row className="display-4 mt-3">
        <Container>ご契約状況</Container>
      </Row>
      <Row className="mt-3">
        <Col lg="6">
          <Card>
            <Container className="pl-4 py-4">
              <Row>
                <Col xs={4}>ご契約中のプラン</Col>
                <Col xs={3}>{convertJoinPlan(props.user)}</Col>
                <Col xs={5}>
                  <Button onClick={creditCardChange} color="info" type="button">
                    クレジットカード変更
                  </Button>
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default PlanRow
