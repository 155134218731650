import { selectImage } from 'common/image'
import { ITag } from 'common/interfaces/tag'
import { convertNULLIntoString } from 'common/utils'
import { trim } from 'lodash'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useRef, useState } from 'react'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { createTag } from 'services/admin/tag_create'
import { v4 as uuidv4 } from 'uuid'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const initTag: ITag = {
    id: uuidv4(),
    name: null,
    image: null,
    sort_num: null,
    created_at: null,
    updated_at: null,
  }
  const tagElem = useRef<ITag>(initTag)
  const [tagState, setTag] = useState<ITag>(initTag)
  const [file, setFile] = useState<File>()

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void =>
    selectImage<ITag>(e, setFile, setTag, tagState)
  const onChangeSortNum = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSortNum =
      trim(e.target.value) === '' ? null : Number(e.target.value)
    setTag({ ...tagState, sort_num: newSortNum })
  }
  const create = (): Promise<void> =>
    createTag(props, file, tagState, storeCache)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="tagNameForm">タグ名</Label>
            <Input
              id="tagNameForm"
              ref={tagElem.current.name}
              onChange={(e) => setTag({ ...tagState, name: e.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="tagThumbnailForm">サムネイル</Label>
            <CustomInput
              id="tagThumbnailForm"
              ref={tagElem.current.image}
              onChange={(e) => onChangeImage(e)}
              type="file"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <FormGroup>
            <Label for="categorySortNumForm">ソート番号</Label>
            <Input
              id="categorySortNumForm"
              value={convertNULLIntoString(tagState.sort_num)}
              onChange={(e) => onChangeSortNum(e)}
              type="number"
              step="1"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={create} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
