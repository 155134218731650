import firebase, { db } from 'common/firebase'
import { IVideo, IVideoResponse } from 'common/interfaces/video'

const Videos = db.collection('videos')

/**
 * videos all get
 */
export const get = async (): Promise<IVideoResponse> => {
  const videoResponse: IVideoResponse = { data: [] }

  try {
    const videoDatas = await Videos.get()
    for (const video of videoDatas.docs) {
      const videoData = makeVideo(video)
      videoResponse.data.push(videoData)
    }
  } catch (error) {
    console.log(error)
  }

  return videoResponse
}

/**
 * find video by id
 */
export const findVideo = async (id: string): Promise<IVideo | null> => {
  try {
    const videoData = await Videos.where('id', '==', id).get()
    if (videoData.docs.length <= 0) {
      return null
    }
    return makeVideo(videoData.docs[0])
  } catch (error) {
    console.log(error)
  }
  return null
}

/**
 * 新しい順のVideoを取得する
 * @param count 取得件数。`0`: all
 */
export const getNewer = async (count: number): Promise<IVideoResponse> => {
  const videoResponse: IVideoResponse = { data: [] }

  try {
    const videoQuery = Videos.orderBy('created_at', 'desc')
    let videoData = null
    if (count === 0) {
      videoData = await videoQuery.get()
    } else {
      videoData = await videoQuery.limit(count).get()
    }
    for (const video of videoData.docs) {
      const data = makeVideo(video)
      videoResponse.data.push(data)
    }
  } catch (error) {
    console.log(error)
  }
  return videoResponse
}

/**
 * 人気順のVideoを取得する
 * @param count 取得件数。`0`: all
 */
export const getPopular = async (count: number): Promise<IVideoResponse> => {
  const videoResponse: IVideoResponse = { data: [] }

  try {
    const videoQuery = Videos.orderBy('view_count', 'desc')
    let videoData = null
    if (count === 0) {
      videoData = await videoQuery.get()
    } else {
      videoData = await videoQuery.limit(count).get()
    }
    for (const video of videoData.docs) {
      const data = makeVideo(video)
      videoResponse.data.push(data)
    }
  } catch (error) {
    console.log(error)
  }
  return videoResponse
}

/**
 * カテゴリーのVideoを取得する
 * @param categoryId category id
 * @param count 取得件数（0: all）
 */
export const getInCategory = async (
  categoryId: string,
  count: number = 0
): Promise<IVideoResponse> => {
  const videoResponse: IVideoResponse = { data: [] }

  try {
    let videoWhere = Videos.where('category_id', '==', categoryId)
    if (count > 0) {
      videoWhere = videoWhere.limit(count)
    }
    const videoData = await videoWhere.get()

    for (const video of videoData.docs) {
      const data = makeVideo(video)
      videoResponse.data.push(data)
    }
  } catch (error) {
    console.log(error)
  }
  return videoResponse
}

/**
 * タグを含んでいるVideoを取得する
 * @param tagId tag id
 */
export const getInTag = async (tagId: string): Promise<IVideoResponse> => {
  const videoResponse: IVideoResponse = { data: [] }

  try {
    const videoData = await Videos.where(
      'tag_ids',
      'array-contains',
      tagId
    ).get()
    for (const video of videoData.docs) {
      const data = makeVideo(video)
      videoResponse.data.push(data)
    }
  } catch (error) {
    console.log(error)
  }
  return videoResponse
}

/**
 * 運動時間でVideoを取得する
 * @param minutes play time minutes
 */
export const getPlayTime = async (
  minutes: number[]
): Promise<IVideoResponse> => {
  const videoResponse: IVideoResponse = { data: [] }

  try {
    let videoData: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> | null = null
    if (minutes.length > 1) {
      videoData = await Videos.where('play_time', '>=', minutes[0])
        .where('play_time', '<=', minutes[1])
        .get()
    } else {
      videoData = await Videos.where('play_time', '>=', minutes[0]).get()
    }
    for (const video of videoData.docs) {
      const data = makeVideo(video)
      videoResponse.data.push(data)
    }
  } catch (error) {
    console.log(error)
  }
  return videoResponse
}

/**
 * 部位タグを含んでいるVideoを取得する
 * @param partTagId part tag id
 */
export const getInPartTag = async (
  partTagId: string
): Promise<IVideoResponse> => {
  const videoResponse: IVideoResponse = { data: [] }

  try {
    const videoData = await Videos.where(
      'body_part_ids',
      'array-contains',
      partTagId
    ).get()
    for (const video of videoData.docs) {
      const data = makeVideo(video)
      videoResponse.data.push(data)
    }
  } catch (error) {
    console.log(error)
  }
  return videoResponse
}

function makeVideo(
  video: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>
): IVideo {
  const data = video.data()
  return {
    id: data.id,
    title: data.title,
    image: data.image,
    url: data.url,
    category_id: data.category_id,
    tag_ids: data.tag_ids,
    body_part_ids: data.body_part_ids,
    overview: data.overview,
    play_time: data.play_time,
    mets: data.mets,
    status: data.status,
    view_count: data.view_count,
    created_at: data.created_at,
    updated_at: data.updated_at,
  }
}

/**
 * videos add
 */
export const store = async (video: IVideo): Promise<void> => {
  try {
    await Videos.doc(video.id).set(video)
  } catch (error) {
    console.log(error)
  }
}

/**
 * videos update
 */
export const update = async (video: IVideo): Promise<void> => {
  try {
    video.updated_at = firebase.firestore.Timestamp.now()

    await Videos.doc(video.id).update(video)
  } catch (error) {
    console.log(error)
  }
}

/**
 * video increment view_count
 */
export const incrementViewCount = async (videoId: string): Promise<void> => {
  try {
    await Videos.doc(videoId).update({
      view_count: firebase.firestore.FieldValue.increment(1),
    })
  } catch (error) {
    console.log(error)
  }
}

/**
 * videos delete
 */
export const remove = async (video: IVideo): Promise<void> => {
  try {
    await Videos.doc(video.id).delete()
  } catch (error) {
    console.log(error)
  }
}

/**
 * remove image
 */
export const removeImage = async (video: IVideo): Promise<void> => {
  try {
    video.image = null
    await Videos.doc(video.id).update(video)
  } catch (error) {
    console.log(error)
  }
}
