import { Routes } from 'common/enums'
import ButtonLeft from 'components/atoms/Button/Left'
import H1 from 'components/atoms/Head/H1'
import React from 'react'
import { Container } from 'reactstrap'
import TableBody from 'components/organisms/Admin/Video/TableBody'

const Video: React.FC = (props: any) => {
  return (
    <>
      <div className="main-content">
        <H1 title="動画一覧" />

        <Container fluid>
          <ButtonLeft
            history={props.history}
            nextPage={Routes.AdminVideoCreate}
            content="新規登録"
          />

          <TableBody {...props} />
        </Container>
      </div>
    </>
  )
}

export default Video
