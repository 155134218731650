import { getCountSignedUpByCoupon } from 'common/coupon'
import { Routes } from 'common/enums'
import firebase, { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import { ICorporate } from 'common/interfaces/corporate'
import { IPlanSelect } from 'common/interfaces/plan/select'
import { IUser } from 'common/interfaces/user'
import {
  reloadCachedCorporates,
  reloadCachedUser,
} from 'providers/AuthProvider'
import { findByID, update } from 'repositories/store/firebase_user'
import { alertService } from 'services/alert'
import { v4 as uuidv4 } from 'uuid'

const STORE_ID = String(process.env.REACT_APP_ROBOT_PAYMENT_STORE_ID)

/**
 * 決済方法登録画面に遷移する
 */
export const transitionToClearingRegister = async (
  planSelectState: IPlanSelect
): Promise<void> => {
  if (!planSelectState.plan) {
    alertService.show(false, 'プランを選択してください')
    return
  }

  try {
    const uri: string = `https://credit.j-payment.co.jp/gateway/payform.aspx?aid=${STORE_ID}&pt=1&iid=${planSelectState.plan}&uid=${auth.currentUser?.uid}&productId=${planSelectState.plan}`

    window.location.href = uri
  } catch (error) {
    console.log(error)
  }
}

/**
 * クーポンで利用開始する
 */
export const couponByUseStart = async (
  storeCache: IStoreCache,
  couponCode: string
): Promise<void> => {
  try {
    if (!couponCode) {
      alertService.show(false, 'クーポンコードが入力されておりません')
      return
    }

    let corporate: ICorporate | undefined = undefined
    for (const corporateData of storeCache.corporates) {
      if (corporateData.coupon_code === couponCode) {
        corporate = corporateData
        break
      }
    }
    if (corporate === undefined) {
      alertService.show(false, 'クーポンコードが見つかりません')
      return
    }
    if (await checkCouponEnabled(corporate)) {
      alertService.show(false, '入力されたクーポンコードは有効ではありません')
      return
    }

    // ユーザを課金状態に更新
    const user: IUser = await findByID(storeCache.user!.id)
    user.corporate_id = corporate.id
    user.payment = [
      {
        id: uuidv4(),
        result: '999',
        cardCompanyApprovalNum: null,
        orderCode: null,
        storeOrderNum: null,
        price: null,
        tax: null,
        shippingFee: null,
        totalAmount: null,
        issueId: null,
        issuePassword: null,
        automaticPayingNum: null,
        created_at: firebase.firestore.Timestamp.now(),
      },
    ]

    await update(user)
    await reloadCachedUser(storeCache)
    await reloadCachedCorporates(storeCache)

    alertService.show(true, 'クーポンコードで承認いたしました')
    window.location.href = Routes.Root // reloadCachedUserではstoreCacheが更新されないためこの方法しかないのかな
    // props.history.push(Routes.Root)
  } catch (error) {
    console.log(error)
    alertService.show(false, '不正な操作です')
  }
}

const checkCouponEnabled = async (corporate: ICorporate): Promise<boolean> => {
  const remainingEnabledCount = await getCountSignedUpByCoupon(corporate)
  if (remainingEnabledCount >= Number(corporate.coupon_enabled_count)) {
    return true
  }

  return false
}
