import { Routes } from 'common/enums'
import { auth } from 'common/firebase'
import { IStoreCache } from 'common/interfaces/auth_provider'
import FullLoadingSpinner from 'components/atoms/FullLoadingSpinner/FullLoadingSpinner'
import CantLogin, { CantLoginPageType } from 'components/molecules/CantLogin'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { IsInExpirationAt, isLoading, IsSettled } from 'services/auth'
import GenreList from 'template/user/GenreList'
import Index from 'template/user/Index'
import Setting from 'template/user/Setting'
import VideoDetail from 'template/user/VideoDetail'
import Videos from 'template/user/Videos'
import ViewHistory from 'template/user/ViewHistory'
import Worries from 'template/user/Worries'

enum ViewType {
  LOGIN,
  LOADING,
  CHANGE_INFO,
  GONE,
  VISIBLE,
}

const User: React.FC = (props: any) => {
  const storeCache: IStoreCache = useContext(AuthContext).storeCache
  const [viewType, setViewType] = useState<ViewType>(ViewType.LOADING)
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        setViewType(ViewType.LOGIN)
      } else if (isLoading(storeCache)) {
        setViewType(ViewType.LOADING)
      } else if (!IsSettled(storeCache.user)) {
        setViewType(ViewType.LOGIN)
      } else if (!IsInExpirationAt(storeCache.user!)) {
        setViewType(ViewType.CHANGE_INFO)
      } else if (storeCache.user?.admin ?? false) {
        setViewType(ViewType.GONE)
      } else {
        setViewType(ViewType.VISIBLE)
      }
    })
  }, [setViewType, storeCache])

  switch (viewType) {
    case ViewType.LOADING:
      return <FullLoadingSpinner />
    case ViewType.LOGIN:
      props.history.replace(Routes.Login)
      return null
    case ViewType.CHANGE_INFO:
      props.history.replace(Routes.UserChangeInfo)
      return null
    case ViewType.GONE:
      return <CantLogin pageType={CantLoginPageType.MEMBER} />
    case ViewType.VISIBLE:
      return userRouteSwitch(props)
  }
}

const userRouteSwitch = (props: any): JSX.Element => {
  return (
    <Switch>
      <Route path={Routes.Setting} component={() => <Setting {...props} />} />
      <Route
        path={Routes.UserViewHistory}
        component={() => <ViewHistory {...props} />}
      />
      <Route
        path={Routes.UserCourseVideoDetail}
        component={() => <VideoDetail {...props} />}
      />
      <Route
        path={Routes.UserVideoDetail}
        component={() => <VideoDetail {...props} />}
      />
      <Route
        path={Routes.UserPlayTimeVideos}
        component={() => <Videos {...props} />}
      />
      <Route
        path={Routes.UserPartTagVideos}
        component={() => <Videos {...props} />}
      />
      <Route
        path={Routes.UserTagVideos}
        component={() => <Videos {...props} />}
      />
      <Route
        path={[Routes.UserRecommendCourses, Routes.UserPopularExercises]}
        component={() => <GenreList {...props} />}
      />
      <Route
        path={Routes.UserCommonWorries}
        component={() => <Worries {...props} />}
      />
      <Route
        path={Routes.UserCategoryVideos}
        component={() => <Videos {...props} />}
      />
      <Route
        path={Routes.UserNewerVideos}
        component={() => <Videos {...props} />}
      />
      <Route
        path={Routes.UserPopularVideos}
        component={() => <Videos {...props} />}
      />
      <Route path={Routes.Root} component={() => <Index {...props} />} />
    </Switch>
  )
}

export default User
