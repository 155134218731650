import firebase, { auth } from 'common/firebase'
import { IAuthProvider, IStoreCache } from 'common/interfaces/auth_provider'
import { ICategory } from 'common/interfaces/category'
import { ICourse } from 'common/interfaces/course'
import { Ilogin } from 'common/interfaces/login'
import { IPartTag } from 'common/interfaces/part_tag'
import { IRegister } from 'common/interfaces/register'
import { ITag } from 'common/interfaces/tag'
import { IUser } from 'common/interfaces/user'
import React, { useEffect, useState } from 'react'
import { get as getCorporates } from 'repositories/store/firebase_corporate'
import { get as getCategories } from 'repositories/store/firebase_category'
import { get as getCourses } from 'repositories/store/firebase_course'
import { get as getPartTags } from 'repositories/store/firebase_part_tag'
import { get as getTags } from 'repositories/store/firebase_tag'
import { findByID } from 'repositories/store/firebase_user'
import { firebaseBySignInWithEmailAndPassword } from 'services/login'
import { createUser } from 'services/register'
import { ICorporate } from 'common/interfaces/corporate'

export const AuthContext = React.createContext<IAuthProvider>({
  loginUser: () => null,
  registerUser: () => null,
  currentUser: null,
  storeCache: {
    setStoreCache: null,
    user: null,
    categories: [],
    partTags: [],
    tags: [],
    courses: [],
    corporates: [],
  },
})

export const AuthProvider = ({ children }: { children: any }): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null)
  const [storeCache, setStoreCache] = useState<IStoreCache>({
    setStoreCache: null,
    user: null,
    categories: [],
    partTags: [],
    tags: [],
    courses: [],
    corporates: [],
  })

  useEffect(() => {
    auth.onAuthStateChanged(setCurrentUser)
    if (currentUser === null) {
      return
    }

    ;(async () => {
      const user = currentUser !== null ? await findByID(currentUser.uid) : null
      const fsCache = await getFirestoreCache(setStoreCache, user)
      setStoreCache(fsCache)
    })()
  }, [currentUser, setStoreCache])

  /**
   * firebaseのAuthenticationで認証する
   */
  const loginUser = (props: any, loginState: Ilogin): void => {
    firebaseBySignInWithEmailAndPassword(props, loginState)
  }

  /**
   * firebaseのAuthenticationを作成し認証する
   */
  const registerUser = (props: any, registerState: IRegister): void => {
    createUser(props, registerState)
  }

  return (
    <AuthContext.Provider
      value={{
        loginUser,
        registerUser,
        currentUser,
        storeCache,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

async function getFirestoreCache(
  setStoreCache: React.Dispatch<React.SetStateAction<IStoreCache>>,
  user: IUser | null
): Promise<IStoreCache> {
  const cacheCategories: ICategory[] = (await getCategories()).data
  const partTags: IPartTag[] = (await getPartTags()).data
  const tags: ITag[] = (await getTags()).data
  const courses: ICourse[] = (await getCourses()).data
  const corporates: ICorporate[] = (await getCorporates()).data

  return {
    setStoreCache: setStoreCache,
    user: user,
    categories: cacheCategories,
    partTags: partTags,
    tags: tags,
    courses: courses,
    corporates: corporates,
  }
}

export const reloadCachedUser = async (storeCache: IStoreCache) => {
  const newUser = await findByID(storeCache.user!.id)
  storeCache.setStoreCache?.({ ...storeCache, user: newUser })
}

export const reloadCachedCategories = async (storeCache: IStoreCache) => {
  const newCategories = (await getCategories()).data
  storeCache.setStoreCache?.({ ...storeCache, categories: newCategories })
}

export const reloadCachedPartTags = async (storeCache: IStoreCache) => {
  const newPartTags = (await getPartTags()).data
  storeCache.setStoreCache?.({ ...storeCache, partTags: newPartTags })
}

export const reloadCachedTags = async (storeCache: IStoreCache) => {
  const newTags = (await getTags()).data
  storeCache.setStoreCache?.({ ...storeCache, tags: newTags })
}

export const reloadCachedCourses = async (storeCache: IStoreCache) => {
  const newCourses = (await getCourses()).data
  storeCache.setStoreCache?.({ ...storeCache, courses: newCourses })
}

export const reloadCachedCorporates = async (storeCache: IStoreCache) => {
  const newCorporates = (await getCorporates()).data
  storeCache.setStoreCache?.({ ...storeCache, corporates: newCorporates })
}
