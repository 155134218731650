import { selectImage } from 'common/image'
import { IPartTag } from 'common/interfaces/part_tag'
import { convertNULLIntoString } from 'common/utils'
import { trim } from 'lodash'
import { AuthContext } from 'providers/AuthProvider'
import React, { useContext, useState } from 'react'
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { updatePartTag, deleteImage } from 'services/admin/part_tag_edit'

interface IFormBodyProps {
  props: any
}

const FormBody: React.FC<IFormBodyProps> = (props: any) => {
  const storeCache = useContext(AuthContext).storeCache
  const partTag: IPartTag = props.location.state.partTag
  const initPartTagEdit: IPartTag = {
    id: partTag.id,
    name: partTag.name,
    image: partTag.image,
    sort_num: partTag.sort_num,
    created_at: partTag.created_at,
    updated_at: partTag.updated_at,
  }
  const [partTagEdit, setPartTagEdit] = useState<IPartTag>(initPartTagEdit)
  const [file, setFile] = useState<File>()

  const onChangeImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    selectImage<IPartTag>(e, setFile, setPartTagEdit, partTagEdit)
  }
  const onChangeSortNum = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newSortNum =
      trim(e.target.value) === '' ? null : Number(e.target.value)
    setPartTagEdit({ ...partTagEdit, sort_num: newSortNum })
  }
  const delImage = () => deleteImage(props, partTagEdit, storeCache)
  const update = () => updatePartTag(props, file, partTagEdit, storeCache)

  return (
    <Form>
      <Row>
        <Col md="10">
          <FormGroup>
            <Label for="partTagNameForm">タグ名</Label>
            <Input
              id="partTagNameForm"
              value={convertNULLIntoString(partTagEdit.name)}
              onChange={(e) =>
                setPartTagEdit({ ...partTagEdit, name: e.target.value })
              }
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label for="partTagThumbnailForm">サムネイル</Label>
            <CustomInput
              id="partTagThumbnailForm"
              onChange={(e) => onChangeImage(e)}
              type="file"
            />
          </FormGroup>
        </Col>
      </Row>

      {partTag.image !== null && (
        <Row className="mb-3">
          <Col>
            <Label>設定されているサムネイル</Label>
            <Row>
              <Col md="4">
                <img
                  height={150}
                  width="auto"
                  src={partTag.image}
                  alt={String(partTag.name)}
                />
              </Col>
              <Col md="8">
                <Button
                  onClick={() => delImage()}
                  className="my-4"
                  color="danger"
                  type="button"
                >
                  削除
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row>
        <Col md="3">
          <FormGroup>
            <Label for="categorySortNumForm">ソート番号</Label>
            <Input
              id="categorySortNumForm"
              value={convertNULLIntoString(partTagEdit.sort_num)}
              onChange={(e) => onChangeSortNum(e)}
              type="number"
              step="1"
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="text-center">
        <Button onClick={update} className="my-4" color="primary" type="button">
          保存
        </Button>
      </div>
    </Form>
  )
}

export default FormBody
